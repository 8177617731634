var BST;
(function (BST) {
  var Rank = BST.Rank = (function () {
    function Rank (parent, opt) {
      var _self = this;
      parent = parent || 'body';
      opt = opt || {};
      this.callbackChangeDate = opt.callbackChangeDate;
      this.callbackClickAll = opt.callbackClickAll;
      this.callbackChangeTreeListItem = opt.callbackChangeTreeListItem;
      this.callbackPrint = opt.callbackPrint;

      // ベース作成
      this.elementRoot = $('<div>').addClass('bst-rank').appendTo($(parent));

      this.init();

      // 背景画像
      // this.backImg = $('<div class="backImg">').appendTo(this.elementRoot);

      /// /データ取得
      // this.data = data;

      /// /UI完成
      /// /_self.createHead();       //ヘッダー部分
      // _self.createTogBtn();     //トグルボタン部分
      // _self.createDay();        //日ボタン部分
      // _self.createMonth();      //月ボタン部分
      // if (this.data)
      //    _self.createMain();       //メイン部分作成

      /// /初期表示
      // $('#rank-home .oneday').toggleClass("active");
      // $('#rank-home .ymonthbtn').css('display', 'none');

      /// /データが11個以上あった場合
      // if (this.data && (this.data.score).length >= 11) {
      //    $('#rank-home .graphbtn').css('display', 'block');
      // };
    }

    Rank.prototype.init = function () {
      this.currentDataMap = {};
      this.currentType = null;

      this.tab1Items = [];
      this.tab2s = [];

      this.text1 = null;
      this.text2 = null;
      this.text3 = null;

      this.graphBtn = null;
      this.graphBase = null;
    };

    Rank.prototype.clearAll = function () {
      this.elementRoot.empty();

      this.init();
    };

    Rank.prototype.clearGraph = function () {
      // this.graphBtn = null;
      this.graphBase.empty();
      // this.graphBase = null;
    };

    Rank.prototype.updateAll = function (data) {
      this.clearAll();

      // データ取得
      this.data = data;

      this.createAll(data, this.elementRoot);

      // UI完成
      // this.createHead();       //ヘッダー部分
      // this.createTogBtn(data);     //トグルボタン部分
      // if (data.day)
      //    this.createDay(data.day);        //日ボタン部分
      // if (data.month)
      //    this.createMonth(data.month);      //月ボタン部分
      // if (this.data)
      //    this.createMain();       //メイン部分作成

      // 初期表示
      // $('#rank-home .oneday').toggleClass("active");
      // $('#rank-home .ymonthbtn').css('display', 'none');

      /// /データが11個以上あった場合
      // if (this.data && (this.data.score).length >= 11) {
      //    $('#rank-home .graphbtn').css('display', 'block');
      // };
    };

    Rank.prototype.updateGraph = function (data, ave) {
      var len = 0;
      if (data) len = data.length;
      this.setTextAve(ave, len);

      this.clearGraph();
      // this.createGraph(data.rank, this.graphBase);
      this.createGraph(data, this.graphBase);
      console.log('bst rank update graph');
    };

    // Rank.prototype.firstClick = function (type) {
    //    for (var i = 0; i < this.tab1Items.length; i++) {
    //        if (this.tab1Items[i].data("type") == type) {
    //            this.tab1Items[i].trigger("click");
    //        }
    //    }
    //    for (var i = 0; i < this.tab2s.length; i++) {
    //        if (this.tab2s[i].data("type") == type) {
    //            var items = tab2s[i].children();

    //            for (var i=items.length-1; i>=0; i--) {
    //                var item = items.length.eq(i);
    //                var d = item.data("data");
    //                if (d.enable == 1) {
    //                    item.trigger("click");
    //                    break;
    //                }
    //            }
    //        }
    //    }
    // }

    Rank.prototype.createAll = function (data, parent) {
      var _self = this;

      this.createTab1(data, parent);

      var main = $('<div>').addClass('main').appendTo(parent);
      var mainLeft = $('<div>').addClass('main-left').appendTo(main);
      var mainRight = $('<div>').addClass('main-right').appendTo(main);
      this.graphBase = $('<div>').addClass('graphbase').appendTo(mainRight);

      this.createText(mainLeft);

      // this.createGraph(data.rank, this.graphBase, 10);
      this.createGraph(data.rank, this.graphBase);

      var len = 0;
      if (data.rank) len = data.rank.length;
      this.setTextAve(data.ave, len);

      for (var i = 0; i < this.tab1Items.length; i++) {
        if (this.tab1Items[i].data('type') === data.rankType) {
          this.tab1Items[i].trigger('click');
        }
      }

      // もっと表示するボタン
      var graphBtn = this.graphBtn = $('<div>').addClass('graphbtn').appendTo(parent);
      graphBtn.text('もっと表示する');
      graphBtn.on('click', function () {
        if (_self.callbackClickAll) {
          // var n = $(this).find(":selected").data("data");
          var n = _self.getSelectTreeListItem();
          _self.callbackClickAll(_self.currentDataMap[_self.currentType], n, _self.currentType);
        }
        // _self.clearGraph();
        // _self.createGraph(data.rank, _self.graphBase);
        // graphBtn.remove();

        // graphBtn.toggleClass("active");
        // graphBtn.text( graphBtn.hasClass("active") ? "隠す" : "もっと表示する");
      });

      // this.dispGraphBtn(false);
    };
    Rank.prototype.createTab1 = function (data, parent) {
      var _self = this;

      var tab1 = $('<div>').addClass('tab1').appendTo(parent);
      // this.tab1Items.push(tab1);

      if (data.day) {
        this.tab1Items.push(this.createTab1Item(data.day, tab1, '日', 'day', data.rankType === 'day'));
      }
      if (data.week) {
        this.tab1Items.push(this.createTab1Item(data.week, tab1, '週', 'week', data.rankType === 'week'));
      }
      if (data.month) {
        this.tab1Items.push(this.createTab1Item(data.month, tab1, '月', 'month', data.rankType === 'month'));
      }

      // ツリーリスト
      this.selectTree = $('<select>').addClass('list').appendTo(tab1);
      this.selectTree.on('change', function () {
        // var n = $(this).find(":selected").data("data");
        var n = _self.getSelectTreeListItem();
        // console.log(JSON.stringify(n));
        if (_self.callbackChangeTreeListItem) {
          _self.callbackChangeTreeListItem(_self.currentDataMap[_self.currentType], n, _self.currentType);
        }
      });

      if (data.myRanking === 1) {
        // var o = $("<option>").text("マイランキング").val(-1).data({cd:-1}).appendTo(this.selectTree);
        // this.createTab1TreeItem({ nm: "マイランキング" , cd:-1}, this.selectTree);
        this.createTab1TreeItem(data.myRankingData, this.selectTree, data.currentTree);
      }
      this.createTab1TreeItem(data.tree, this.selectTree, data.currentTree);

      this.btnPrint = $('<div>').addClass('btn-print').appendTo(tab1);
      this.btnPrint.on('click', function () {
        if (_self.callbackPrint) {
          _self.callbackPrint();
        }
      });
    };
    Rank.prototype.createTab1TreeItem = function (node, parent, defCd) {
      var o = $('<option>').text((node.tree || '') + node.nm).data('data', node).appendTo(parent);
      if (node.cd === defCd) {
        o.get(0).selected = true;
      }

      if (!node.child) return;

      for (var i = 0; i < node.child.length; i++) {
        this.createTab1TreeItem(node.child[i], parent, defCd);
      }
    };

    Rank.prototype.createTab1Item = function (data, parent, title, type, active) {
      var item = $('<div>').addClass('item').appendTo(parent);
      item.text(title);
      item.data('data', data);
      item.data('type', type);

      var tab2 = this.createTab2(data, parent.parent(), type);
      tab2.hide();

      var _self = this;
      item.on('click', function () {
        if (item.hasClass('active')) return;

        item.parent().children().removeClass('active');
        item.addClass('active');
        _self.currentType = item.data('type');

        for (var i = 0; i < _self.tab2s.length; i++) {
          _self.tab2s[i].hide();
        }
        tab2.show();

        _self.setTextDate(type, _self.currentDataMap[type].cd);
        // _self.updateGraph(_self.currentDataMap["rank"]);
        if (_self.callbackChangeDate) {
          // var n = $(this).find(":selected").data("data");
          var n = _self.getSelectTreeListItem();
          _self.callbackChangeDate(_self.currentDataMap[type], n, type);
        }
        // $('#rank-home .ymonthbtn').css('display', 'none');
        // $('#rank-home .daybtn').css('display', 'inline-block');
      });

      // if (active) item.addClass("active");
      // if (active) item.trigger("click");

      return item;
    };

    Rank.prototype.createTab2 = function (data, parent, type) {
      var tab2 = $('<div>').addClass('tab2').appendTo(parent);
      this.tab2s.push(tab2);

      // tab作成
      var items = [];
      for (var i = 0; i < data.length; i++) {
        items.push(this.createTab2Item(data[i], tab2, type));
      }

      // 選択状態にする
      for (i = items.length - 1; i >= 0; i--) {
        // 選択許可がでていて、まだ選択がされていないなら
        var d = items[i].data('data');
        // var t = items[i].data("type");
        if (!this.currentDataMap[type] && d.enable === 1) {
          items[i].addClass('active');
          this.currentDataMap[type] = d;
          this.currentType = type;
        }
      }

      return tab2;
    };

    Rank.prototype.createTab2Item = function (data, parent, type) {
      // var btns = [];
      var item = $('<div>').addClass('item').appendTo(parent);
      item.data('data', data);
      item.data('type', type);

      /// / 選択許可がでていて、まだ選択がされていないなら
      // if (!this.currentDataMap[type] && data.enable == 1) {
      //    item.addClass("active");
      //    this.currentDataMap[type] = data;
      // }

      var date1 = new Date(data.cd);

      if (type === 'day') {
        $('<div>').addClass('text-m').text(BST.Util.dateFormat(date1, 'M月d日')).appendTo(item);
      } else if (type === 'week') {
        var date2 = new Date(date1);
        date2.setDate(date2.getDate() + 6);
        $('<div>').addClass('text-s').text(BST.Util.dateFormat(date1, 'M月d日') + '～').appendTo(item);
        $('<div>').addClass('text-s').text(BST.Util.dateFormat(date2, 'M月d日')).appendTo(item);
      } else if (type === 'month') {
        var arr = data.cd.split('/');
        $('<div>').addClass('text-s').text(BST.Util.dateFormat(date1, 'yyyy年')).appendTo(item);
        $('<div>').addClass('text-l').text(BST.Util.dateFormat(date1, 'M月')).appendTo(item);
      }

      // btns.push(btn);
      var _self = this;
      if (data.enable === 1) {
        item.on('click', function () {
          if ($(this).hasClass('active')) return;

          item.parent().children().removeClass('active');
          item.addClass('active');

          var d = item.data('data');
          var t = item.data('type');
          _self.currentDataMap[t] = d;
          _self.currentType = t;
          // _self.updateRanking(d, t);

          _self.setTextDate(t, d.cd);

          if (_self.callbackChangeDate) {
            // var n = $(this).find(":selected").data("data");
            var n = _self.getSelectTreeListItem();
            _self.callbackChangeDate(d, n, t);
          }
        });
      } else {
        item.addClass('disable');
      }

      return item;
    };

    Rank.prototype.createText = function (parent) {
      var gt = $('<div>').addClass('graphtitle').appendTo(parent);
      this.text1 = $('<div>').addClass('graphtitle-content').html('　' + '月' + '　' + '日の<br>評価点<br>ランキング').appendTo(gt);
      var avg = $('<div>').addClass('average').appendTo(parent);
      this.text2 = $('<div>').addClass('average-content1').html('平均<br> 　 点').appendTo(avg);
      this.text3 = $('<div>').addClass('average-content2').html('(　名の平均値)').appendTo(avg);
    };

    Rank.prototype.setTextDate = function (type, date) {
      var date1 = new Date(date);

      switch (type) {
        case 'day':
          this.text1.html(BST.Util.dateFormat(date1, 'M月d日') + 'の<br>評価点<br>ランキング');
          break;
        case 'week':
          var date2 = new Date(date1);
          date2.setDate(date2.getDate() + 6);
          this.text1.html(
            BST.Util.dateFormat(date1, 'M月d日') + '～<br>' +
                        BST.Util.dateFormat(date2, 'M月d日') + 'の<br>平均評価点<br>ランキング');
          break;
        case 'month':
          this.text1.html(
            BST.Util.dateFormat(date1, 'yyyy年') + '<br>' +
                        BST.Util.dateFormat(date1, 'M月') + 'の<br>月平均評価点<br>ランキング');
          break;
        default:
          break;
      }
    };

    Rank.prototype.setTextAve = function (point, num) {
      this.text2.html('平均<br>' + (point >= 0 ? point : '-') + '点');
      this.text3.html('(' + num + '名の平均値)');
    };

    Rank.prototype.createGraph = function (data, parent, len) {
      this.currentDataMap.rank = data;

      if (data === null || data === undefined || data.length === 0) {
        $('<div>').addClass('mess').text('ランキング情報がありません').appendTo(parent);
        return;
      }

      // if (len == undefined || len == null) len = data.length;

      var graphNameContent = $('<div>').addClass('graph-name-content').appendTo(parent);

      // 順位処理
      var graphNoRow = $('<div>').addClass('graph-NoRow').appendTo(graphNameContent);
      // 名前処理
      var graphNameRow = $('<div>').addClass('graph-nameRow').appendTo(graphNameContent);
      // ｙ座標作成
      var graphyRow = $('<div>').addClass('graph-yRow').appendTo(parent);
      // グラフ部分
      var graphxRow = $('<div>').addClass('graph-xRow').appendTo(parent);

      // for (var i = 0; i < len; i++) {
      for (var i = 0; i < data.length; i++) {
        switch (i) {
          case 0:
            var rank1Img = $('<div>').addClass('rank1Img').appendTo(graphNoRow);
            var graphName1 = $('<div>').addClass('graph-name1').text(data[i].nm).appendTo(graphNameRow);
            var graphy1 = $('<div>').addClass('graph-y1').appendTo(graphyRow);

            var graphBar1 = $('<div>').addClass('graphView').appendTo(graphxRow);
            var bar1 = $('<div>').addClass('bar1').appendTo(graphBar1);
            bar1.css('width', data[i].point * 4 + 'px');
            var graphx1 = $('<div>').addClass('graph-x1').appendTo(bar1);
            var value1 = $('<div>').addClass('value1').appendTo(bar1);
            value1.text(data[i].point + '点');
            $('<div>').addClass(this.getStar(this.conv(data[i].star))).appendTo(graphBar1);

            break;
          case 1:
            var rank2Img = $('<div>').addClass('rank2Img').appendTo(graphNoRow);
            var graphName2 = $('<div>').addClass('graph-name2').text(data[i].nm).appendTo(graphNameRow);
            var graphy2 = $('<div>').addClass('graph-y2').appendTo(graphyRow);

            var graphBar2 = $('<div>').addClass('graphView').appendTo(graphxRow);
            var bar2 = $('<div>').addClass('bar2').appendTo(graphBar2);
            bar2.css('width', data[i].point * 4 + 'px');
            var graphx2 = $('<div>').addClass('graph-x2').appendTo(bar2);
            var value2 = $('<div>').addClass('value2').appendTo(bar2);
            value2.text(data[i].point + '点');
            $('<div>').addClass(this.getStar(this.conv(data[i].star))).appendTo(graphBar2);

            break;
          case 2:
            var rank3Img = $('<div>').addClass('rank3Img').appendTo(graphNoRow);
            var graphName3 = $('<div>').addClass('graph-name3').text(data[i].nm).appendTo(graphNameRow);
            var graphy3 = $('<div>').addClass('graph-y3').appendTo(graphyRow);

            var graphBar3 = $('<div>').addClass('graphView').appendTo(graphxRow);
            var bar3 = $('<div>').addClass('bar3').appendTo(graphBar3);
            bar3.css('width', data[i].point * 4 + 'px');
            var graphx3 = $('<div>').addClass('graph-x3').appendTo(bar3);
            var value3 = $('<div>').addClass('value3').appendTo(bar3);
            value3.text(data[i].point + '点');
            $('<div>').addClass(this.getStar(this.conv(data[i].star))).appendTo(graphBar3);

            break;
          default:
            var graphNo = $('<div>').addClass('graph-No').text(i + 1 + '位').appendTo(graphNoRow);
            var graphName = $('<div>').addClass('graph-name').text(data[i].nm).appendTo(graphNameRow);
            var graphy = $('<div>').addClass('graph-y').appendTo(graphyRow);

            var graphBar = $('<div>').addClass('graphView').appendTo(graphxRow);
            var bar = $('<div>').addClass('bar').appendTo(graphBar);
            bar.css('width', data[i].point * 4 + 'px');
            var graphx = $('<div>').addClass('graph-x').appendTo(bar);
            var value = $('<div>').addClass('value').appendTo(bar);
            value.text(data[i].point + '点');
            $('<div>').addClass(this.getStar(this.conv(data[i].star))).appendTo(graphBar);

            break;
        }
      }

      // function _graphContentStar(value) {
      //    /* key ;区切りで、 "要素0 以上 要素1 未満" を示す。 */

      //    var _starLevel = {
      //        '0.5;1': 'graphStar1',
      //        '1;1.5': 'graphStar2',
      //        '1.5;2.0': 'graphStar3',
      //        '2.0;2.5': 'graphStar4',
      //        '2.5;3.0': 'graphStar5',
      //        '3.0;3.5': 'graphStar6',
      //        '3.5;4.0': 'graphStar7',
      //        '4.0;4.5': 'graphStar8',
      //        '4.5;5.0': 'graphStar9',
      //        '5.5;6.0': 'graphStar10',
      //        '6.0;6.5': 'graphStar11',
      //        '6.5;7.0': 'graphStar12',
      //        '7.0;7.5': 'graphStar13',
      //        '7.5;8.0': 'graphStar14',
      //        '8.0;8.5': 'graphStar15',
      //        '8.5;9.0': 'graphStar16',
      //        '9.0;9.5': 'graphStar17',
      //        '9.5;10': 'graphStar18',
      //        '10;10.5': 'graphStar19',
      //        '10.5;11': 'graphStar20',
      //        '11;Infinity': 'graphStar21',
      //    }

      //    var _self = this;
      //    this.data.star.forEach(function (value) {

      //        var clazz = _graphContentStar(value);

      //    })
      // }

      // ラベル部分作成
      var label = $('<div>').addClass('label').appendTo(graphxRow);
      var xscaleTable = $('<div>').addClass('xscale-table').appendTo(label);
      for (i = 0; i < 10; i++) {
        xscaleTable.append($('<div>').addClass('xscale'));
      }

      var rankLabel = $('<div>').addClass('rank-label').appendTo(label);
    };

    Rank.prototype.dispGraphBtn = function (b) {
      if (this.graphBtn) {
        if (b) this.graphBtn.show();
        else this.graphBtn.hide();
      }
    };

    Rank.prototype.conv = function (val) {
      var a = val % 1;
      var b = Math.floor(val);
      if (a >= 0.5) a = 0.5;
      else a = 0;
      var res = (b * 10 + a * 10) / 10;
      return res;
    };

    Rank.prototype.getStar = function (val) {
      switch (val) {
        case 0:
          return '';
        case 0.5:
          return 'graphStar1';
        case 1:
          return 'graphStar2';
        case 1.5:
          return 'graphStar3';
        case 2:
          return 'graphStar4';
        case 2.5:
          return 'graphStar5';
        case 3:
          return 'graphStar6';
        case 3.5:
          return 'graphStar7';
        case 4:
          return 'graphStar8';
        case 4.5:
          return 'graphStar9';
        case 5:
          return 'graphStar10';
        case 5.5:
          return 'graphStar11';
        case 6:
          return 'graphStar12';
        case 6.5:
          return 'graphStar13';
        case 7:
          return 'graphStar14';
        case 7.5:
          return 'graphStar15';
        case 8:
          return 'graphStar16';
        case 8.5:
          return 'graphStar17';
        case 9:
          return 'graphStar18';
        case 9.5:
          return 'graphStar19';
        case 10:
          return 'graphStar20';
        case 10.5:
          return 'graphStar20';
        default:
          return 'graphStar21';
      }
    };

    Rank.prototype.getSelectTreeListItem = function () {
      return this.selectTree.find(':selected').data('data');
    };

    return Rank;
  })();
})(BST || (BST = {}));
