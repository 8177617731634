var BST;
(function (BST) {
  var ReportData = BST.ReportData = (function () {
    function ReportData () {
      this._data = null;
    }

    ReportData.ICON_TYPE_STAR = 'icon-star';
    ReportData.ICON_TYPE_BRAKE = 'icon-brake';
    ReportData.ICON_TYPE_BACK_GEAR = 'icon-back-gear';
    ReportData.ICON_TYPE_BLINKER_R = 'icon-blinker-r';
    ReportData.ICON_TYPE_BLINKER_L = 'icon-blinker-l';

    ReportData.ICONS = {};
    ReportData.ICONS[ReportData.ICON_TYPE_STAR] =
        {
          src: './img/star.png',
          anchor: [10, 10]
        };
    ReportData.ICONS[ReportData.ICON_TYPE_BRAKE] =
        {
          src: './img/star.png',
          anchor: [10, 10]
        };
    ReportData.ICONS[ReportData.ICON_TYPE_BACK_GEAR] =
        {
          src: './img/icon-rear.png',
          anchor: [15 / 2, 22]
        };
    ReportData.ICONS[ReportData.ICON_TYPE_BLINKER_R] =
        {
          src: './img/star.png',
          anchor: [10, 10]
        };
    ReportData.ICONS[ReportData.ICON_TYPE_BLINKER_L] =
        {
          src: './img/star.png',
          anchor: [10, 10]
        };

    ReportData.prototype.data = function () {
      return this._data;
    };

    ReportData.prototype.load = function (cd, dcd, sc, from, kijun, callback) {
      var url = BST.Const.csurl + 'nippo';
      var req = {
        cd: cd,
        dcd: dcd,
        sc: sc,
        from: from,
        kijun: kijun
      };
      var _self = this;

      BST.Com.post(url, req, function (res) {
        if (res.suc) {
          _self._data = res.data;
        } else {
          _self._data = null;
        }

        // debug
        // if (res.data) {
        //    res.data.list = [
        //         {
        //             aaa: 49
        //             , bbb: 26.2
        //             , ccc: 15
        //         }
        //         , {
        //             aaa: 49
        //             , bbb: 26.2
        //             , ccc: 15
        //         }
        //         , {
        //             aaa: 49
        //             , bbb: 26.2
        //             , ccc: 15
        //         }
        //         , {
        //             aaa: 49
        //             , bbb: 26.2
        //             , ccc: 15
        //         }
        //         , {
        //             aaa: 49
        //             , bbb: 26.2
        //             , ccc: 15
        //         }
        //         , {
        //             aaa: 49
        //             , bbb: 26.2
        //             , ccc: 15
        //         }
        //    ];
        // }

        callback(res);
      });
    };

    // ReportData.prototype.load = function (cd, dcd, sc, from, kijun, callback) {
    //    var url = BST.Const.csurl + "nippo";
    //    var req = {
    //        cd: cd
    //        , dcd: dcd
    //        , sc: sc
    //        , from: from
    //        , kijun: kijun
    //    };
    //    var _self = this;

    //    $.ajax({
    //        type: "POST"
    //        , datatype: "json"
    //        , data: JSON.stringify(req)
    //        , url: url
    //        , contentType: "application/json; charset=utf-8"
    //    }).then(function (data) {
    //        _self._data = data;
    //        if (callback) {
    //            var res = BST.Com.createAjaxResult(true, _self._data);
    //            callback(res);
    //        }

    //    }).fail(function (err) {
    //        //_self._data = null;
    //        if (callback) {
    //            var res = BST.Com.createAjaxResult(false, err);
    //            callback(res);
    //        }
    //        console.log('report data error:' + err);
    //    }).always(function () {
    //    });

    // }

    // ReportData.prototype.loadImg = function (cd, dcd, sc, from, kijun, width, height, callback) {
    //    var url = BST.Const.imgurl + "taiko/nippo";
    //    var req = {
    //        cd: cd
    //        , dcd: dcd
    //        , sc: sc
    //        , from: from
    //        , kijun: kijun
    //        , width: width
    //        , height: height
    //    };
    //    var _self = this;

    //    $.ajax({
    //        type: "POST"
    //        , datatype: "json"
    //        , data: JSON.stringify(req)
    //        , url: url
    //        , contentType: "application/json; charset=utf-8"
    //    }).then(function (data) {
    //        _self._data = data;
    //        if (callback) {
    //            var res = BST.Com.createAjaxResult(true, _self._data);
    //            callback(res);
    //        }

    //    }).fail(function (err) {
    //        //_self._data = null;
    //        if (callback) {
    //            var res = BST.Com.createAjaxResult(false, err);
    //            callback(res);
    //        }
    //        console.log('login data error:' + err);
    //    }).always(function () {
    //    });

    // }

    return ReportData;
  })();
})(BST || (BST = {}));
