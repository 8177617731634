var BST;
(function (BST) {
  var LoginCtr = BST.LoginCtr = (function () {
    function LoginCtr (type) {
      // シングルトン
      // var _self = arguments.callee;
      // if (!_self._instance) {
      //    this.init.apply(this, arguments);
      //    _self._instance = this;
      // }
      // return _self._instance;

      // でない
      this.init(type);
    }

    LoginCtr.prototype.init = function (type) {
      var _self = this;
      var loginData = this.loginData = new BST.LoginData();
      this.view = new BST.Login('body', {
        type: type,
        callbackLogin: function (ccd, id, pass) {
          loginData.login(ccd, id, pass, function (res) {
            if (res.suc) {
              // _self.onLogined(ok, data);
              // loginした
              $(_self).trigger('login', res);

              _self.view.visible(false);

              // セッション監視開始
              _self.setupMo();
            } else {
              _self.view.message(res.mess);
            }
          });
        }
      });

      // this.setupMo();
    };

    // ログイン画面表示
    LoginCtr.prototype.login = function () {
      // this.view.defaultIdPass("pC7eQxz3", "admweb01", "pass");
      // this.view.defaultIdPass("best-ts", "root", "pass");
      this.view.defaultIdPass('', '', '');
      // this.view.defaultIdPass("drive", "admweb01", "pass");
      // this.view.defaultIdPass("TESTA", "user100", "zMCcS9PE");
      // this.view.defaultIdPass("yama", "aaa", "EQuy7cVp");
      // this.view.defaultIdPass("yamaguchi", "ccc", "k32E9tD0");
      // this.view.defaultIdPass("drive", "mix39", "pass");
      // this.view.defaultIdPass("drive2", "hase", "PlOxBQXU");
      // this.view.defaultIdPass("yama", "bbb", "uK1tCx0d");
      // this.view.defaultIdPass("drive2", "driver", "BCthYqsO"); // ドライバー権限
      this.view.visible(true);
    };

    LoginCtr.prototype.logout = function () {
      this.loginData.logout(BST.Com.session, function (res) {
        if (res.data.ecd === 0) {
          BST.Com.reload();
        }
      });
    };

    // ログインした結果
    // LoginCtr.prototype.onLogined = function (ok, data) {
    //    if (ok) {

    //    }
    // }

    LoginCtr.prototype.setupMo = function () {
      var _self = this;
      this._active = false;
      this._timerId = setInterval(function () {
        if (_self._active) {
          _self.checkSession();
        }
      }, 5 * 60 * 1000);

      $('body').on('mousemove', function () {
        _self._active = true;
      });
    };

    LoginCtr.prototype.checkSession = function () {
      var _self = this;
      this.loginData.checkSession(BST.Com.session, function (res) {
        if (res.suc) {
          if (res.data.ecd === 0) {
            _self._active = false;
          }
        }
      });
    };

    // LoginCtr.relogin = function () {
    //    location = location.href;
    // }

    return LoginCtr;
  })();
})(BST || (BST = {}));
