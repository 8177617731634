var BST;
(function (BST) {
  var Mv2d = BST.Mv2d = (function () {
    function Mv2d (parent, options) {
      parent = parent || $('body');
      var opt = this.opt = options || {};
      // var data = this.data = opt.data || null;
      this.max = opt.max || 10;
      this.callbackSelected = opt.callbackSelected;
      this.callbackKarute = opt.callbackKarute;
      this.callbackStar = opt.callbackStar;

      var _self = this;
      var base = this.base = $('<div>').addClass('bst-mv-2d').appendTo(parent);
      base.on('click', function (e) {
        // var ominx = e.offsetX - 8;
        // var ominy = e.offsetY - 8;
        // var omaxx = e.offsetX + 8;
        // var omaxy = e.offsetY + 8;
        _bodyClick = false; // バブリングフェーズで、イベントがとれたのでクリア

        _self.clearViewSelecter();

        var ms = [];

        // var sw2 = BST.MvListData.IMG_2D_SIZE / 2;
        if (_self.data.ids) {
          for (var i = 0; i < _self.data.ids.length; i++) {
            if (_self.isHit(e.offsetX, e.offsetY, _self.data.ids[i].x, _self.data.ids[i].y)) {
              var m = _self.getMovie(_self.data.ids[i].id);
              if (m) {
                ms.push(m);
              }
            }

            // var minx = _self.data.ids[i].x - sw2;
            // var miny = _self.data.ids[i].y - sw2;
            // var maxx = _self.data.ids[i].x + sw2;
            // var maxy = _self.data.ids[i].y + sw2;

            /// /if (
            /// /      Math.abs(red.x - blue.x) < red.width / 2 + blue.width / 2 //横の判定
            /// /      &&
            /// /      Math.abs(red.y - blue.y) < red.height / 2 + blue.height / 2 //縦の判定
            /// /) {
            /// /    console.log("hit"); // hit
            /// /}
            // if (miny > e.offsetY || maxy < e.offsetY || minx > e.offsetX || maxx < e.offsetX) {
            //    //return false;
            // } else {
            //    //return true;
            //    var m = _self.getMovie(_self.data.ids[i].id);
            //    if (m)
            //        ms.push(m);
            // }
          }

          // eslint-disable-next-line no-empty
          if (ms.length === 0) {

          } else if (ms.length === 1) {
            if (_self.callbackStar) _self.callbackStar(ms[0]);
          } else {
            // if (_self.callbackStar) _self.callbackStar(ms[0]);
            _self.viewSelecter(e.offsetX, e.offsetY, ms);
          }
        }
      });
      base.on('mousemove', function (e) {
        if (_self.data.ids) {
          for (var i = 0; i < _self.data.ids.length; i++) {
            if (_self.isHit(e.offsetX, e.offsetY, _self.data.ids[i].x, _self.data.ids[i].y)) {
              _self.base.css({ cursor: 'pointer' });
              return;
            }
          }
          _self.base.css({ cursor: 'default' });
        }
      });
      base.on('mouseleave', function () {
        _self.base.css({ cursor: 'default' });
      });
      /// / bodyクリックで2d画面を閉じるようにする
      var _tid = null;
      var _bodyClick = false;
      // $("body").get(0).addEventListener('click', function () {
      //    _bodyClick = true;
      //    if (_tid) {
      //        clearTimeout(_tid);
      //        _tid = null;
      //    }

      //    _tid = setTimeout(function () {
      //        if (_bodyClick) {
      //            _tid = null;

      //            _self.disp(false);
      //        }
      //    },100);

      // }, true);

      this.clear();
    }

    Mv2d.prototype.clear = function () {
      this.base.empty();
      this.img = null;
      this.ul = null;
      this.data = {};
      this.movies = [];
    };

    Mv2d.prototype.update = function (url, data, movies) {
      this.clear();

      // データなし
      if (!url || !data || !movies) {
        $('<div>').addClass('mess').text('データを取得できませんでした。').appendTo(this.base);
        return;
      }

      var img = $("<img src='" + url + "'>");

      var _self = this;
      // コールバックを設定
      img.on('load', function () {
        _self.data = data;
        _self.movies = movies;
        // _self.createRects(data);

        // alert("load complete");
      });
      img.on('error', function () {
        img.remove();
        $('<div>').addClass('mess').text('データを取得できませんでした。').appendTo(_self.base);

        // alert("error");
      });

      // 画像読み込み開始
      // img.src = url;

      img.appendTo(this.base);

      this.img = img;
    };

    // Mv2d.prototype.createRects = function (data) {
    //    var _self = this;
    //    if (data.ids) {
    //        var sw2 = BST.MvListData.IMG_2D_SIZE / 2;
    //        for (var i = 0; i < data.ids.length; i++) {

    //            var sx = data.ids[i].x;
    //            var sy = data.ids[i].y;

    //            var sminx = sx - sw2;
    //            var sminy = sy - sw2;
    //            var smaxx = sx + sw2;
    //            var smaxy = sy + sw2;

    //            var re = $("<div>").addClass("rect").css({ top: sminy + "px", left: sminx + "px", width: BST.MvListData.IMG_2D_SIZE + "px", height: BST.MvListData.IMG_2D_SIZE + "px" }).appendTo(this.base);
    //            re.on("click", function () {
    //                _self.base.trigger("click");
    //            });
    //        }
    //    }
    // }

    Mv2d.prototype.offset = function (of) {
      this.base.css(of);
    };

    Mv2d.prototype._disp = false;
    Mv2d.prototype.disp = function (b) {
      if (b === undefined) {
        return this._disp;
      }

      this._disp = b;

      if (b) {
        this.base.show();
      } else {
        this.base.hide();
      }
    };

    Mv2d.prototype.getMovie = function (fid) {
      for (var i = 0; i < this.movies.length; i++) {
        if (this.movies[i].fid === fid) {
          return this.movies[i];
        }
      }

      return null;
    };

    Mv2d.prototype.viewSelecter = function (x, y, movies) {
      var _self = this;

      this.clearViewSelecter();

      if (this.base) {
        var ul = this.ul = $('<div>').addClass('ul').css({ top: y + 'px', left: x + 'px' }).appendTo(this.base);
        for (var i = 0; i < movies.length; i++) {
          var item = $('<div>').addClass('item').data('data', movies[i]).appendTo(ul);
          item.on('click', function (e) {
            e.stopPropagation();

            if (_self.callbackStar) {
              _self.callbackStar($(this).data('data'));
            }

            ul.remove();

            // if (_self.img)
            //    _self.img.empty();
          });
          $('<span>').addClass('star').text('★').appendTo(item);
          $('<span>').addClass('text').text(movies[i].date + ' ' + movies[i].dnm).appendTo(item);
        }
      }
    };

    Mv2d.prototype.clearViewSelecter = function () {
      if (this.ul) {
        this.ul.remove();
        this.ul = null;
      }
    };

    Mv2d.prototype.isHit = function (px, py, sx, sy) {
      var sw2 = BST.MvListData.IMG_2D_SIZE / 2;

      var sminx = sx - sw2;
      var sminy = sy - sw2;
      var smaxx = sx + sw2;
      var smaxy = sy + sw2;

      if (sminx <= px && px <= smaxx &&
                sminy <= py && py <= smaxy) {
        console.log('-sminx:' + sminx + '-px:' + px + '-smaxx:' + smaxx + '-sminy:' + sminy + '-py:' + py + '-smaxy:' + smaxy);
        return true;
      }

      return false;
    };

    return Mv2d;
  })();
})(BST || (BST = {}));
