var BST;
(function (BST) {
  var SettingCtr = BST.SettingCtr = (function () {
    var TYPE = 'setting-ctr';

    function SettingCtr () {
      var param = {};
      BST.BaseCtr.call(this, TYPE, param);

      this._data = new BST.SettingData();
      this._format = this._data.format2;
      if (BST.Com.isAdminU()) {
        this._format = this._data.format;
      }

      this.menu = $('#app-setting');

      this.items = [];
      this.views = [];

      this.createMenu();
      // this.createEval();
      // this.createOrg();
      // this.createCar();
      // this.createDriver();
      // this.createUser();
      // this.createVehicle();

      for (var i = 0; i < this._format.menu.length; i++) {
        var type = this._format.menu[i].type;

        if (type === 'eva') {
          this.views.push(this.createEval());
        } else if (type === 'kyoten') {
          this.views.push(this.createOrg());
        } else if (type === 'car') {
          this.views.push(this.createCar());
        } else if (type === 'driver') {
          this.views.push(this.createDriver());
        } else if (type === 'user') {
          this.views.push(this.createUser());
        } else if (type === 'vehicle') {
          this.views.push(this.createVehicle());
        }
      }
    }
    BST.Util.inherits(SettingCtr, BST.BaseCtr);

    // BaseCtr関係
    SettingCtr.prototype.getPanel = function () {
      return $('#app-setting-contents');
    };

    SettingCtr.prototype.__changedPanel = function (type) {
      if (type !== TYPE) {
        for (var i = 0; i < this.items.length; i++) {
          this.items[i].removeClass('item-active');
        }
      }
    };

    // BaseCtr関係以外
    SettingCtr.prototype.active = function () {
      // this.dispPanel(3);
      this.dispPanel();
    };

    SettingCtr.prototype.click = function (index) {
      var item = this.items[index];
      if (item) {
        item.trigger('click');
      }
    };

    SettingCtr.prototype.createMenu = function () {
      var base = $('<div>').addClass('bst-setting-ctr-menu').appendTo(this.menu);
      var title = $('<div>').addClass('title').text('設　定').appendTo(base);
      var list = $('<div>').addClass('list').appendTo(base);

      var _self = this;
      for (var i = 0; i < this._format.menu.length; i++) {
        var m = this._format.menu[i];
        var item = $('<div>').addClass('item').text(m.name).data('data', m).appendTo(list);
        this.items.push(item);
        item.on('click', function () {
          _self.active();
          for (var j = 0; j < _self.items.length; j++) {
            _self.items[j].removeClass('item-active');
          }

          var d = $(this).data('data');
          $(this).addClass('item-active');
          _self.onChangeMenu(d.type);
        });
      }
    };

    // メニューが切り替わった
    SettingCtr.prototype.onChangeMenu = function (type) {
      var _self = this;

      // this._evalView.disp(false);
      // this._orgView.disp(false);
      // this._carView.disp(false);
      // this._driveView.disp(false);
      // this._userView.disp(false);
      // this._vehicleView.disp(false); //車載器
      for (var i = 0; i < this.views.length; i++) {
        this.views[i].disp(false);
      }

      if (type === 'eva') {
        this._data.evaData(BST.Com.session, function (res) {
          if (res.suc) {
            var eva = res.data;
            // 画面更新
            _self._evalView.viewUpdate(eva);
            _self._evalView.disp(true);
          } else {
            // error
            // データがない
            // if (res.data.ecd == 310) {
            //    // デフォルト表示
            //    var eva = {
            //        saiten: 0.2
            //        , grav: 0.2
            //        , blank: 20
            //        , term: 8
            //    };
            //    _self._evalView.viewUpdate(eva);
            //    _self._evalView.disp(true);
            // }
            // else
            BST.Com.dialog(res.mess);
          }
        });
      } else if (type === 'kyoten') {
        // this.isEvaData(function (b) {
        //    if (!b) {
        //        var str = "評価の設定を先に行ってください。";
        //        BST.Com.dialog(str);
        //        return;
        //    }

        _self._data.kyotenList(BST.Com.session, function (res) {
          if (res.suc) {
            var org = res.data.org;
            // 画面更新
            _self._orgView.update(org);
            _self._orgView.disp(true);
          } else {
            // error
            BST.Com.dialog(res.mess);
          }
        });
        // });
      } else if (type === 'car') {
        this._data.carList(BST.Com.session, function (res) {
          if (res.suc) {
            var str = '';
            if (res.data.org.length === 0) {
              if (str !== '') str += ',';
              str += '拠点';
              // BST.Com.dialog("拠点の登録を先に行ってください。");
              // return;
            }
            if (res.data.type.length === 0) {
              if (str !== '') str += ',';
              str += '車両形状';
              // BST.Com.dialog("車両形状の登録を先に行ってください。");
              // return;
            }
            if (res.data.rec.length === 0) {
              if (str !== '') str += ',';
              str += 'ドライブレコーダ';
              // BST.Com.dialog("ドライブレコーダの登録を先に行ってください。");
              // return;
            }
            if (str !== '') {
              str += 'の登録を先に行ってください。';
              BST.Com.dialog(str);
              return;
            }

            var car = res.data.car;
            var org = res.data.org;
            var rec = res.data.rec;
            var type = res.data.type;
            // 画面更新
            _self._carView.update(car, org, type, rec);
            _self._carView.disp(true);
          } else {
            // error
            BST.Com.dialog(res.mess);
          }
        });
      } else if (type === 'driver') {
        this._data.driverList(BST.Com.session, function (res) {
          if (res.suc) {
            var str = '';
            if (res.data.org.length === 0) {
              if (str !== '') str += ',';
              str += '拠点';
              // BST.Com.dialog("拠点の登録を先に行ってください。");
              // return;
            }
            if (str !== '') {
              str += 'の登録を先に行ってください。';
              BST.Com.dialog(str);
              return;
            }

            // 画面更新
            _self._driveView.update(
              res.data.drv
              , res.data.org
              , _self._data.sexs
              , res.data.nt
              , res.data.dNum
              , res.data.cNum
            );
            _self._driveView.disp(true);
          } else {
            // error
            BST.Com.dialog(res.mess);
          }
        });
      } else if (type === 'user') {
        _self.updateUserList();
      } else if (type === 'vehicle') {
        this._data.devList(BST.Com.session, function (res) {
          if (res.suc) {
            var str = '';
            if (res.data.org.length === 0) {
              if (str !== '') str += ',';
              str += '拠点';
              // BST.Com.dialog("拠点の登録を先に行ってください。");
              // return;
            }
            if (res.data.car.length === 0) {
              if (str !== '') str += ',';
              str += '車両';
              // BST.Com.dialog("車両の登録を先に行ってください。");
              // return;
            }

            if (str !== '') {
              str += 'の登録を先に行ってください。';
              BST.Com.dialog(str);
              return;
            }

            // 画面更新
            _self._vehicleView.update(
              res.data.dr
              , res.data.org
              , res.data.car
            );
            _self._vehicleView.disp(true);
          } else {
            // error
            BST.Com.dialog(res.mess);
          }
        });
      }
    };

    // SettingCtr.prototype.isEvaData = function (callback) {
    //    this._data.evaData(BST.Com.session, function (res) {
    //        if (res.suc) {
    //            if (callback)
    //                callback(true);
    //        }
    //        else {
    //            //error
    //            // データがない
    //            if (res.data.ecd == 310) {
    //                if (callback)
    //                    callback(false);
    //            }
    //            else
    //                BST.Com.dialog(res.mess);
    //        }
    //    });
    // }

    SettingCtr.prototype.updateUserList = function () {
      var _self = this;

      this._data.userList(BST.Com.session, function (res) {
        if (res.suc) {
          // 先頭に選択しないを追加
          // res.data.drv.unshift({
          //    cd: ""
          //    , nm: "選択しない"
          // });
          _self._userView.update(
            res.data.usr
            , res.data.org
            , res.data.drv
            , res.data.orgAdm
          );
          _self._userView.disp(true);
        } else {
          // error
          BST.Com.dialog(res.mess);
        }
      });
    };

    SettingCtr.prototype.createEval = function () {
      var _self = this;

      // 画面を生成する基点要素をきめる。
      var root = this.getPanel();

      // 評価設定
      this._evalView = new BST.SettingEval(root, {
        callbackUpdate: function (data) {
          _self._data.evaUpdate(BST.Com.session, data.grav, data.saiten, data.blank, data.term, data.dist, function (res) {
            if (res.suc) {
              var eva = res.data;
              // 画面更新
              _self._evalView.viewUpdate(eva);
              BST.Com.dialog('適用されました');
            } else {
              // error
              // データがない
              if (res.data.ecd === 310) {
                // データ作る
                _self._data.evaCreate(BST.Com.session, data.grav, data.saiten, data.blank, data.term, data.dist, function (res2) {
                  if (res2.suc) {
                    var eva = res2.data;
                    // 画面更新
                    _self._evalView.viewUpdate(eva);
                  } else {
                    BST.Com.dialog(res2.mess);
                  }
                });
              } else {
                BST.Com.dialog(res.mess);
              }
            }
          });
        }
      });

      return this._evalView;
    };

    SettingCtr.prototype.createOrg = function () {
      var _self = this;

      // 画面を生成する基点要素をきめる。
      var root = this.getPanel();

      this._orgDi = new BST.SettingDialogOrgEdit();

      this._orgView = new BST.SettingOrg(root, {
        callbackCreate: function () {
          var _new = {};
          _new.nm = '';

          _self._orgDi.open(_new, function (data) {
            // 拠点作成
            _self._data.kyotenCreate(BST.Com.session, data.nm, data.nm2, data.nm3, data.nm4, function (res) {
              if (res.suc) {
                _self._orgView.update(res.data.org);
                BST.Com.dialog('登録しました');
                _self._orgDi.close();
              } else {
                // error
                BST.Com.dialog(res.mess);
              }
            });
          }, function () {
            // alert("cancel");
            _self._orgDi.close();
          }, 1);
        },
        callbackUpdate: function (data) {
          data = BST.Util.deepCopy(data);
          _self._orgDi.open(data, function (data) {
            // 拠点更新
            _self._data.kyotenUpdate(BST.Com.session, data.cd, data.nm, data.nm2, data.nm3, data.nm4, function (res) {
              if (res.suc) {
                if (res.suc) {
                  _self._orgView.update(res.data.org);
                  BST.Com.dialog('編集しました');
                  _self._orgDi.close();
                }
              } else {
                // error
                BST.Com.dialog(res.mess);
              }
            });
          }, function () {
            // alert("cancel");
            _self._orgDi.close();
          }, 2);
        },
        callbackDelete: function (data) {
          BST.Com.dialogDelCan('削除してよろしいですか？',
            function () {
              // 拠点削除
              _self._data.kyotenDelete(BST.Com.session, data.cd, function (res) {
                if (res.suc) {
                  _self._orgView.update(res.data.org);
                  BST.Com.dialogDelCanClose();
                  BST.Com.dialog('削除しました');
                } else {
                  // error
                  BST.Com.dialog(res.mess);
                }
              });
            }, function () {
              // cancel
              BST.Com.dialogDelCanClose();
            });
        }
      });

      return this._orgView;
    };

    SettingCtr.prototype.createCar = function () {
      var _self = this;

      // 画面を生成する基点要素をきめる。
      var root = this.getPanel();

      this._carDi = new BST.SettingDialogCarEdit();

      this._carView = new BST.SettingCar(root, {
        callbackCreate: function (orgs, types, recs) {
          var _new = {};
          _new.nm = '';
          _new.ocd = orgs[0].cd;
          _new.type = types[0].id;
          _new.rcd = recs[0].cd;

          _self._carDi.open(orgs, types, recs, _new, function (data) {
            // 車両作成
            // _self._data.carCreate(BST.Com.session, data.cd, data.ocd, data.rcd, data.type, function (res) {
            _self._data.carCreate(BST.Com.session, data.nm, data.ocd, data.rcd, data.type, function (res) {
              if (res.suc) {
                _self._carView.update(
                  res.data.car
                  , res.data.org
                  , res.data.type
                  , res.data.rec
                );

                BST.Com.dialog('登録しました');
                _self._carDi.close();
              } else {
                // error
                BST.Com.dialog(res.mess);
              }
            });
          }, function () {
            // alert("cancel");
            _self._carDi.close();
          }, 1);
        },
        callbackUpdate: function (data, orgs, types, recs) {
          data = BST.Util.deepCopy(data);
          _self._carDi.open(orgs, types, recs, data, function (data) {
            // 車両更新
            _self._data.carUpdate(BST.Com.session, data.cd, data.nm, data.ocd, data.rcd, data.type, function (res) {
              if (res.suc) {
                _self._carView.update(
                  res.data.car
                  , res.data.org
                  , res.data.type
                  , res.data.rec
                );

                BST.Com.dialog('編集しました');
                _self._carDi.close();
              } else {
                // error
                BST.Com.dialog(res.mess);
              }
            });
          }, function () {
            // alert("cancel");
            _self._carDi.close();
          }, 2);
        },
        callbackDelete: function (data) {
          BST.Com.dialogDelCan('削除してよろしいですか？',
            function () {
              // 車両削除
              _self._data.carDelete(BST.Com.session, data.cd, data.nm, data.ocd, data.rcd, data.type, function (res) {
                if (res.suc) {
                  _self._carView.update(
                    res.data.car
                    , res.data.org
                    , res.data.type
                    , res.data.rec
                  );
                  BST.Com.dialogDelCanClose();
                  BST.Com.dialog('削除しました');
                } else {
                  // error
                  BST.Com.dialog(res.mess);
                }
              });
            },
            function () {
              // cancel
              BST.Com.dialogDelCanClose();
            });
        }
      });

      return this._carView;
    };

    SettingCtr.prototype.createDriver = function () {
      var _self = this;

      // 画面を生成する基点要素をきめる。
      var root = this.getPanel();

      this._drvDi = new BST.SettingDialogDrvEdit();

      this._driveView = new BST.SettingDrv(root, {
        callbackCreate: function (orgs, nts) {
          var _new = {};
          _new.nm = '';
          _new.ocd = orgs[0].cd;
          _new.sx = _self._data.sexs[0].id;
          _new.nt = nts[0].cd;
          _new.ni = '';

          _self._drvDi.open(orgs, _self._data.sexs, nts, _new, function (data) {
            // ドライバー作成
            _self._data.driverCreate(BST.Com.session, data.nm, data.ocd, data.sx, data.nt, data.ni, function (res) {
              if (res.suc) {
                _self._driveView.update(
                  res.data.drv
                  , res.data.org
                  , _self._data.sexs
                  , res.data.nt
                  , res.data.dNum
                  , res.data.cNum
                );

                BST.Com.dialog('登録しました');
                _self._drvDi.close();
              } else {
                // error
                BST.Com.dialog(res.mess);
              }
            });
          }, function () {
            // alert("cancel");
            _self._drvDi.close();
          }, 1);
        },
        callbackUpdate: function (data, orgs, nts) {
          // var orgs = [{ "cd": "c6nVcug2", "nm": "" }, { "cd": "L8nQ7Am9", "nm": "ｓｓｓ" }, { "cd": "Wr4ziLdG", "nm": "第２営業部" }, { "cd": "B4nvKeFE", "nm": "第３営業部" }];
          // var data = { "ocd": "L8nQ7Am9", "cd": "Gw2MpBTP", "nm": "岡島　昇", "sx": 1 };
          // var sexs = [{ "id": 1, "nm": "男" }, { "id": 2, "nm": "女" }];
          data = BST.Util.deepCopy(data);
          _self._drvDi.open(orgs, _self._data.sexs, nts, data, function (data) {
            // alert(JSON.stringify(data));

            // ドライバー更新
            _self._data.driverUpdate(BST.Com.session, data.cd, data.nm, data.ocd, data.sx, data.nt, data.ni, function (res) {
              if (res.suc) {
                _self._driveView.update(
                  res.data.drv
                  , res.data.org
                  , _self._data.sexs
                  , res.data.nt
                  , res.data.dNum
                  , res.data.cNum
                );

                BST.Com.dialog('編集しました');
                _self._drvDi.close();
              } else {
                // error
                BST.Com.dialog(res.mess);
              }
            });
          }, function () {
            // alert("cancel");
            _self._drvDi.close();
          }, 2);
        },
        callbackDelete: function (data) {
          BST.Com.dialogDelCan("削除してよろしいですか？<br><div style='color: #ff4c4c;text-align: left;margin-left: 47px;font-size: 15px;white-space: nowrap;margin-top: 3px;'>※ドライバーを削除すると<br>　評価データなども削除されます。</div>"
            , function () {
              // ドライバー削除
              _self._data.driverDelete(BST.Com.session, data.cd, data.nm, data.ocd, data.sx, data.nt, data.ni, function (res) {
                if (res.suc) {
                  _self._driveView.update(
                    res.data.drv
                    , res.data.org
                    , _self._data.sexs
                    , res.data.nt
                    , res.data.dNum
                    , res.data.cNum
                  );
                  BST.Com.dialogDelCanClose();
                  BST.Com.dialog('削除しました');
                } else {
                  // error
                  BST.Com.dialog(res.mess);
                }
              });
            }, function () {
              // cancel
              BST.Com.dialogDelCanClose();
            });
        },
        callbackCsv: function () {
          _self._data.driverCsv(BST.Com.session, function (res) {
            if (res.suc) {
              if (res.data.ecd === 0) {
                // location.href = res.data.emsg;
                location.href = BST.Const.csd + res.data.emsg;
              }
            } else {
              // error
              BST.Com.dialog(res.mess);
            }
          });
        }
      });

      return this._driveView;
    };

    SettingCtr.prototype.createUser = function () {
      var _self = this;

      // 画面を生成する基点要素をきめる。
      var root = this.getPanel();

      this._userDi = new BST.SettingDialogUserEdit();
      this._userInfo = new BST.SettingDialogUserInfo();

      this._userView = new BST.SettingUser(root, {
        filters: this._data.userData,
        callbackCreate: function (orgs, drvs, orgAdms) {
          // user作成
          var _new = {
            sc: null,
            cd: '',
            nm: '',
            roleDriver: '',
            roleCloud: [],
            roleAdmin: 0,
            roleView: 0
          };

          _self._userDi.open(orgs, drvs, orgAdms, _new, function (data) {
            _self._data.userCreate(BST.Com.session, data, function (res) {
              if (res.suc) {
                // BST.Com.dialog("登録しました<br>パスワード:"+res.data.emsg);
                data.emsg = res.data.emsg;
                _self._userInfo.open(orgs, drvs, data);
                _self._userDi.close();

                // リスト更新
                _self.updateUserList();
              } else {
                // error
                BST.Com.dialog(res.mess);
              }
            });
          }, function () {
            // alert("cancel");
            _self._userDi.close();
          }, 1);
        },
        callbackUpdate: function (data, orgs, drvs, orgAdms) {
          // ユーザー情報の詳細
          _self._data.userInfo(BST.Com.session, data.cd, data.ucd, function (res) {
            if (res.suc) {
              data = BST.Util.deepCopy(data);
              // 編集ダイアログ
              _self._userDi.open(orgs, drvs, orgAdms, res.data, function (data) {
                // user更新
                _self._data.userUpdate(BST.Com.session, data, function (res) {
                  if (res.suc) {
                    _self._userView.update(
                      res.data.usr
                      , res.data.org
                      , res.data.drv
                      , res.data.orgAdm
                    );

                    BST.Com.dialog('編集しました');
                    _self._userDi.close();
                  } else {
                    // error
                    BST.Com.dialog(res.mess);
                  }
                });
              }, function () {
                // cancel
                _self._userDi.close();
              }, 2, function (data) {
                // pw
                BST.Com.dialogOkCan(
                  'パスワードを再発行して<br>よろしいですか？'
                  , function () {
                    // ok
                    _self._data.userCreatePw(BST.Com.session, data, function (res) {
                      if (res.suc) {
                        // BST.Com.dialog("パスワードが再発行されました。<br>パスワード:" + res.data.emsg, null, { "textSelect": true });
                        BST.Com.dialog(
                          'パスワードが再発行されました。<br>' +
                          "<p class='text-color-blue' style='margin: 0px;text-align:left;'>パスワード：" + res.data.emsg +
                          "<p class='" + BST.VDialog.TYPE_COL_BLUE + "' style='font-size:13px;margin: 0px;text-align:left;'>　　　　　　　※パスワードはこのダイアログを閉じると<br>　　　　　　　　見ることができなくなるので、記録を残してください。</p>" +
                          '</p>'
                          , {
                            size: BST.VDialog.SIZE_M,
                            textSelect: true
                          });
                        _self._userDi.close(); // 変更ダイアログ クローズ
                      } else {
                        // error
                        BST.Com.dialog(res.mess);
                      }
                    });
                    BST.Com.dialogOkCanClose();
                  }, function () {
                    // can
                    BST.Com.dialogOkCanClose();
                  }
                  , '再発行する'
                );
              });
            } else {
              // error
              BST.Com.dialog(res.mess);
            }
          }, function () {
            // alert("cancel");
            _self._userDi.close();
          });
        },
        callbackDelete: function (data) {
          BST.Com.dialogDelCan('削除してよろしいですか？'
            , function () {
              // user削除
              _self._data.userDelete(BST.Com.session, data, function (res) {
                if (res.suc) {
                  _self._userView.update(
                    res.data.usr
                    , res.data.org
                    , res.data.drv
                    , res.data.orgAdm
                  );
                  BST.Com.dialogDelCanClose();
                  BST.Com.dialog('削除しました');
                } else {
                  // error
                  BST.Com.dialog(res.mess);
                }
              });
            }, function () {
              // cancel
              BST.Com.dialogDelCanClose();
            });
        }
        //, callbackChangeFliter: function (data) {
        // }
      });

      return this._userView;
    };

    // 車載器
    SettingCtr.prototype.createVehicle = function () {
      var _self = this;

      // 画面を生成する基点要素をきめる。
      var root = this.getPanel();

      this._vehicleDi = new BST.SettingDialogVehicleEdit();

      this._vehicleView = new BST.SettingVehicle(root, {
        callbackCreate: function (orgs, cars) {
          var _new = {};
          _new.sn = '';
          _new.ocd = orgs[0].cd;
          _new.ccd = cars[0].ccd;

          _self._vehicleDi.open(orgs, cars, _new, function (data) {
            // 車載器作成
            _self._data.devCreate(BST.Com.session, data.sn, data.ocd, data.ccd, function (res) {
              if (res.suc) {
                _self._vehicleView.update(
                  res.data.dr
                  , res.data.org
                  , res.data.car
                );

                BST.Com.dialog('登録しました');
                _self._vehicleDi.close();
              } else {
                // error
                BST.Com.dialog(res.mess);
              }
            });
          }, function () {
            _self._vehicleDi.close();
          }, 1);
        },
        callbackUpdate: function (data, orgs, cars) {
          data = BST.Util.deepCopy(data);
          _self._vehicleDi.open(orgs, cars, data
            , function (data) {
              // 車載器更新
              _self._data.devUpdate(BST.Com.session, data.cd, data.sn, data.ocd, data.ccd, function (res) {
                if (res.suc) {
                  _self._vehicleView.update(
                    res.data.dr
                    , res.data.org
                    , res.data.car
                  );

                  BST.Com.dialog('編集しました');
                  _self._vehicleDi.close();
                } else {
                  // error
                  BST.Com.dialog(res.mess);
                }
              });
            }, function () {
              _self._vehicleDi.close();
            }, 2);
        },
        callbackDelete: function (data) {
          BST.Com.dialogDelCan('削除してよろしいですか？'
            , function () {
              // 車両削除
              _self._data.devDelete(BST.Com.session, data.cd, data.sn, data.ocd, data.ccd, function (res) {
                if (res.suc) {
                  _self._vehicleView.update(
                    res.data.dr
                    , res.data.org
                    , res.data.car
                  );
                  BST.Com.dialogDelCanClose();
                  BST.Com.dialog('削除しました');
                } else {
                  // error
                  BST.Com.dialog(res.mess);
                }
              });
            }, function () {
              // cancel
              BST.Com.dialogDelCanClose();
            });
        }
      });

      return this._vehicleView;
    };

    return SettingCtr;
  })();
})(BST || (BST = {}));
