$(function () {
  var main = new BST.Main();

/*
  var loginData = new BST.LoginData();
  var login = new BST.Login("body", {
     callbackLogin: function(id,pass){
         loginData.login(id, pass, function (ok, data) {
             if (ok) {

             }
         });

     }

  });
*/
});
