var BST;
(function (BST) {
  var MvListData = BST.MvListData = (function () {
    MvListData.IMG_2D_SIZE = 16;

    MvListData.get2dPath = function (data) {
      if (data.fid === undefined || data.fid === null) return null;

      return BST.Const.imgurl + '2m/' + data.rcd + '/' + data.fid + '/170/170/' + BST.Com.session;
    };

    // fid 動画 tidサムネイル
    MvListData.getMoviePath = function (data, fid) {
      if (fid === undefined || fid === null) return null;

      return BST.Const.imgurl + 'movie/' + data.rcd + '/' + fid + '/' + BST.Com.session;
    };

    MvListData.prototype.format = {
      sort: [
        { label: '運行日付', key: 'date' },
        { label: 'G値', key: 'grav' }
      ],
      star: {
        label: '★分布図を開く'
      }
    };

    MvListData.prototype.pageMax = 12;

    MvListData.prototype.sample = [
      {
        onm: '営業部',
        // eslint-disable-next-line no-tabs
        nm: '長谷川　智昭',
        time: '2018/10/2 10:11',
        g: 0.1,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'
      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/3 10:11',
        g: 0.11,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'
      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/4 10:11',
        g: 0.12,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'
      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/5 10:11',
        g: 0.13,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/6 10:11',
        g: 0.14,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/7 10:11',
        g: 0.15,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/8 10:11',
        g: 0.16,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/9 10:11',
        g: 0.17,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/10 10:11',
        g: 0.18,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/11 10:11',
        g: 0.19,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/12 10:11',
        g: 0.20,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.21,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.22,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.23,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/24 10:11',
        g: 0.35,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      }
    ];

    MvListData.prototype.sample2 = [
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/02 10:11',
        g: 0.1,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_200.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_300.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/03 10:11',
        g: 0.11,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_200.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_300.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/20 10:11',
        g: 0.12,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_200.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_300.jpg'
        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/10 10:11',
        g: 0.4,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_200.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_300.jpg'

        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      },
      {
        onm: '営業部',
        nm: '長谷川　智昭',
        time: '2018/10/10 10:12',
        g: 0.3,
        imgs: [
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_100.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_200.jpg',
          'http://dcw.dev.takugi.net/0GEEbpKy3RmQMQIBUU4J_20180423072645_-0.0608_-0.2922_300.jpg'

        ],
        i2d: 'http://localhost:9000/v1/image/2d/2018-05-29/Wr4ziLdG/dX9zJmrk/gx5SF9QyOlRlSXIjbHy3/102/102/92e1a5ea-07f9-43a7-8bdb-acd05efe859b'

      }
    ];
    function MvListData () {

    }

    MvListData.prototype.load = function (type, cd, sc, from, kijun, callback) {
      var url = '';
      var req = {
        sc: sc,
        from: from,
        kijun: kijun
      };

      if (type === 'comp') {
        url = BST.Const.csurl + 'movie/comp';
        req.cd = cd;
      } else if (type === 'org') {
        url = BST.Const.csurl + 'movie/org';
        req.cd = cd;
      } else if (type === 'driver') {
        url = BST.Const.csurl + 'movie/drive';
        req.cd = cd;
      }

      var _self = this;

      BST.Com.post(url, req, function (res) {
        if (res.suc) {
          _self._data = res.data;
        } else {
          _self._data = null;
        }

        callback(res);
      });
    };

    MvListData.prototype.img = function (sc, w, h, ids) {
      ids = ids || [];

      var url = BST.Const.imgurl + '2dimg';
      url += '/' + w + '/' + h + '/' + MvListData.IMG_2D_SIZE + '/' + ids.join(',') + '/' + sc;

      return url;

      // var _self = this;

      // BST.Com.post(url, req, function (res) {
      //    if (res.suc)
      //        _self._data = res.data;
      //    else
      //        _self._data = null;

      //    callback(res);
      // });
    };
    MvListData.prototype.imgJson = function (sc, w, h, ids, callback) {
      var url = BST.Const.imgurl + '2djson';
      var req = {
        sc: sc,
        w: w,
        h: h,
        s: MvListData.IMG_2D_SIZE,
        ids: ids
      };

      var _self = this;
      BST.Com.post(url, req, function (res) {
        /*
        if (res.suc) {

        } else {

        }
        */
        callback(res);
      });

      return url;
    };

    // MvListData.prototype.load = function (type, cd, sc, from, to, kijun, cd2, callback) {
    //    var url = "";
    //    var req = {
    //        sc: sc
    //        , from: from
    //        , to: to
    //        , kijun: kijun
    //    };

    //    if (type == "comp") {
    //        url = BST.Const.csurl + "comp";
    //        req.cd = cd;
    //    } else if (type == "org") {
    //        url = BST.Const.csurl + "org";
    //        req.cd = cd;
    //    } else if (type == "driver") {
    //        url = BST.Const.csurl + "drive";
    //        req.dcd = cd;
    //        req.cd = cd2;
    //    }

    //    var _self = this;

    //    $.ajax({
    //        type: "POST"
    //        , datatype: "json"
    //        , data: JSON.stringify(req)
    //        , url: url
    //        , contentType: "application/json; charset=utf-8"
    //    }).then(function (data) {
    //        _self._data = data;
    //        if (callback) {
    //            //var res = _self.createResult(true, _self._data);
    //            var res = BST.Com.createAjaxResult(true, _self._data);
    //            callback(res);
    //        }

    //    }).fail(function (err) {
    //        //_self._data = null;
    //        if (callback) {
    //            //var res = _self.createResult(false, err);
    //            var res = BST.Com.createAjaxResult(false, err);
    //            callback(res);
    //        }
    //        console.log('list data error:' + err);
    //    }).always(function () {
    //    });
    // }

    // まとめたいなー
    // MvListData.prototype.createResult = function (success, data) {
    //    var res = {
    //        suc: false
    //        , mess: "通信エラーが発生しました。(" + data.status + ")"
    //        , data: data
    //    };
    //    if (success) {
    //        // エラーなし
    //        if (data.ecd == 0) {
    //            res.suc = true;
    //            res.mess = "";
    //        }
    //            // サーブレット内でエラーあり
    //        else {
    //            //res.login = false;
    //            res.mess = data.emsg;
    //        }

    //    }
    //        // それ以外のエラー
    //    else {
    //        //res.login = false;
    //    }

    //    return res;

    // }

    // MvListData.prototype.extendData = function (data) {
    //    data.fromTime = data.from + " " + data.time;
    // }

    return MvListData;
  })();
})(BST || (BST = {}));
