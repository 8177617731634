var BST;
(function (BST) {
  var Report = BST.Report = (function () {
    function Report (parent, opt) {
      this.parent = parent = parent || 'body';
      opt = opt || {};
      this._callbackChangeDate = opt.callbackChangeDate;

      this._pages = [];
      this._pageIndex = 0;
      // this.createHeader(parent);
      // this.createBody(this.body);
    }

    Report.prototype.colors = [
      '#59baff',
      '#4cd970',
      '#ffce59',
      '#da59ff',
      '#ff8c59',
      '#5959ff',
      '#a4cc47',
      '#ff5959',
      '#bfa34c',
      '#579936'
    ];

    // viewをつくり直す
    Report.prototype.createView = function (data, date) {
      this.clear();

      // init
      this._pageNum = 1;
      this._pageNum += Math.ceil(data.list.length / 5);
      this._data = data;

      this.create(data, date);
    };

    // viewをつくり直して、メッセージ表示
    Report.prototype.createViewMess = function (name, date, mess) {
      this.clear();

      this.createHeader(name, date);
      this.setMess(mess);
    };

    // viewをきりかえる
    Report.prototype.changeView = function (data) {
      this.clearPage();
      this.clearFooter();

      // init
      this._pageNum = 1;
      this._pageNum += Math.ceil(data.list.length / 5);
      this._data = data;

      // this.createBody(res.data.head);
      if (!this.page) {
        this.page = $('<div>').addClass('page-base').appendTo(this.body);
      }
      this._createPage(this.page, data, this._pageIndex);
      this._createFooter(this.body, this._pageNum);
    };

    // viewをメッセージ表示に切り替える
    Report.prototype.changeViewMess = function (mess) {
      this.clearPage();
      this.clearFooter();
      this.setMess(mess);
    };

    Report.prototype.setMess = function (mess) {
      this.mess.text(mess);
    };

    Report.prototype.create = function (data, date) {
      // this._createHeader(this.parent);
      // this.name.text(data.dnm);
      this.createHeader(BST.Util.textOverflowEllipsis(data.head.dnm, 20), date);
      if (!this.page) {
        this.page = $('<div>').addClass('page-base').appendTo(this.body);
      }
      this._createPage(this.page, data, this._pageIndex);
      this._createFooter(this.body, this._pageNum);
    };

    Report.prototype.createHeader = function (name, date) {
      date = date || new Date();

      this._createHeader(this.parent, date);
      this.name.text(BST.Util.textOverflowEllipsis(name, 20));
    };

    Report.prototype._createHeader = function (parent, date) {
      var _self = this;

      var rptBg = this.base = $('<div>').addClass('bst-report-bg').appendTo(parent);
      var rpt = $('<div>').addClass('bst-report').appendTo(rptBg);
      var mess = this.mess = $('<div>').addClass('mess').appendTo(rpt);
      var rptIn = this.body = $('<div>').appendTo(rpt);

      // row1
      var r1 = this.r1 = $('<div>').addClass('row1').appendTo(rptIn);
      var r1tbl = $('<div>').addClass('table').appendTo(r1);
      var r1tblIcon = $('<div>').addClass('icon').appendTo(r1tbl);
      var r1tblName = this.name = $('<div>').addClass('name').appendTo(r1tbl);
      var r1tblTitle = $('<div>').addClass('title').text('　日報').appendTo(r1tbl);
      var r1tblDate = $('<div>').addClass('date').appendTo(r1tbl);
      var dp = new BST.DatePicker(r1tblDate
        , {
          callbackSelected: function (text) {
            // alert(text);
            if (_self._callbackChangeDate) _self._callbackChangeDate(text);
          },
          css: {
            width: '100px'
          },
          right: true
        });
      dp.setDate(date);

      var btnClose = $('<div>').addClass('x-btn').appendTo(rpt);
      btnClose.on('click', function () {
        _self.clear();
      });

      // var r1tblText = $("<div>").addClass("text").appendTo(r1tbl);
      // var r1tblNumBase = $("<div>").appendTo(r1tbl);
      // var r1tblNum = this.gnum = $("<input>").val(0.3).addClass("num").appendTo(r1tblNumBase);
      // r1tblNum.spinner({
      //    step: 0.01
      //    , max: 0.5
      //    , min:0
      // });
      // var a = this.gnum.val();
    };

    Report.prototype._createFooter = function (parent, num) {
      var _self = this;
      // var fdummy = this._fdummy = $("<div>").addClass("footer-dummy").appendTo(parent);
      var footer = this._footer = $('<div>').addClass('footer').appendTo(parent);
      var panel = $('<div>').appendTo(footer);

      $('<div>').addClass('btn').addClass('al').appendTo(panel)
        .on('click', function () {
          _self._pageIndex--;
          if (_self._pageIndex < 0) _self._pageIndex = 0;

          no.text(_self._pageIndex + 1 + '/' + num);

          // ＶＩＥＷの切り替え
          _self._createPage(_self.page, _self._data, _self._pageIndex);
          for (var i = 0; i < _self._pages.length; i++) {
            if (_self._pageIndex === i) {
              _self._pages[i].show();
            } else {
              _self._pages[i].hide();
            }
          }
        });

      var no = $('<div>').addClass('num').appendTo(panel);
      no.text(this._pageIndex + 1 + '/' + num);

      $('<div>').addClass('btn').addClass('ar').appendTo(panel)
        .on('click', function () {
          _self._pageIndex++;
          if (_self._pageIndex >= num) _self._pageIndex = num - 1;

          no.text(_self._pageIndex + 1 + '/' + num);

          // ＶＩＥＷの切り替え
          _self._createPage(_self.page, _self._data, _self._pageIndex);
          for (var i = 0; i < _self._pages.length; i++) {
            if (_self._pageIndex === i) {
              _self._pages[i].show();
            } else {
              _self._pages[i].hide();
            }
          }
        });
    };

    // Report.prototype.createBody = function (data) {
    //    this._createPage(this.body, data, this._pageIndex);
    // }

    Report.prototype._createPage = function (parent, data, index) {
      if (this._pages[index]) return;

      var page = this._pages[index] = $('<div>').addClass('page').appendTo(parent);

      // row2
      var r2 = this.r2 = $('<div>').addClass('row2').appendTo(page);
      var r2pnl = $('<div>').addClass('panel-s').appendTo(r2);

      var r2lbl = $('<div>').addClass('label').appendTo(r2pnl);
      var r2lblorg = $('<div>').addClass('org').appendTo(r2lbl);
      r2lblorg.text(data.head.nm);
      // var r2lblnm = $("<div>").appendTo(r2lbl);
      /// /r2lblnm.text(data.head.dnm);
      // eslint-disable-next-line no-irregular-whitespace
      // r2lblnm.text("　/　");
      var r2lblcar = $('<div>').addClass('car-type').appendTo(r2lbl);
      var tc = '';
      if (data.head.cars) {
        for (var i = 0; i < data.head.cars.length; i++) tc += data.head.cars[i] + ' ';
      }
      r2lblcar.text(tc);

      var r2tm = $('<div>').addClass('time').appendTo(r2pnl);
      r2tm.html('期間：' + this.convDate(data.head.from) + '　～　' + this.convDate(data.head.to) + '<br />' +
        '記録時間：' + this.convTime(data.head.termAll) + '（運転時間：' + this.convTime(data.head.termMove) + '　駐停車時間：' + this.convTime(data.head.termStay) + '）');
      var r2eva = $('<div>').addClass('eva').appendTo(r2pnl);
      $('<div>').text('あなたの運転は　').appendTo(r2eva);
      var r2evaten = $('<div>').addClass('ten').appendTo(r2eva);
      if (data.head.point === -1) {
        r2evaten.addClass(data.head.hDrive.rank).text(' 0点｜ - 判定　　　');
      } else {
        r2evaten.addClass(data.head.hDrive.rank).text(data.head.point + '点｜' + data.head.hDrive.rank + '判定（' + data.head.hDrive.title + '）');
      }
      $('<div>').text('でした').appendTo(r2eva);
      $('<span>').text('　　').appendTo(r2eva);
      var r2evag = $('<div>').addClass('g').appendTo(r2eva);
      r2evag.text('評価基準G：' + data.head.saiten + 'G');
      $('<span>').text('　').appendTo(r2eva);

      if (index === 0) {
        this.createPage1(page, data.head);
      } else {
        this.createPage2(page, data, index);
      }
    };

    // main page
    Report.prototype.createPage1 = function (parent, data) {
      // row3
      var r3 = this.r3 = $('<div>').addClass('row3').appendTo(parent);

      var r3colum1 = $('<div>').addClass('colum1').appendTo(r3);
      var r3pnl = $('<div>').addClass('panel').appendTo(r3colum1);
      var r3tlt = $('<div>').addClass('title').text('判定根拠').appendTo(r3pnl);
      var r3cnt = $('<div>').addClass('contents').appendTo(r3pnl);
      var r3cntgt = $('<div>').addClass('graph-title').text('各方向へかかる加速度分布').appendTo(r3cnt);

      if (data.routes && data.routes.length > 1) {
        var r3cntsub = $('<div>').addClass('graph-sub-title').appendTo(r3cnt);
        var r3cntg1min = $('<div>').addClass('min').text('最低評価').appendTo(r3cntsub);
        var r3cntg1max = $('<div>').addClass('max').text('最高評価').appendTo(r3cntsub);

        var r3cntimage = $('<div>').addClass('graph-image').appendTo(r3cnt);
        var r3cntg1p1 = $('<div>').addClass('image').addClass('p1').appendTo(r3cntimage);
        var r3cntg1p2 = $('<div>').addClass('image').addClass('p2').appendTo(r3cntimage);
        var maxr = data.routes[0];
        var minr = data.routes[0];
        for (var i = 1; i < data.routes.length; i++) {
          if (maxr.point < data.routes[i].point) {
            maxr = data.routes[i];
          }
          if (minr.point > data.routes[i].point) {
            minr = data.routes[i];
          }
        }

        this.set2d(r3cntg1p1, data, minr, 160);
        this.set2d(r3cntg1p2, data, maxr, 160);

        var r3cntg1p3 = $('<div>').addClass('image').addClass('p3').appendTo(r3cntimage);
        this.setTiko(r3cntg1p3, data, 160);
      } else if (data.routes && data.routes.length === 1) {
        var r3cntimage2 = $('<div>').addClass('graph-image').appendTo(r3cnt);
        var r3cntg2p4 = $('<div>').addClass('image2').addClass('p4').appendTo(r3cntimage2);
        this.set2d(r3cntg2p4, data, data.routes[0], 184);
        var r3cntg2p5 = $('<div>').addClass('image2').addClass('p5').appendTo(r3cntimage2);
        this.setTiko(r3cntg2p5, data, 184);
      }

      // コメント・運行情報
      var r3colum2 = $('<div>').addClass('colum2').appendTo(r3);
      var r3pnl2 = $('<div>').addClass('panel').appendTo(r3colum2);
      var r3tlt2 = $('<div>').addClass('title').text('コメント・運行情報').appendTo(r3pnl2);
      var r3cnt2 = $('<div>').addClass('contents').appendTo(r3pnl2);
      var r3cnt2cmt = $('<div>').addClass('comment').appendTo(r3cnt2);
      var r3cnt2line = $('<div>').addClass('line').appendTo(r3cnt2);
      var r3cnt2drv = $('<div>').addClass('drive').appendTo(r3cnt2);

      // コメント
      var cmtext = data.hDrive.comment;
      cmtext = BST.Util.replaceAll(cmtext, '\n', '<br>');
      var r3cnt2cmtlb = $('<div>').addClass('text').html(cmtext).appendTo(r3cnt2cmt);

      // 運行情報
      var r3cnt2drvlb = $('<div>').addClass('d-label').text('最大加速度：').appendTo(r3cnt2drv);
      var r3cnt2drvdiv1 = $('<div>').appendTo(r3cnt2drv);
      var r3cnt2drvdiv1lb = $('<div>').addClass('d-label2').text('アクセル・ブレーキ　').appendTo(r3cnt2drvdiv1);
      var r3cnt2drvdiv1val = $('<div>').addClass('d-value').text(data.maxGY).appendTo(r3cnt2drvdiv1);
      var r3cnt2drvdiv1unit = $('<div>').addClass('d-unit').text('G').appendTo(r3cnt2drvdiv1);
      var r3cnt2drvdiv2 = $('<div>').appendTo(r3cnt2drv);
      var r3cnt2drvdiv2lb = $('<div>').addClass('d-label2').text('ハンドル操作　　　　').appendTo(r3cnt2drvdiv1);
      var r3cnt2drvdiv2val = $('<div>').addClass('d-value').text(data.maxGX).appendTo(r3cnt2drvdiv1);
      var r3cnt2drvdiv2unit = $('<div>').addClass('d-unit').text('G').appendTo(r3cnt2drvdiv1);

      // row4
      var r4 = this.r4 = $('<div>').addClass('row4').appendTo(parent);
      var r4pnl = $('<div>').addClass('panel').appendTo(r4);
      var r4tlt = $('<div>').addClass('title').text('地図と走行時間帯').appendTo(r4pnl);
      var r4cnt = $('<div>').addClass('contents').appendTo(r4pnl);
      var r4cntmap = $('<div>').addClass('map').appendTo(r4cnt);
      var r4cntscale = $('<div>').addClass('scale').appendTo(r4cnt);
      this.createScale(r4cntscale, data);

      // row5
      var r5 = this.r5 = $('<div>').addClass('row5').appendTo(parent);
      var r5pnl = $('<div>').addClass('panel').appendTo(r5);
      var r5tlt = $('<div>').addClass('title').text('走行中の速度グラフ（全運行）').appendTo(r5pnl);
      var r5cnt = $('<div>').addClass('contents').appendTo(r5pnl);
      var r5cntgraph = $('<div>').addClass('graph').appendTo(r5cnt);
      this.setSpeed(r5cntgraph, data);

      // row6
      var r6 = this.r6 = $('<div>').addClass('row6').appendTo(parent);

      // row6 colum1
      var r6col1 = $('<div>').addClass('colum1').appendTo(r6);
      var r6pnl1 = $('<div>').addClass('panel').appendTo(r6col1);
      // var r6tlt1 = $("<div>").addClass("title").text("").appendTo(r6pnl1);
      var r6cnt1 = $('<div>').addClass('contents').appendTo(r6pnl1);
      $('<div>').addClass('label').text('メモ').appendTo(r6cnt1);
      $('<div>').addClass('line').appendTo(r6cnt1);
      var r6cnt1memo = $('<div>').addClass('memo').appendTo(r6cnt1);
      $("<textarea maxlength='160'>").appendTo(r6cnt1memo);

      // row6 colum2
      var r6col2 = $('<div>').addClass('colum2').appendTo(r6);
      var r6pnl2 = $('<div>').addClass('panel').appendTo(r6col2);
      // var r6tlt2 = $("<div>").addClass("title").text("").appendTo(r6pnl2);
      var r6cnt2 = $('<div>').addClass('contents').appendTo(r6pnl2);

      var r6cnt2dv1 = $('<div>').appendTo(r6cnt2);
      $('<div>').addClass('d-label').text('総走行距離：').appendTo(r6cnt2dv1);
      if (data.distm < 1000) {
        $('<div>').addClass('d-value').text(data.distm).appendTo(r6cnt2dv1);
        $('<div>').addClass('d-unit').text('m').appendTo(r6cnt2dv1);
      } else {
        $('<div>').addClass('d-value').text((data.dist % 1) === 0 ? data.dist + '.0' : data.dist).appendTo(r6cnt2dv1);
        $('<div>').addClass('d-unit').text('km').appendTo(r6cnt2dv1);
      }
      var r6cnt2dv2 = $('<div>').appendTo(r6cnt2);
      $('<div>').addClass('d-label').text('平均速度：').appendTo(r6cnt2dv2);
      $('<div>').addClass('d-value').text(data.aveV).appendTo(r6cnt2dv2);
      $('<div>').addClass('d-unit').text('km/h').appendTo(r6cnt2dv2);

      var r6cnt2dv3 = $('<div>').appendTo(r6cnt2);
      $('<div>').addClass('d-label').text('最高速度：').appendTo(r6cnt2dv3);
      $('<div>').addClass('d-value').text(data.maxV).appendTo(r6cnt2dv3);
      $('<div>').addClass('d-unit').text('km/h').appendTo(r6cnt2dv3);

      this.createMap(r4cntmap.get(0), data);
    };

    // 運行のpage
    Report.prototype.createPage2 = function (parent, data, page) {
      // row1
      var r1 = $('<div>').addClass('row4').appendTo(parent);

      var r1pnl = $('<div>').addClass('panel').appendTo(r1);
      var r1tlt = $('<div>').addClass('title').text('走行時間帯').appendTo(r1pnl);
      var r1cnt = $('<div>').addClass('contents').appendTo(r1pnl);
      var r1cntscale = $('<div>').addClass('scale').appendTo(r1cnt);
      this.createScale(r1cntscale, data.head);

      // row2
      var i = (page - 1) * 5;
      var num = page * 5;
      // if (data.list.length < num)
      //    num = data.list.length;

      var first = true;
      for (; i < num; i++) {
        var d = data.list[i];

        // データがない場合はダミー
        if (!d) {
          $('<div>').addClass('row22-dummy').appendTo(parent);
          continue;
        }

        var r2 = $('<div>').addClass('row22').appendTo(parent);
        var r2pnl = $('<div>').addClass('panel').appendTo(r2);
        if (first) {
          $('<div>').addClass('title').text('走行中の速度グラフ（運行毎）').appendTo(r2pnl);
          first = false;
        }
        var r2cnt = $('<div>').addClass('contents').appendTo(r2pnl);
        if (d.invalid === 9) {
          r2cnt.addClass('mask');
          $('<div>').addClass('exclusion').appendTo(r2cnt);
        }
        var item = $('<div>').addClass('item').appendTo(r2cnt);
        // 詳細
        var detail = $('<div>').addClass('detail').appendTo(item);
        $('<div>').addClass('label1').text('評価点').appendTo(detail);
        var l1 = $('<div>').addClass('').appendTo(detail);
        $('<div>').addClass('val').text(d.point).appendTo(l1);
        $('<div>').addClass('unit1').text('点').appendTo(l1);

        $('<div>').addClass('label2').text('平均速度').appendTo(detail);
        var l2 = $('<div>').addClass('').appendTo(detail);
        $('<div>').addClass('val').text(d.aveV).appendTo(l2);
        $('<div>').addClass('unit2').text('km/h').appendTo(l2);

        $('<div>').addClass('label3').text('走行距離').appendTo(detail);
        var l3 = $('<div>').addClass('').appendTo(detail);
        if (d.distm < 1000) {
          $('<div>').addClass('val').text(d.distm).appendTo(l3);
          $('<div>').addClass('unit2').text('m').appendTo(l3);
        } else {
          $('<div>').addClass('val').text((d.dist % 1) === 0 ? d.dist + '.0' : d.dist).appendTo(l3);
          $('<div>').addClass('unit2').text('km').appendTo(l3);
        }
        // 2d
        var i2d = $('<div>').addClass('p1').appendTo(item);
        this.set2d(i2d, d, d, 124);

        // graph
        var ig = $('<div>').addClass('p2').appendTo(item);
        var colIndex = Math.floor(i % this.colors.length);
        // var colIndex = data.list.length - 1 - i;
        // colIndex = Math.floor(colIndex % this.colors.length);//サーバーでしてくださるようなので、マスク。
        this.setSpeed2(ig, d, colIndex);
      }
    };

    Report.prototype.set2d = function (elem, data, rdata, w) {
      /// 2d/{from}/{orgCode}/{driverCode}/{routeCode}/{width}/{height}/{sessCode}
      var date = BST.Util.replaceAll(rdata.dateFrom ? rdata.dateFrom : rdata.from, '/', '-');
      date = date.split(' ')[0];
      var url = BST.Const.imgurl + '2d/' + date + '/' +
        data.cd + '/' + data.dcd + '/' + rdata.rcd + '/' +
        w + '/' + w + '/' + BST.Com.session;
      // elem.css({
      //    "background-image": "url('" + url + "')"
      //    , "background-repeat": "no-repeat"
      // });
      $('<img border="0" src="' + url + '" width="' + w + '" height="' + w + '">').appendTo(elem);
    };

    Report.prototype.setSpeed = function (elem, data) {
      // v1/image/speed/nippo/{from}/{orgCode}/{driverCode}/{sessCode}
      var date = BST.Util.replaceAll(data.from, '/', '-');
      date = date.split(' ')[0];
      var url = BST.Const.imgurl + 'speed/nippo/' + date + '/' +
        data.cd + '/' + data.dcd + '/' +
        BST.Com.session;
      // elem.css({
      //    "background-image": "url('" + url + "')"
      //    , "background-repeat": "no-repeat"
      // });
      // $('<img border="0" src="' + url + '" width="' + 640 + '" height="' + 104 + '">').appendTo(elem);
      $('<img border="0" src="' + url + '">').appendTo(elem);
    };

    Report.prototype.setSpeed2 = function (elem, data, colIndex) {
      colIndex = colIndex || 0;

      // v1/image/su/{from}/{orgCode}/{driverCode}/{routeCode}/{colorIndex}/{sessCode}
      var date = BST.Util.replaceAll(data.from, '/', '-');
      date = date.split(' ')[0];
      var url = BST.Const.imgurl + 'su/' + date + '/' +
        data.cd + '/' + data.dcd + '/' + data.rcd + '/' +
        colIndex + '/' + BST.Com.session;
      // elem.css({
      //    "background-image": "url('" + url + "')"
      //    , "background-repeat": "no-repeat"
      // });
      // $('<img border="0" src="' + url + '" width="' + 420 + '" height="' + 142 + '">').appendTo(elem);
      $('<img border="0" src="' + url + '">').appendTo(elem);
    };

    Report.prototype.setTiko = function (elem, data, w) {
      /// taiko/nippo/{from}/{kijun}/{orgCode}/{driverCode}/{width}/{height}/{sessCode}
      var date = BST.Util.replaceAll(data.from, '/', '-');
      date = date.split(' ')[0];
      var url = BST.Const.imgurl + 'taiko/nippo/' + date + '/' +
        // + data.kijun + "/"
        999 + '/' +
        data.cd + '/' + data.dcd + '/' +
        +w + '/' + w + '/' + BST.Com.session;
      // elem.css({
      //    "background-image": "url('" + url + "')"
      //    , "background-repeat": "no-repeat"
      // });
      $('<img border="0" src="' + url + '" width="' + w + '" height="' + w + '">').appendTo(elem);
    };
    Report.prototype.createMap = function (mapId, data) {
      var lat = 31.939107;
      var lng = 131.430292;
      var zoom = 13;

      var baseLayer = new ol.layer.Tile({
        title: 'base map',
        source: new ol.source.OSM({ url: BST.Const.mapurl + '/{z}/{x}/{y}.png' }),
        preload: 10
      });

      var pointLayer = new ol.layer.Vector({
        renderBuffer: 100,
        source: new ol.source.Vector()
      });

      var lineLayer = new ol.layer.Vector({
        renderBuffer: 600,
        source: new ol.source.Vector()
      });

      var map = new ol.Map({
        renderer: 'canvas',
        // renderer:"dom",
        layers: [
          baseLayer,
          lineLayer,
          pointLayer
        ],
        target: mapId,
        controls: [
          new ol.control.ScaleLine(),
          new ol.control.Zoom(),
          new ol.control.ZoomSlider(),
          // new ol.control.Attribution(),
          new ol.control.Rotate()
        ],
        view: new ol.View({
          center: ol.proj.transform(
            [lng, lat]
            // perHome.center
            , 'EPSG:4326', 'EPSG:3857'),
          zoom: zoom, // C.initZoom,
          // minZoom: T.Rsq.const.AbroadMode ? 1 : 3,
          maxZoom: 18
        }),
        interactions: ol.interaction.defaults({
          pinchRotate: false,
          altShiftDragRotate: false
        })
      });

      // 道路
      if (data.routes) {
        for (var i = 0; i < data.routes.length; i++) {
          var route = data.routes[i];

          if (route.path) {
            var ci = i % 10;
            var color = this.colors[ci];

            var format = new ol.format.Polyline({
              // factor: 1e6
            });

            var line = format.readGeometry(route.path, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857'
            });

            // var test = line.getCoordinates();
            // var iconFeature = this.getStar(test[0]);
            // pointLayer.getSource().addFeature(iconFeature);

            var lineFeature = new ol.Feature({ geometry: line });

            var lineStyle = new ol.style.Style({
              stroke: new ol.style.Stroke({
                color: color,
                width: 4
              }),
              fill: new ol.style.Fill({
                color: color,
                weight: 1
              })
            });

            lineFeature.setStyle(lineStyle);
            lineLayer.getSource().addFeature(lineFeature);
          }

          // ★
          if (route.star) {
            format = new ol.format.Polyline({
              // factor: 1e6
            });
            line = format.readGeometry(route.star, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857'
            });

            var lineArr = line.getCoordinates();
            for (var j = 0; j < lineArr.length; j++) {
              var iconFeature = this.getIcon(lineArr[j], BST.ReportData.ICON_TYPE_STAR);
              pointLayer.getSource().addFeature(iconFeature);
            }
          }
          // バックギア
          if (route.back) {
            format = new ol.format.Polyline({
              // factor: 1e6
            });
            line = format.readGeometry(route.back, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857'
            });

            lineArr = line.getCoordinates();
            for (j = 0; j < lineArr.length; j++) {
              iconFeature = this.getIcon(lineArr[j], BST.ReportData.ICON_TYPE_BACK_GEAR);
              pointLayer.getSource().addFeature(iconFeature);
            }
          }
        }
      }

      // ★
      // if (data.star) {
      //    var format = new ol.format.Polyline({
      //        //factor: 1e6
      //    });
      //    var line = format.readGeometry(data.star, {
      //        dataProjection: 'EPSG:4326',
      //        featureProjection: 'EPSG:3857'
      //    });

      //    var lineArr = line.getCoordinates();
      //    for (var i = 0; i < lineArr.length; i++) {
      //        var iconFeature = this.getStar(lineArr[i]);
      //        pointLayer.getSource().addFeature(iconFeature);
      //    }
      // }

      // 全部が収まる感じに表示する
      var extent = lineLayer.getSource().getExtent();
      map.getView().fitExtent(extent, map.getSize());

      // var iconFeature = new ol.Feature({
      //    geometry: new ol.geom.Point(ol.proj.transform([lng, lat], 'EPSG:4326',
      //    'EPSG:3857'))
      // });

      // var iconStyle = new ol.style.Style({
      //    image: new ol.style.Icon({
      //        src: "../img/exp.gif",
      //        anchor: [5, 5],
      //        anchorXUnits: 'pixels',
      //        anchorYUnits: 'pixels',
      //    }),
      //    //zIndex: zi2,
      // })
    };

    Report.prototype.getIcon = function (d, type) {
      var iconFeature = new ol.Feature({
        geometry: new ol.geom.Point(d)
      });

      var iconStyle = new ol.style.Style({
        image: new ol.style.Icon({
          src: BST.ReportData.ICONS[type].src,
          anchor: BST.ReportData.ICONS[type].anchor,
          anchorXUnits: 'pixels',
          anchorYUnits: 'pixels'
        })
        // zIndex: zi2,
      });
      iconFeature.setStyle(iconStyle);

      return iconFeature;
    };

    Report.prototype.createScale = function (parent, data) {
      var max = Math.ceil(data.termTo);
      if (max < 24) max = 24;
      var min = 0;

      var bar = $('<div>').addClass('bar').appendTo(parent);
      var w = bar.width();
      var w1 = w / max;

      var line = $('<div>').addClass('line').appendTo(bar);

      var len = Math.floor(max / 6) + 1;
      for (var i = 0; i < len; i++) {
        var left = w1 * i * 6;
        if (i === (len - 1)) {
          var time = $('<div>').addClass('time').css({ left: (left - 15) + 'px' }).appendTo(bar);
          $('<div>').text(i * 6 + '時').appendTo(time);
        } else {
          time = $('<div>').addClass('time').css({ left: left + 'px' }).appendTo(bar);
          $('<div>').text(i * 6).appendTo(time);
        }
      }

      if (data.routes) {
        for (i = 0; i < data.routes.length; i++) {
          var ci = i % this.colors.length;
          var color = this.colors[ci];

          var from = data.routes[i].termFrom;
          var to = data.routes[i].termTo;

          var item = $('<div>').addClass('item')
            .css({
              left: w1 * from + 'px',
              width: w1 * (to - from) + 'px',
              //, right: w1 * to + "px"
              'background-color': color,
              //, "border": "8px solid " + color
              border: '1px solid ' + color // 最低の太さを2pxに変更
            }).appendTo(bar);
        }
      }
    };

    Report.prototype.clear = function () {
      this.clearPage();
      this.clearFooter();
      if (this.base) this.base.remove();
      this.base = null;
    };

    Report.prototype.clearFooter = function () {
      if (this._footer) {
        this._footer.remove();
        this._footer = null;
      }
    };

    Report.prototype.clearPage = function () {
      this._pageIndex = 0;
      this._pageNum = 0;

      this.clearMap();

      for (var i = 0; i < this._pages.length; i++) {
        this._pages[i].remove();
      }

      this._pages = [];

      if (this.page) {
        this.page.remove();
        this.page = null;
      }

      // if (this.r2) this.r2.remove();
      // this.r2 = null;
      // if (this.r3) this.r3.remove();
      // this.r3 = null;
      // if (this.r4) this.r4.remove();
      // this.r4 = null;
      // if (this.r5) this.r5.remove();
      // this.r5 = null;
      // if (this.r6) this.r6.remove();
      // this.r6 = null;
      // if (this.r7) this.r7.remove();
      // this.r7 = null;
    };

    // Report.prototype.clearBody = function () {
    //    this.clearMap();

    //    if (this.r2) this.r2.remove();
    //    this.r2 = null;
    //    if (this.r3) this.r3.remove();
    //    this.r3 = null;
    //    if (this.r4) this.r4.remove();
    //    this.r4 = null;
    //    if (this.r5) this.r5.remove();
    //    this.r5 = null;
    //    if (this.r6) this.r6.remove();
    //    this.r6 = null;
    //    if (this.r7) this.r7.remove();
    //    this.r7 = null;
    // }

    Report.prototype.clearMap = function () {

    };

    Report.prototype.convTime = function (time) {
      if (!time) return '-時-分-秒';
      var d = new Date('1900/1/1 ' + time);
      return BST.Util.dateFormat(d, 'hh時mm分ss秒');
    };

    Report.prototype.convDate = function (date) {
      if (!date) return '-年-月-日 -時-分';
      var d = new Date(date);
      return BST.Util.dateFormat(d, 'yyyy年MM月dd日 hh時mm分');
    };

    return Report;
  })();
})(BST || (BST = {}));
