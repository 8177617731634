var BST;
(function (BST) {
  var SettingDialogUserEdit = BST.SettingDialogUserEdit = (function () {
    function SettingDialogUserEdit () {
    }

    SettingDialogUserEdit.prototype.create = function () {
      var _self = this;

      this.bg = $('<div>').addClass('bst-setting-dialog-user-edit-bg').appendTo('body').hide();
      var base = this.base = $('<div>').addClass('bst-setting-dialog-user-edit').appendTo('body').hide();
      var panel = this.panel = $('<div>').addClass('panel').appendTo(base);
      this.title = $('<div>').addClass('title').text('タイトル').appendTo(panel);
      $('<div>').addClass('ms').text('※*は入力必須項目です。').appendTo(panel);

      var row1 = $('<div>').addClass('row').appendTo(panel);
      $('<span>').addClass('label').text('ログインID').appendTo(row1);
      $('<span>').addClass('mark').text('*').appendTo(row1);
      this.input1 = $('<input>').addClass('ip1').attr('maxlength', 30).appendTo(row1)
        .on('change', function () {
          _self.change();
        });
      $('<span>').addClass('label2').text('(半角英数)').appendTo(row1);

      var row2 = $('<div>').addClass('row').appendTo(panel);
      $('<span>').addClass('label').text('ユーザー名').appendTo(row2);
      $('<span>').addClass('mark').text('*').appendTo(row2);
      this.input3 = $('<input>').addClass('ip3').attr('maxlength', 100).appendTo(row2)
        .on('change', function () {
          _self.change();
        });

      this.btn3 = $('<button>').addClass('btn-cre-pass').text('パスワード再発行').appendTo(panel);
      this.btn3.on('click', function () {
        if (_self._callback3) {
          _self._callback3(_self._data);
        }
      });

      var row3 = $('<div>').addClass('row').addClass('row2').appendTo(panel);
      this.rbtn1 = $("<input type='radio' name='bst-radio-setting-dialog-user-edit'>")
        .val(0).attr('checked', true).appendTo(row3);
      this.rbtn1.on('change', function () {
        if ($(this).prop('checked') === true) {
          _self.updateCover();
          _self.change();
        }
      });
      $('<div>').addClass('label').text('閲覧者ユーザーとして作成する').appendTo(row3);
      this.comment = $('<div>').addClass('label7').text('(変更は再ログイン時に反映されます。)').appendTo(row3);

      var waku1 = $('<div>').addClass('waku').appendTo(panel);
      $('<div>').addClass('mess')
        .html('※閲覧者ユーザーとして<br>　作成する場合は<br>　閲覧可能な拠点、<br>　権限付与のいずれかに<br>　必ずチェックを<br>　入れて下さい。')
        .appendTo(waku1);

      // var row4 = $("<div>").addClass("row").appendTo(waku1);

      var row5 = $('<div>').addClass('row').appendTo(waku1);
      $('<span>').addClass('label').text('閲覧可能な拠点').appendTo(row5);
      this.ck1 = $('<input type="checkbox">').addClass('ck-box').appendTo(row5)
        .on('change', function () {
          _self.updateCheckBox();
          _self.change();
        });
      $('<span>').addClass('label4').text('すべてにチェック').appendTo(row5);

      this.list = $('<div>').addClass('list').appendTo(waku1);

      var row6 = $('<div>').addClass('row').appendTo(waku1);
      $('<span>').addClass('label').text('権限付与').appendTo(row6);
      this.ck2 = $('<input type="checkbox">').addClass('ck-box').appendTo(row6)
        .on('change', function () {
          _self.change();
        });
      $('<span>').addClass('label5').text('管理者（設定を行うことができる権限です）').appendTo(row6);

      // var row7 = $("<div>").addClass("row1").appendTo(waku1);
      // this.ck3 = $('<input type="checkbox">').addClass("ck-box").appendTo(row7)
      // .on("change", function () {
      //    _self.change();
      // });
      // $("<span>").addClass("label6").text("ビューアからのデータアップロード").appendTo(row7);

      this.cover1 = $('<div>').addClass('waku-cover').appendTo(waku1);

      var row8 = $('<div>').addClass('row').addClass('row2').appendTo(panel);
      this.rbtn2 = $("<input type='radio' name='bst-radio-setting-dialog-user-edit'>")
        .val(1).appendTo(row8);
      this.rbtn2.on('change', function () {
        if ($(this).prop('checked') === true) {
          _self.updateCover();
          _self.change();
        }
      });
      $('<div>').addClass('label').text('ドライバーユーザーとして作成する').appendTo(row8);

      var waku2 = $('<div>').addClass('waku').appendTo(panel);

      var row9 = $('<div>').addClass('row').appendTo(waku2);
      $('<span>').addClass('label').text('所属拠点').appendTo(row9);
      $('<span>').addClass('mark').text('*').appendTo(row9).css({ visibility: 'hidden' });
      this.select1 = $('<select>').addClass('pl1').appendTo(row9)
        .on('change', function () {
          _self.filterDriver();
          _self.change();
        });

      var row10 = $('<div>').addClass('row').appendTo(waku2);
      $('<span>').addClass('label').text('ドライバー名').appendTo(row10);
      $('<span>').addClass('mark').text('*').appendTo(row10);
      this.select2 = $('<select>').addClass('pl2').appendTo(row10)
        .on('change', function () {
          _self.change();
        });

      // var row11 = $("<div>").addClass("row1").appendTo(waku2);
      // this.ck4 = $('<input type="checkbox">').addClass("ck-box").appendTo(row11)
      // .on("change", function () {
      //    _self.change();
      // });
      // $("<span>").addClass("label6").text("ビューアからのデータアップロード").appendTo(row11);

      this.cover2 = $('<div>').addClass('waku-cover').appendTo(waku2);

      //
      var row12 = $('<div>').addClass('row').addClass('row2').appendTo(panel);
      this.rbtn3 = $("<input type='radio' name='bst-radio-setting-dialog-user-edit'>")
        .val(2).appendTo(row12);
      this.rbtn3.on('change', function () {
        if ($(this).prop('checked') === true) {
          _self.updateCover();
          _self.change();
        }
      });
      $('<div>').addClass('label').text('データアップロード用ユーザーとして作成する').appendTo(row12);

      var waku3 = $('<div>').addClass('waku').appendTo(panel);

      var row13 = $('<div>').addClass('row2').appendTo(waku3);
      $('<div>').addClass('text').html('Drive Correctorの閲覧権限を持たない、<br>ビューアからのデータアップロード作業専用のユーザーとして作成します。').appendTo(row13);

      this.cover3 = $('<div>').addClass('waku-cover').appendTo(waku3);

      //
      var row14 = $('<div>').addClass('btns').appendTo(panel);
      var btn1 = this.btn1 = $('<button>').addClass('ok').text('OK').appendTo(row14);
      var btn2 = $('<button>').addClass('cancel').text('キャンセル').appendTo(row14);

      btn1.on('click', function () {
        if (_self.check(_self._data) === false) return;

        try {
          if (_self._callback1) {
            _self._callback1(_self._data);
          }
        } catch (e) {
          console.log(e);
        }
      });
      btn2.on('click', function () {
        try {
          if (_self._callback2) {
            _self._callback2();
          }
        } catch (e) {
          console.log(e);
        }
      });

      var disable = this.disable = $('<div>').addClass('disable').appendTo(panel);

      this.updateCover();
    };

    SettingDialogUserEdit.prototype.setData = function (sl1, sl2, sl3, data) {
      var _self = this;

      this._data = data;
      this._sl1 = sl1;
      this._sl2 = sl2;
      this._sl3 = sl3;

      if (data.cd === 'admin') this.disable.show();
      else this.disable.hide();

      var view = this.base;
      if (view) {
        // ID
        this.input1.val(data.cd);

        // ドライバーにひもづくユーザ
        // for (var i = 0; i < sl2.length; i++){
        //    if (data.roleDriver == sl2[i].cd) {
        //        this.input2.text(sl2[i].nm);
        //        break;
        //    }
        // }

        // ドライバーの拠点
        var ableOrg = {};

        var drv = {};
        // ドライバー名
        this.select2.empty();
        for (var i = 0; i < sl2.length; i++) {
          var o = $('<option>').text(sl2[i].nm).data('data', sl2[i]).appendTo(this.select2);
          if (sl2[i].cd === data.roleDriver) {
            // o.prop("selected", "selected");
            this.select2.get(0).options[i].selected = true;

            drv = sl2[i];
          }

          ableOrg[sl2[i].ocd] = true;
        }

        // 所属拠点
        // this.select1.empty();
        // this.list.empty();
        // this.chks = [];
        // var optionIndex = 0; // 追加されない要素があるので
        // for (var i = 0; i < sl1.length; i++) {

        //    // list
        //    var item = $("<div>").addClass("item").appendTo(this.list);
        //    var chk = $('<input type="checkbox">').addClass("ck-box").data("data", sl1[i]).appendTo(item);
        //    this.chks.push(chk);
        //    chk.on("change", function () {
        //        //var org = $(this).data("data");
        //        //_self.data.ck.push(sl1.cd);
        //        _self.change();
        //     })
        //    $("<span>").addClass("name").text(sl1[i].nm).appendTo(item);

        //    chk.prop("checked", false);
        //    for (var j = 0; j < data.roleCloud.length; j++) {
        //        if (data.roleCloud[j] == sl1[i].cd) {
        //            chk.prop("checked", true);
        //            break;
        //        }
        //    }

        //    // select
        //    if (!ableOrg[sl1[i].cd]) continue; // その拠点のドライバーはいないよ
        //    var o = $("<option>").text(sl1[i].nm).data("data", sl1[i]).appendTo(this.select1);

        //    if (sl1[i].cd == drv.ocd)
        //        //o.prop("selected", "selected");
        //        this.select1.get(0).options[optionIndex].selected = true;
        //    optionIndex++;

        // }
        // 所属拠点
        this.list.empty();
        this.chks = [];
        for (i = 0; i < sl3.length; i++) {
          // list
          var item = $('<div>').addClass('item').appendTo(this.list);
          var chk = $('<input type="checkbox">').addClass('ck-box').data('data', sl3[i]).appendTo(item);
          this.chks.push(chk);
          chk.on('change', function () {
            // var org = $(this).data("data");
            // _self.data.ck.push(sl1.cd);
            _self.change();
          });
          $('<span>').addClass('name').text(sl3[i].nm).appendTo(item);

          chk.prop('checked', false);
          for (var j = 0; j < data.roleCloud.length; j++) {
            if (data.roleCloud[j] === sl3[i].cd) {
              chk.prop('checked', true);
              break;
            }
          }
        }

        this.select1.empty();
        var optionIndex = 0; // 追加されない要素があるので
        for (i = 0; i < sl1.length; i++) {
          // select
          if (!ableOrg[sl1[i].cd]) continue; // その拠点のドライバーはいないよ
          o = $('<option>').text(sl1[i].nm).data('data', sl1[i]).appendTo(this.select1);

          if (sl1[i].cd === drv.ocd) {
            // o.prop("selected", "selected");
            this.select1.get(0).options[optionIndex].selected = true;
          }
          optionIndex++;
        }

        /// / ドライバー名
        // this.select2.empty();
        // for (var i = 0; i < sl2.length; i++) {
        //    var o = $("<option>").text(sl2[i].nm).data("data", sl2[i]).appendTo(this.select2);
        //    if (sl2[i].cd == data.roleDriver)
        //        //o.prop("selected", "selected");
        //        this.select2.get(0).options[i].selected = true;
        // }

        // ユーザー名
        this.input3.val(data.nm);

        // 管理者
        if (data.roleAdmin === 1) {
          this.ck2.prop('checked', true);
        } else {
          this.ck2.prop('checked', false);
        }

        // ビューアーからアップロード
        // if (data.roleView == 1)
        //    this.ck3.prop("checked", true);
        // else
        //    this.ck3.prop("checked", false);

        if (this.kengen(data) === 0) {
          this.rbtn1.prop('checked', true);
        } else if (this.kengen(data) === 1) {
          this.rbtn2.prop('checked', true);
        } else if (this.kengen(data) === 2) {
          this.rbtn3.prop('checked', true);
        } else {
          this.rbtn1.prop('checked', true);
        }

        this.updateCover();
      }
    };

    SettingDialogUserEdit.prototype.change = function () {
      this._data.cd = this.input1.val();
      this._data.nm = this.input3.val();

      if (this.rbtn1.prop('checked')) {
        this._data.roleDriver = '';
        this._data.roleCloud = [];
        for (var i = 0; i < this.chks.length; i++) {
          if (this.chks[i].prop('checked')) {
            var d = this.chks[i].data('data');
            this._data.roleCloud.push(d.cd);
          }
        }
        this._data.roleAdmin = this.ck2.prop('checked') ? 1 : 0;
        // this._data.roleView = this.ck3.prop("checked") ? 1 : 0;
        this._data.roleView = 1; // 全員アップロード権限をつける

        // かいらん権限（ユーザータイプ）
        // this._data._ut = 0;
      } else if (this.rbtn2.prop('checked')) {
        this._data.roleDriver = this.select2.find(':selected').data('data').cd;
        this._data.roleCloud = [];
        this._data.roleAdmin = 0;
        // this._data.roleView = this.ck4.prop("checked") ? 1 : 0;
        this._data.roleView = 1; // 全員アップロード権限をつける

        // ドライバー権限（ユーザータイプ）
        // this._data._ut = 1;
      } else {
        this._data.roleDriver = '';
        this._data.roleCloud = [];
        this._data.roleAdmin = 0;

        this._data.roleView = 1; // 全員アップロード権限をつける

        // アップロード権限（ユーザータイプ）
        // this._data._ut = 2;
      }
    };

    SettingDialogUserEdit.prototype.check = function (data) {
      var err = '';

      if (BST.Util.isBlank(data.cd)) {
        // 空白
        err = this.addText(err, '※ログインIDを入力して下さい。');
      } else if (!BST.Util.isHanEisu(data.cd)) {
        // 半角英数
        err = this.addText(err, '※ログインIDは半角英数で入力して下さい。');
      }

      if (BST.Util.isBlank(data.nm)) {
        err = this.addText(err, '※ユーザー名を入力して下さい。');
      }

      if (this.rbtn1.prop('checked')) {
        if (data.roleCloud.length === 0 && data.roleAdmin !== 1) {
          err = this.addText(err, '※閲覧者ユーザーとして作成する<br>　場合は閲覧可能な拠点、権限付与<br>　のいずれかにチェックを入れてくだ<br>　さい。');
        }
      } else if (this.rbtn2.prop('checked')) {
        if (data.roleDriver === '' || data.roleDriver === undefined || data.roleDriver === null) {
          err = this.addText(err, 'ドライバー名を選択して下さい。');
        }
      } else {
        // eslint-disable-next-line no-empty
      }

      if (err === '') return true;

      BST.Com.dialogLErr(err);
      return false;
    };

    SettingDialogUserEdit.prototype.type = function (type) {
      if (!this.base) this.create();

      if (type === 1) {
        // new
        this.title.text('新規登録');
        this.btn1.text('登録');
        this.btn3.hide();
        this.panel.css('height', '632px');
      } else if (type === 2) {
        // edit
        this.title.text('編集');
        this.btn1.text('OK');
        this.btn3.show();
        this.panel.css('height', '664px');
      }
    };
    SettingDialogUserEdit.prototype.open = function (orgs, drvs, orgAdms, data, callback1, callback2, type, callback3) {
      if (!this.base) this.create();

      if (data.cd === '') this.comment.hide();
      else this.comment.show();

      // this.rbtn1.prop("checked", true);
      // this.updateCover();

      this.type(type);

      this._callback1 = callback1;
      this._callback2 = callback2;
      this._callback3 = callback3;

      this.setData(orgs, drvs, orgAdms, data);

      // filter
      this.filterDriver();
      // check box
      // this.updateCheckBox();
      // 更新
      this.change();

      this.disp(true);
    };

    SettingDialogUserEdit.prototype.close = function (type) {
      var view = this.base;
      if (view) {
        this._callback1 = null;
        this._callback2 = null;
        this._callback3 = null;
        this._data = null;
      }

      this.ck1.prop('checked', false);
      this.ck2.prop('checked', false);
      // this.ck3.prop("checked", false);
      // this.ck4.prop("checked", false);

      this.disp(false);
    };

    SettingDialogUserEdit.prototype.disp = function (b) {
      if (!this.base) {
        this.create();
      }
      if (b) {
        this.bg.show();
        this.base.show();
      } else {
        this.bg.hide();
        this.base.hide();
      }
    };

    SettingDialogUserEdit.prototype.filterDriver = function () {
      var _self = this;
      try {
        // ドライバーを絞り込む
        var sd1 = this.select1.find(':selected').data('data');
        var ocd1 = sd1.cd;
        var sd2 = this.select2.find(':selected').data('data') || {};
        var ocd2 = sd2.ocd;
        var dcd2 = sd2.cd;

        // var index=null;

        // var dispFirst;
        // this.select2.find("option").each(function (i) {
        //    var s2d = $(this).data("data");
        //    // 同じ拠点。何も選択しないは表示
        //    //if (s2d.ocd == ocd1 || s2d.ocd == "") $(this).show();
        //    // s2d.cd == "" は選択しないってデータ
        //    if (s2d.ocd == ocd1 || s2d.cd == "") {
        //        $(this).show();
        //        if (!dispFirst) dispFirst = $(this);
        //    }
        //    else $(this).hide();
        // });

        var dispFirst;

        this.select2.empty();
        for (var i = 0; i < this._sl2.length; i++) {
          if (this._sl2[i].ocd === ocd1 || this._sl2[i].cd === '') {
            var o = $('<option>').text(this._sl2[i].nm).data('data', this._sl2[i]).appendTo(this.select2);
            // filterがよばれたけど、選択している車がある場合は選択状態へ
            if (this._sl2[i].cd === dcd2) {
              o.get(0).selected = true;
            }

            if (!dispFirst) dispFirst = o;
          }
        }

        if (ocd1 !== ocd2) {
          if (dispFirst) dispFirst.get(0).selected = true;
        }
        // this.select2.get(0).options[0].selected = true;
        // this.select2.get(0).options[index].selected = true;
      } catch (e) {
        console.log(e);
      }
    };

    SettingDialogUserEdit.prototype.updateCheckBox = function () {
      if (this.ck1.prop('checked')) {
        for (var i = 0; i < this.chks.length; i++) {
          this.chks[i].prop('checked', true);
        }
      } else {
        for (i = 0; i < this.chks.length; i++) {
          this.chks[i].prop('checked', false);
        }
      }
    };

    SettingDialogUserEdit.prototype.updateCover = function () {
      if (this.rbtn1.prop('checked')) {
        this.cover1.hide();
        this.cover2.show();
        this.cover3.show();
      } else if (this.rbtn2.prop('checked')) {
        this.cover1.show();
        this.cover2.hide();
        this.cover3.show();
      } else {
        this.cover1.show();
        this.cover2.show();
        this.cover3.hide();
      }
    };

    SettingDialogUserEdit.prototype.addText = function (str, add) {
      if (str !== '') str += '<br>';

      str += add;

      return str;
    };

    SettingDialogUserEdit.prototype.kengen = function (data) {
      if (data.roleCloud.length > 0 || data.roleAdmin === 1) {
        return 0;
      } else if (data.roleDriver !== '' && data.roleDriver !== undefined && data.roleDriver !== null) {
        return 1;
      } else if (data.roleView === 1) {
        return 2;
      }

      return -1;
    };

    return SettingDialogUserEdit;
  })();
})(BST || (BST = {}));
