var BST;
(function (BST) {
  var TreeData = BST.TreeData = (function () {
    function TreeData () {
      this._data = null;
      // this._dataName = {
      //    label: "label"
      //    , nodes: "nodes"
      // };
    }

    TreeData.prototype.data = function (d) {
      if (d) {
        this._data = d;
      }
      return this._data;
    };

    // TreeData.prototype.setDataName = function (fmt) {
    //    this._dataName = fmt;
    // }

    TreeData.prototype.updateLatest = function (callback) {
      // test
      callback({
        nodes: [
          {
            label: 'Node1',
            nodes: [
              {
                label: 'Node1-1'
              },
              {
                label: 'Node1-2',
                nodes: [
                  { label: 'Node1-2-1' }
                ]
              }
            ]
          },
          {
            label: 'Node2'
          }
        ]
      });
      return;

      var url = '';
      var req = {};
      var _self = this;

      $.ajax({
        type: 'POST',
        datatype: 'json',
        data: req,
        url: url,
        contentType: 'application/json; charset=utf-8'
      }).then(function (data) {
        _self._data = data;
      }).fail(function (err) {
        _self._data = null;
        console.log('tree data error:' + err);
      }).always(function () {
        if (callback) {
          callback(_self._data);
        }
      });
    };

    return TreeData;
  })();
})(BST || (BST = {}));
