var BST;
(function (BST) {
  var WinLink = BST.WinLink = (function () {
    WinLink.TYPE_DRIVE_LIST = 'drive-list';
    WinLink.TYPE_RANK = 'rank';

    WinLink.DATA_INFO = {};
    WinLink.DATA_INFO[WinLink.TYPE_DRIVE_LIST] = {
      url: 'print/print-drive-list.html'
    };
    WinLink.DATA_INFO[WinLink.TYPE_RANK] = {
      url: 'print/print-rank.html'
    };

    // 使用か？
    // WinLink.is = function (callback) {
    //    $.ajax({
    //        url: "./al/use-mo.text",
    //        type: 'GET'
    //        //contentType: "application/json; charset=utf-8",
    //        //dataType: 'json'
    //    })
    //    .done(function (data, status, xhr) {
    //        callback(true);
    //    })
    //    .fail(function (xhr, status, error) {
    //        callback(false);
    //        BST.log("*** Query.getValue ----");
    //        BST.log("xhr.status = " + xhr.status);          // 例: 404
    //        BST.log("xhr.statusText = " + xhr.statusText);  // 例: Not Found
    //        BST.log("status = " + status);                  // 例: error
    //        BST.log("error = " + error);                    // 例: Not Found
    //        BST.log("---- Query.getValue ***");
    //    });
    // }

    WinLink.getParam = function (type) {
      return WinLink.data[type];
    };

    WinLink.data = {}; // データ
    WinLink.win = {}; // ウィンドウ

    // リンクを開く
    // WinLink._openTimeId;
    WinLink.open = function (type, data) {
      var info = WinLink.DATA_INFO[type];

      WinLink.data[type] = data;
      WinLink.win[type] = window.open(info.url, type);// ウィンドウオープン

      // if (!WinLink.sub || WinLink.sub.closed) {
      //    WinLink.sub = window.open(url, "amatellus-mo");// ウィンドウオープン
      // }
      // else
      //    WinLink.sub.focus(); // サブウィンドウを最前面に出す
    };

    // WinLink.dispButton = function () {
    //    //$(".common-button-thunder").css({"visibility":"visible"});
    //    $(".common-button-mo").css({ "display": "block" });
    // }

    function WinLink () {
    }

    return WinLink;
  })();
})(BST || (BST = {}));
