var BST;
(function (BST) {
  var SettingOrg = BST.SettingOrg = (function () {
    // var SEXS = [
    //    {cd: "-1", nm: "すべて"}
    //    ,{ cd: 1, nm: "男" }
    //    , { cd: 2, nm: "女" }
    // ];

    function SettingOrg (parent, options) {
      var _self = this;

      this.labels = ['nm', 'nm2', 'nm3', 'nm4'];

      parent = parent || 'body';
      var opt = this.opt = options || {};
      this._callbackCreate = opt.callbackCreate;
      this._callbackUpdate = opt.callbackUpdate;
      this._callbackDelete = opt.callbackDelete;

      this.create(parent);
      this.createTable([]);
    }

    SettingOrg.prototype.setData = function (datas) {
      this._datas = datas || [];
    };

    SettingOrg.prototype.create = function (parent) {
      var _self = this;

      var base = this.base = $('<div>').addClass('setting-org').appendTo(parent);

      var header = $('<div>').addClass('header').appendTo(base);

      var row1 = $('<div>').addClass('row').appendTo(header);

      this.btnNew = $('<span>').addClass('btn-new').text('新規作成').appendTo(row1);
      if (BST.Com.isAdminU()) {
        this.btnNew.on('click', function () {
          if (_self._callbackCreate) _self._callbackCreate();
        });
      } else {
        this.btnNew.css({ visibility: 'hidden' });
      } // 位置がずれるので、微妙な調整

      $('<span>').addClass('label').html('拠点設定').appendTo(row1);

      var row2 = $('<div class="row"> ').appendTo(header);
      $("<input type='password' name='dummypass' style='visibility: hidden; position: absolute;' />").addClass('search').appendTo(row2);
      this.search = $('<input>').addClass('search').attr('autocomplete', 'off').attr('placeholder', 'フリーワード検索').appendTo(row2);
    };

    // テーブル作成
    SettingOrg.prototype.createTable = function (orgs) {
      var _self = this;

      // テーブル
      if (!this.table) {
        this.table = $('<table>').addClass('table').appendTo(this.base);
      } else {
        this.table.empty();
      }
      var table = this.table;
      var header = $('<thead>').addClass('scrollHead').appendTo(table);
      var tr = $('<tr> ').appendTo(header);
      $('<td>').addClass('col0').addClass('label0').text('').appendTo(tr);
      $('<td>').addClass('col1').addClass('label').text('拠点名').appendTo(tr);
      $('<td>').addClass('col2').addClass('label').text('車両数').appendTo(tr);
      $('<td>').addClass('col3').addClass('label').text('ドライバー数').appendTo(tr);
      if (BST.Com.isAdminU()) {
        $('<td>').addClass('col4').addClass('label').text('').appendTo(tr);
      }

      var body = $('<tbody>').addClass('scrollBody').appendTo(table);
      // 1行分
      for (var i = 0; i < orgs.length; i++) {
        var d = orgs[i];

        tr = $('<tr class="tr"> ').data('data', d).appendTo(_self.table);
        $('<td>').addClass('col0').addClass('item').addClass('item0').text(i + 1).appendTo(tr);
        var nm = '';
        for (var j = 0; j < this.labels.length; j++) {
          var _nm = d[this.labels[j]];

          if (_nm && _nm !== '') {
            if (nm !== '') {
              nm += '-';
            }
            nm += _nm;
          }
        }
        $('<td>').addClass('col1').addClass('item').text(nm).appendTo(tr);
        $('<td>').addClass('col2').addClass('item').text(d.cNum).appendTo(tr);
        $('<td>').addClass('col3').addClass('item').text(d.dNum).appendTo(tr);
        if (BST.Com.isAdminU()) {
          var btns = $('<td>').addClass('col4').addClass('item').appendTo(tr);
          $('<span>').addClass('btn-edit').text('編集').appendTo(btns)
            .on('click', function () {
              var _d = $(this).parent().parent().data('data');
              if (_self._callbackUpdate) {
                _self._callbackUpdate(_d);
              }
            });
          var btnDel = $('<span>').addClass('btn-delete').text('削除').appendTo(btns)
            .on('click', function () {
              var _d = $(this).parent().parent().data('data');
              // if (_d.dNum != 0) return;
              if (_self._callbackDelete) {
                _self._callbackDelete(_d);
              }
            });
        }
        // if (d.dNum != 0) btnDel.addClass("disable");
      }// end of for
      if (orgs.length > 0) {
        this.table.exTableFilter(this.search
          , {
            onFilteringEnd: function (api) {
              _self.resetNumber();
            }
          }
        );
      }
      this.search.trigger('change');
    };

    SettingOrg.prototype.update = function (orgs) {
      this.setData(orgs);
      this.createTable(orgs);
    };

    SettingOrg.prototype.resetNumber = function () {
      var no = 1;

      this.table.find('.scrollBody').find('.tr').each(function (i, elem) {
        var $tr = $(elem);

        // console.debug($tr.css('display'));
        if ($tr.css('display') !== 'none') {
          var $no = $tr.find('.col0');
          $no.text(no);
          no++;
        }
      });
    };

    SettingOrg.prototype.disp = function (b) {
      if (b) {
        this.base.show();
      } else {
        this.base.hide();
      }
    };

    return SettingOrg;
  })();
})(BST || (BST = {}));
