var BST;
(function (BST) {
  var VDialog = BST.VDialog = (function () {
    VDialog.TYPE_OK = 1;
    VDialog.TYPE_YESNO = 2;

    VDialog.TYPE_COL_ERR = 'text-color-error';
    VDialog.TYPE_COL_BLUE = 'text-color-blue';

    VDialog.SIZE_M = 'text-w-m';

    // text1,text2
    // type
    function VDialog (opt) {
      this._opt = opt || {};
    }

    VDialog.prototype.create = function () {
      var _self = this;
      this.bg = $('<div>').addClass('bst-vdialog-bg').appendTo('body').hide();
      this.base = $('<div>').addClass('bst-vdialog').appendTo('body').hide();
      this.panel = $('<div>').addClass('panel').appendTo(this.base);
      this.text = $('<div>').addClass('text').appendTo(this.panel);

      var type = this._opt.type || VDialog.TYPE_OK;

      var btns = $('<div>').addClass('btns').appendTo(this.panel);

      if (type === VDialog.TYPE_OK) {
        this.btn1 = $('<div>').addClass('btn1').text(this._opt.text1 || 'OK').appendTo(btns);
        this.btn1.on('click', function () {
          try {
            if (_self.callback1) {
              _self.callback1(true);
            }
          } catch (e) { }

          _self._disp(false);
          _self._text('');
          _self.callback1 = null;
        });
      } else if (type === VDialog.TYPE_YESNO) {
        this.btn1 = $('<div>').addClass('btn1').text(this._opt.text1 || 'OK').appendTo(btns);
        this.btn1.on('click', function () {
          try {
            if (_self.callback1) {
              _self.callback1(true);
            }
          } catch (e) { }
        });

        this.btn2 = $('<div>').addClass('btn2').text(this._opt.text2 || 'キャンセル').appendTo(btns);
        this.btn2.on('click', function () {
          try {
            if (_self.callback2) {
              _self.callback2(true);
            }
          } catch (e) { }
        });
      }
    };

    VDialog.prototype.open = function (text, opt) {
      this._text(text);
      this._disp(true);

      opt = opt || {};
      this.callback1 = opt.callback1;
      this.callback2 = opt.callback2;

      // this.textClear();
      this.text.removeClass();
      this.text.addClass('text');
      this.panel.removeClass();
      this.panel.addClass('panel');

      if (opt.textSelect) this.textSelect();
      if (opt.textLeft) this.textLeft();
      if (opt.textCenter) this.textCenter();
      if (opt.textColor) this.textColor(opt.textColor);
      if (opt.btn1Text) this.btn1Text(opt.btn1Text);
      if (opt.btn2Text) this.btn2Text(opt.btn2Text);
      if (opt.size) this.size(opt.size);
    };

    VDialog.prototype.close = function () {
      this.callback1 = null;
      this.callback2 = null;
      this._text('');
      this._disp(false);
    };

    VDialog.prototype._disp = function (b) {
      if (!this.base) {
        this.create();
      }
      if (b) {
        this.bg.show();
        this.base.show();
      } else {
        this.bg.hide();
        this.base.hide();
      }
    };

    VDialog.prototype._text = function (text) {
      if (!this.base) {
        this.create();
      }
      this.text.html(text);
    };

    VDialog.prototype.btn1Text = function (text) {
      this.btn1.text(text);
    };
    VDialog.prototype.btn2Text = function (text) {
      this.btn2.text(text);
    };

    // VDialog.prototype.textClear = function () {
    //    this.text.removeClass();
    //    this.text.addClass("text");
    // }
    VDialog.prototype.textLeft = function () {
      this.text.addClass('text-left');
    };
    VDialog.prototype.textCenter = function () {
      this.text.addClass('text-center');
    };
    VDialog.prototype.textSelect = function () {
      this.text.addClass('text-select');
    };
    VDialog.prototype.textColor = function (color) {
      this.text.addClass(color);
    };
    VDialog.prototype.size = function (s) {
      this.panel.addClass(s);
    };

    return VDialog;
  })();
})(BST || (BST = {}));
