var BST;
(function (BST) {
  var MvList = BST.MvList = (function () {
    function MvList (parent, options) {
      parent = parent || $('body');
      var opt = this.opt = options || {};
      var data = this.data = opt.data || null;
      this.max = opt.max || 10;
      this.callbackSelected = opt.callbackSelected;
      this.callbackKarute = opt.callbackKarute;
      this.callbackClickStarBtn = opt.callbackClickStarBtn;
      this.callbackSorted = opt.callbackSorted;
      this.callbackChangePaged = opt.callbackChangePaged;

      var base = this.base = $('<div>').addClass('bst-mv-list').appendTo(parent);

      this.clear();
    }

    MvList.prototype.clear = function () {
      this.base.empty();
      this.mess = null;
      this.page = 0;
      this.pageLen = 0;
      this.currentSortData = null;
      this.currentSortDes = false; // 降順（大きいもの → 小さいものへ並び替え）か？
    };

    MvList.prototype.update = function (fmt, data) {
      this.data = data;

      this.clear();
      this.createHeader(fmt);
      this.createFooter(fmt);
      // this.createBody(data);
      this.updateList(data);
    };

    MvList.prototype.updateList = function (data, pageNoClear) {
      // sort
      this.sort(data, this.currentSortData, this.currentSortDes);

      // pege化
      this.pages = [];
      this.pageLen = 0;

      if (pageNoClear !== true) {
        this.page = 0;
      }
      var arr;
      for (var i = 0; i < data.length; i++) {
        if (i === 0 || i % this.max === 0) {
          arr = [];
          this.pages.push(arr);
          this.pageLen++;
        }

        arr.push(data[i]);
      }

      // リスト更新
      if (this.pages.length > 0 && this.pages[0].length > 0) {
        this.createBody(this.pages[this.page]);
      }

      // ページ番号更新
      this.updatePageNum();
    };

    // MvList.prototype.changePage = function (page) {
    //    this.createBody(this.page);
    // }
    MvList.prototype.nextPage = function () {
      this.page++;
      if (this.page >= this.pageLen) {
        this.page = this.pageLen - 1;
        return;
      }

      this.createBody(this.pages[this.page]);
      this.updatePageNum();

      if (this.callbackChangePaged) {
        this.callbackChangePaged(this.page);
      }
    };
    MvList.prototype.prevPage = function () {
      this.page--;
      if (this.page < 0) {
        this.page = 0;
        return;
      }

      this.createBody(this.pages[this.page]);
      this.updatePageNum();

      if (this.callbackChangePaged) {
        this.callbackChangePaged(this.page);
      }
    };

    MvList.prototype.updatePageNum = function () {
      this.num.text(this.page + 1 + '/' + this.pageLen);
    };

    MvList.prototype.createHeader = function (format) {
      var _self = this;

      var header = $('<div>').addClass('header').appendTo(this.base);
      $('<div>').addClass('sort-title').text('並び替え').appendTo(header);

      var sortBtns = this.sortBtns = [];
      for (var i = 0; i < format.sort.length; i++) {
        var s = format.sort[i];
        var btn = $('<div>').addClass('sort').data('data', s)
          .on('click', function () {
            var d = $(this).data('data');

            // データ更新
            if (_self.currentSortData === d) {
              _self.currentSortDes = !_self.currentSortDes;
            } else {
              _self.currentSortData = d;
              _self.currentSortDes = false;// 昇順にする
            }

            // リスト更新
            // _self.sort(d, _self.currentSortDes);
            _self.updateList(_self.data, true);

            // ソートボタン更新
            for (var j = 0; j < sortBtns.length; j++) {
              sortBtns[j].removeClass('sort-asc').removeClass('sort-selected');
            }
            $(this).addClass('sort-selected');
            if (_self.currentSortDes === false) {
              $(this).addClass('sort-asc');
            } // 昇順

            if (_self.callbackSorted) {
              _self.callbackSorted();
            }
          })
          .appendTo(header);
        $('<div>').text(s.label).appendTo(btn);
        sortBtns.push(btn);

        $('<div>').addClass('space').appendTo(header);
      }

      if (format.star) {
        $('<div>').addClass('space').appendTo(header);
        $('<div>').addClass('space').appendTo(header);
        var star = this.star = $('<div>').addClass('btn').appendTo(header);
        $('<div>').text(format.star.label).appendTo(star);
        star.on('click', function () {
          if (_self.callbackClickStarBtn) {
            _self.callbackClickStarBtn(star);
          }
        });
      }
    };

    // ソートしてリストを更新
    // MvList.prototype.updateSort = function () {
    //    this.sort(this.currentSortData, this.currentSortDes);
    // }

    // 降順（大きいもの → 小さいものへ並び替え）: true, 昇順: false
    MvList.prototype.sort = function (data, sortData, des) {
      if (!sortData) return;

      var func;
      if (des) {
        // 降順（大きいもの → 小さいものへ並び替え）
        func = function (_a, _b) {
          var a = _a[sortData.key];
          var b = _b[sortData.key];

          var aNan = (a !== a);
          var bNan = (b !== b);
          if (aNan && bNan) return 0;
          if (aNan) return -1;
          if (bNan) return 1;
          // ここまで来ればaもbもNaNの可能性はない
          if (a < b) return 1;
          if (a > b) return -1;
          return 0;
          // return a[sortData.key] < b[sortData.key];
        };
      } else {
        // 昇順
        func = function (_a, _b) {
          var a = _a[sortData.key];
          var b = _b[sortData.key];

          var aNan = (a !== a);
          var bNan = (b !== b);
          if (aNan && bNan) return 0;
          if (aNan) return 1;
          if (bNan) return -1;
          // ここまで来ればaもbもNaNの可能性はない
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
          // return a[sortData.key] > b[sortData.key];
        };
      }

      data.sort(func);
      // this.data.sort(func);
      // this.updateList(this.data);
    };

    MvList.prototype.createFooter = function (fmt) {
      var _self = this;
      var footer = $('<div>').addClass('footer').appendTo(this.base);
      var panel = $('<div>').addClass('panel').appendTo(footer);
      $('<div>').addClass('al').addClass('btn')
        .on('click', function () {
          _self.prevPage();
        })
        .appendTo(panel);
      var numbase = $('<div>').addClass('num').appendTo(panel);
      this.num = $('<div>').text('/').appendTo(numbase);

      $('<div>').addClass('ar').addClass('btn')
        .on('click', function () {
          _self.nextPage();
        })
        .appendTo(panel);
    };

    MvList.prototype.createBody = function (data) {
      if (this.body) this.body.remove();

      var body = this.body = $('<div>').addClass('body').appendTo(this.base);
      var list = this.list = $('<div>').addClass('list').appendTo(body);
      for (var i = 0; i < data.length; i++) {
        var item = this.createItem(data[i]);
        item.appendTo(list);
      }

      // sort用にelementを取得
      // this.items = list.children("div");

      // var a = tr.data("data");
      // var b = $.data(this.rows[0], "data");

      // this.rows.sort(function (aa, bb) {
      //    var c = $.data(aa, "data");
      //    return 1;
      // });
    };

    MvList.prototype.createItem = function (data) {
      var _self = this;

      var item = $('<div>').addClass('item').data('data', data);
      var detail = this.detail = $('<div>').addClass('detail').appendTo(item);
      var text1 = $('<div>').addClass('text').text(data.nm).appendTo(detail);
      var text2 = $('<div>').addClass('text').text(data.dnm).appendTo(detail);
      var tbase = $('<div>').addClass('time-base').appendTo(detail);
      $('<span>').addClass('g').text('★').appendTo(tbase);
      var time = $('<span>').addClass('time').text(data.date).appendTo(tbase);
      var g = $('<span>').addClass('g').text(BST.Com.adjustGrav(data.grav) + 'G').appendTo(tbase);

      var cbase = $('<div>').addClass('contents-base').appendTo(item);
      // var cpane = $("<div>").addClass("contetns-panel").appendTo(cbase);
      var mbase = $('<div>').addClass('movie-base').appendTo(cbase);

      // movie
      var movie = $('<div>').addClass('movie').appendTo(mbase);
      if (data.fid) {
        var imgs = [];
        for (var i = 0; i < data.tid.length; i++) {
          imgs.push(BST.MvListData.getMoviePath(data, data.tid[i]));
        }

        if (imgs[0]) {
          mbase.on('mouseenter', function () {
            _self.imgParaParaStart(movie, imgs);
          })
            .on('mouseleave', function () {
              _self.imgParaParaEnd(movie, imgs);
            });

          movie.css({
            'background-image': "url('" + imgs[0] + "')"
          });
        } else {
          // サムネイルなし
          $('<div>').addClass('text').text('サムネイルを作成できませんでした。').appendTo(movie);
          $('<div>').addClass('no-img').appendTo(movie);
        }
      } else {
        // 動画なし
        $('<div>').addClass('text').text('動画データが存在しません。').appendTo(movie);
        $('<div>').addClass('no-movie').appendTo(movie);
        mbase.addClass('no-cursor');
      }

      // 2d
      var i2d = $('<div>').addClass('i2d').appendTo(cbase);
      if (data.fid) {
        // /2m/{routeCode}/{fileId}/{width}/{height}/{sessCode}
        // var path = Const.imgurl + "2m/" + data.rcd + "/" + data.fid + "/" + BST.Com.session;
        var path = BST.MvListData.get2dPath(data);
        if (path) {
          i2d.css({
            'background-image': "url('" + path + "')"
          });
        }
      }

      // カルテボタン
      var kbtn = $('<div>').addClass('kbtn').appendTo(i2d);
      $('<div>').text('運転カルテを開く').appendTo(kbtn);

      // 動画表示
      mbase.on('click', function (e) {
        e.stopPropagation();

        if (_self.callbackSelected) {
          _self.callbackSelected(data);
        }
      });

      // カルテ開く
      kbtn.on('click', function (e) {
        e.stopPropagation();

        if (_self.callbackKarute) {
          _self.callbackKarute(data);
        }
      });

      $('<div>').addClass('space').appendTo(item);

      return item;
    };

    // MvList.prototype.imgParaParaId;
    MvList.prototype.imgParaParaStart = function (elem, imgs) {
      var _self = this;

      this.imgParaParaEnd(elem, imgs);

      var index = 0;
      this.imgParaParaPreLoad(index, imgs);
      this.imgParaParaId = setInterval(function () {
        index++;
        if (imgs.length <= index) index = 0;
        elem.css({
          'background-image': "url('" + imgs[index] + "')"
        });

        _self.imgParaParaPreLoad(index, imgs);
      }, 1200);
    };
    MvList.prototype.imgParaParaEnd = function (elem, imgs) {
      if (this.imgParaParaId) clearInterval(this.imgParaParaId);
      this.imgParaParaId = null;
      elem.css({
        'background-image': "url('" + imgs[0] + "')"
      });
    };

    MvList.prototype.imgParaParaPreLoad = function (index, imgs) {
      index++;
      if (imgs.length <= index) index = 0;

      $('<img>').attr('src', imgs[index]);
    };

    MvList.prototype.setMess = function (text) {
      if (!this.mess) {
        this.mess = $('<div>').addClass('mess').appendTo(this.base);
      }

      this.mess.text(text);
    };

    MvList.prototype.disp = function (b) {
      if (b) {
        this.base.show();
      } else {
        this.base.hide();
      }
    };

    MvList.prototype.getCurrentDatas = function () {
      return this.pages[this.page];
    };

    MvList.prototype.selectedItem = function (label, val) {
      this.list.find('.item').each(function (i, elem) {
        var d = $(elem).data('data');
        if (d[label] === val) {
          $(elem).addClass('selected');
        } else {
          $(elem).removeClass('selected');
        }
      });
    };

    MvList.prototype.centerItem = function (label, val) {
      var _self = this;
      this.list.find('.item').each(function (i, elem) {
        var d = $(elem).data('data');
        if (d[label] === val) {
          var top = $(elem).position().top;
          var h2 = _self.body.outerHeight(true) / 2;
          var eh2 = $(elem).outerHeight(true) / 2;

          // _self.body.scrollTop(top - h2 + eh2);
          _self.body.animate({ scrollTop: top - h2 + eh2 });
        }
      });
    };

    return MvList;
  })();
})(BST || (BST = {}));
