var BST;
(function (BST) {
  var Sheet = BST.Sheet = (function () {
    function Sheet (parent, opt) {
      this.parent = parent = parent || 'body';
      opt = opt || {};
      this.tabsData = opt.tabs || [];
      this.callbackSelected = opt.callbackSelected;

      // this.createHeader(parent);
      // this.createBody(this.body);
    }

    Sheet.prototype.update = function (data, movies) {
      this.clear();
      this.create(data, movies);
    };

    Sheet.prototype.create = function (data, movies) {
      var _self = this;
      this.base = $('<div>').addClass('bst-sheet-bg').appendTo(this.parent);
      this.sheet = $('<div>').addClass('bst-sheet').appendTo(this.base);
      this.mess = $('<div>').addClass('mess').appendTo(this.sheet);
      this.header = $('<div>').addClass('header').appendTo(this.sheet);
      this.body = $('<div>').addClass('body').appendTo(this.sheet);
      this.body2 = $('<div>').addClass('body2').appendTo(this.sheet);

      this.createTabs(this.sheet);
      this.createHeader(this.header);
      // eslint-disable-next-line no-irregular-whitespace
      // this.name.text(data.nm + "　" + data.dnm);
      this.name.text(BST.Util.textOverflowEllipsis(data.dnm, 20));
      this.title.text('　' + (this.tabsData[0].label || '運転カルテ'));
      this.createBody(this.body, data);

      if (movies && movies.length !== 0) {
        this.createBody2(this.body2, movies);
      } else {
        this.tabs.hide();
        this.body2.hide();
      }

      var btnClose = $('<div>').addClass('x-btn').appendTo(this.sheet);
      btnClose.on('click', function () {
        _self.clear();
      });

      if (this.btns[0]) {
        this.btns[0].trigger('click');
      }
    };

    Sheet.prototype.createTabs = function (parent) {
      var _self = this;

      var tabs = this.tabs = $('<div>').addClass('tabs').appendTo(parent);
      // var btnDatas = [
      //   { "label": "運転カルテ", id:1 }
      //   , { "label": "★動画リスト", id: 2 }
      // ];
      var btnDatas = this.tabsData;
      var btns = this.btns = [];
      for (var i = 0; i < btnDatas.length; i++) {
        var btn = $('<div>').addClass('btn').data('data', btnDatas[i]).appendTo(tabs);
        $('<div>').text(btnDatas[i].label).appendTo(btn);
        btn.on('click', function () {
          var d = $(this).data('data');

          for (var i = 0; i < btns.length; i++) {
            btns[i].removeClass('selected');
          }
          $(this).addClass('selected');

          _self.changeTab(d);
        });
        this.btns.push(btn);
        $('<div>').addClass('space').appendTo(tabs);
      }
    };

    Sheet.prototype.changeTab = function (data) {
      this.title.text('　' + data.label);
      this.icon.removeClass('icon');
      this.icon.removeClass('icon2');
      if (data.id === 1) {
        this.icon.addClass('icon');
        this.body.show();
        this.body2.hide();
      } else if (data.id === 2) {
        this.icon.addClass('icon2');
        this.body.hide();
        this.body2.show();
      }
    };

    Sheet.prototype.createHeader = function (parent) {
      var _self = this;

      // var rptBg = this.base = $("<div>").addClass("bst-sheet-bg").appendTo(parent);
      // var rpt = $("<div>").addClass("bst-sheet").appendTo(rptBg);
      // var mess = this.mess = $("<div>").addClass("mess").appendTo(rpt);
      // var rptIn = this.body = $("<div>").appendTo(rpt);

      // row1
      var r1 = this.r1 = $('<div>').addClass('row1').appendTo(parent);
      var r1tbl = $('<div>').addClass('table').appendTo(r1);
      var r1tblIcon = this.icon = $('<div>').addClass('icon').appendTo(r1tbl);
      var r1tblName = this.name = $('<div>').addClass('name').appendTo(r1tbl);
      var r1tblTitle = this.title = $('<div>').addClass('title').appendTo(r1tbl);
      // eslint-disable-next-line no-irregular-whitespace
      // var r1tblTitle = this.title = $("<div>").addClass("title").text("　運転カルテ").appendTo(r1tbl);
      // var r1tblDate = $("<div>").addClass("date").appendTo(r1tbl);
      // var dp = new BST.DatePicker(r1tblDate
      //    , {
      //        callbackSelected: function (text) {
      //            alert(text);
      //        }
      //        , css: {
      //            "width": "80px"
      //        }
      //    });
      // dp.setDate(new Date());

      // var r1tblText = $("<div>").addClass("text").appendTo(r1tbl);
      // var r1tblNumBase = $("<div>").appendTo(r1tbl);
      // var r1tblNum = this.gnum = $("<input>").val(0.3).addClass("num").appendTo(r1tblNumBase);
      // r1tblNum.spinner({
      //    step: 0.01
      //    , max: 0.5
      //    , min: 0
      // });
      // var a = this.gnum.val();
    };

    Sheet.prototype.setMess = function (mess) {
      this.mess.text(mess);
    };

    Sheet.prototype.createBody = function (parent, data) {
      // row2
      var r2 = this.r2 = $('<div>').addClass('row2').appendTo(parent);
      var r2pnl = $('<div>').addClass('panel-s').appendTo(r2);
      if (data.invalid === 9) {
        r2pnl.addClass('mask');
        $('<div>').addClass('exclusion').appendTo(r2pnl);
      }

      var r2lbl = $('<div>').addClass('label').appendTo(r2pnl);
      // var r2lblorg = $("<div>").appendTo(r2lbl);
      var r2lblnm = $('<div>').addClass('org').appendTo(r2lbl);
      r2lblnm.text(data.nm);
      // eslint-disable-next-line no-irregular-whitespace
      // r2lblnm.text(data.nm + "　/　");
      var r2lblcar = $('<div>').addClass('car-type').appendTo(r2lbl);
      var tc = '';
      if (data.cars) {
        for (var i = 0; i < data.cars.length; i++) tc += data.cars[i] + ' ';
      }
      r2lblcar.text(tc);

      var r2tm = $('<div>').addClass('time').appendTo(r2pnl);
      r2tm.html('期間：' + this.convDate(data.from) + '　～　' + this.convDate(data.to) + '<br />' +
        '記録時間：' + this.convTime(data.termAll) + '（運転時間：' + this.convTime(data.termMove) + '　駐停車時間：' + this.convTime(data.termStay) + '）');
      // r2tm.html("期間：" + data.from +  "<br />"
      //          + "記録時間：" + data.termAll + "（運転時間：" + data.termMove + "　駐停車時間：" + data.termStay + "）");

      var r2eva = $('<div>').addClass('eva').appendTo(r2pnl);
      $('<div>').text('あなたの運転は　').appendTo(r2eva);
      var r2evaten = $('<div>').addClass('ten').appendTo(r2eva);
      if (data.point === -1) {
        r2evaten.addClass(data.hDrive.rank).text(' 0点｜ - 判定　　　');
      } else {
        r2evaten.addClass(data.hDrive.rank).text(data.point + '点｜' + data.hDrive.rank + '判定（' + data.hDrive.title + '）');
      }
      $('<div>').text('でした').appendTo(r2eva);
      $('<span>').text('　　').appendTo(r2eva);
      var r2evag = $('<div>').addClass('g').appendTo(r2eva);
      r2evag.text('評価基準G：' + data.saiten + 'G');
      $('<span>').text('　').appendTo(r2eva);

      // row3
      var r3 = this.r3 = $('<div>').addClass('row3').appendTo(parent);
      var r3pnl = $('<div>').addClass('panel').appendTo(r3);
      var r3tlt = $('<div>').addClass('title').text('判定根拠').appendTo(r3pnl);
      var r3cnt = $('<div>').addClass('contents').appendTo(r3pnl);
      var r3cntgt = $('<div>').addClass('graph-title').text('各方向へかかる加速度分布').appendTo(r3cnt);

      var r3cntg = $('<div>').addClass('graph').appendTo(r3cnt);
      var r3cntgp1 = $('<div>').addClass('image').addClass('p1').appendTo(r3cntg);
      this.set2d(r3cntgp1, data);

      var r3cntgp2 = $('<div>').addClass('image').addClass('p2').appendTo(r3cntg);
      this.setTiko(r3cntgp2, data);

      // row4
      // var r4 = this.r4 = $("<div>").addClass("row4").appendTo(parent);
      // var r4pnl = $("<div>").addClass("panel2").appendTo(r4);
      // var r4tlt = $("<div>").addClass("title").text("地図と走行時間帯").appendTo(r4pnl);
      // var r4cnt = $("<div>").addClass("contents").appendTo(r4pnl);
      // var r4ft = $("<div>").addClass("foot").appendTo(r4pnl);
      // var r4bar = $("<div>").addClass("bar").appendTo(r4ft);
      // var r4tm0 = $("<div>").addClass("time").appendTo(r4bar);
      // $("<div>").text("0").appendTo(r4tm0);
      // var r4tm6 = $("<div>").addClass("time").appendTo(r4bar);
      // $("<div>").text("6").appendTo(r4tm6);
      // var r4tm12 = $("<div>").addClass("time").appendTo(r4bar);
      // $("<div>").text("12").appendTo(r4tm12);
      // var r4tm18 = $("<div>").addClass("time").appendTo(r4bar);
      // $("<div>").text("18").appendTo(r4tm18);
      // var r4tm24 = $("<div>").addClass("time").appendTo(r4bar);
      // $("<div>").text("24").appendTo(r4tm24);

      // row4
      var r4 = this.r4 = $('<div>').addClass('row4').appendTo(parent);
      var r4pnl = $('<div>').addClass('panel').appendTo(r4);
      var r4tlt = $('<div>').addClass('title').text('コメント').appendTo(r4pnl);
      var r4cnt = $('<div>').addClass('contents').appendTo(r4pnl);

      var r4dr = $('<div>').addClass('comment').appendTo(r4cnt);

      var r4dr1 = $('<div>').appendTo(r4dr);
      var r4dr1lbl = $('<div>').addClass('c-title').text('安全運転度合い').appendTo(r4dr1);

      var t1 = '';
      var t2 = '';
      if (data.hDrive.comment) {
        var tt = data.hDrive.comment;
        tt = BST.Util.replaceAll(tt, '\r\n', '\n');
        tt = BST.Util.replaceAll(tt, '\r', '\n');
        var arr = tt.split('\n');
        try {
          t2 = arr[1];
          t1 = arr[0];
        } catch (e) {

        }
      }

      var r4dr1txt1 = $('<div>').addClass('c-text').appendTo(r4dr1);
      r4dr1txt1.text(t1);
      var r4dr1txt2 = $('<div>').addClass('c-text').appendTo(r4dr1);
      r4dr1txt2.text(t2);

      var r4dr2 = $('<div>').addClass('yoko').appendTo(r4dr);
      // var r4dr2lbl = $("<div>").addClass("c-title").text("運転操作（基準G）").appendTo(r4dr2);
      var r4dr2lbl = $('<div>').addClass('c-title').text('運転操作　').appendTo(r4dr2);
      // var r4dr2txt = $("<div>").addClass("c-text3").text("*判定はA～Eの5段階").appendTo(r4dr2);

      var r4dr3 = $('<div>').addClass('yoko').appendTo(r4dr);
      var r4dr3txt1 = $('<div>').addClass('c-text').text('左に曲がる：').appendTo(r4dr3);
      // var r4dr3txt1 = $("<div>").addClass("c-text").text("左に曲がる（"+0.3).appendTo(r4dr3);
      // var r4dr3txt2 = $("<div>").addClass("c-text4").text("G").appendTo(r4dr3);
      // var r4dr3txt3 = $("<div>").addClass("c-text").text("）：").appendTo(r4dr3);
      var r4dr3txt4 = $('<div>').addClass('c-text').appendTo(r4dr3);
      r4dr3txt4.addClass(data.hLeft.rank);
      r4dr3txt4.text(data.hLeft.comment);

      var r4dr4 = $('<div>').addClass('yoko').appendTo(r4dr);
      var r4dr4txt1 = $('<div>').addClass('c-text').text('右に曲がる：').appendTo(r4dr4);
      // var r4dr4txt1 = $("<div>").addClass("c-text").text("右に曲がる（" + 0.3).appendTo(r4dr4);
      // var r4dr4txt2 = $("<div>").addClass("c-text4").text("G").appendTo(r4dr4);
      // var r4dr4txt3 = $("<div>").addClass("c-text").text("）：").appendTo(r4dr4);
      var r4dr4txt4 = $('<div>').addClass('c-text').appendTo(r4dr4);
      r4dr4txt4.addClass(data.hRight.rank);
      r4dr4txt4.text(data.hRight.comment);

      var r4dr5 = $('<div>').addClass('yoko').appendTo(r4dr);
      var r4dr5txt1 = $('<div>').addClass('c-text').text('加速時：').appendTo(r4dr5);
      // var r4dr5txt1 = $("<div>").addClass("c-text").text("加速時（" + 0.3).appendTo(r4dr5);
      // var r4dr5txt2 = $("<div>").addClass("c-text4").text("G").appendTo(r4dr5);
      // var r4dr5txt3 = $("<div>").addClass("c-text").text("）：").appendTo(r4dr5);
      var r4dr5txt4 = $('<div>').addClass('c-text').appendTo(r4dr5);
      r4dr5txt4.addClass(data.hAccel.rank);
      r4dr5txt4.text(data.hAccel.comment);

      var r4dr6 = $('<div>').addClass('yoko').appendTo(r4dr);
      var r4dr6txt1 = $('<div>').addClass('c-text').text('減速時：').appendTo(r4dr6);
      // var r4dr6txt1 = $("<div>").addClass("c-text").text("減速時（" + 0.3).appendTo(r4dr6);
      // var r4dr6txt2 = $("<div>").addClass("c-text4").text("G").appendTo(r4dr6);
      // var r4dr6txt3 = $("<div>").addClass("c-text").text("）：").appendTo(r4dr6);
      var r4dr6txt4 = $('<div>').addClass('c-text').appendTo(r4dr6);
      r4dr6txt4.addClass(data.hBreak.rank);
      r4dr6txt4.text(data.hBreak.comment);

      // 運行情報
      var r4pnl2 = $('<div>').addClass('panel').appendTo(r4);
      var r4tlt2 = $('<div>').addClass('title').text('運行情報').appendTo(r4pnl2);
      var r4cnt2 = $('<div>').addClass('contents').appendTo(r4pnl2);

      r4dr = $('<div>').addClass('drive').appendTo(r4cnt2);

      r4dr1 = $('<div>').appendTo(r4dr);
      r4dr1lbl = $('<div>').addClass('d-label').text('平均速度：').appendTo(r4dr1);
      var r4dr1val = $('<div>').addClass('d-value').appendTo(r4dr1);
      r4dr1val.text(data.aveV);
      var r4dr1nt = $('<div>').addClass('d-unit').text('km/h').appendTo(r4dr1);

      r4dr2 = $('<div>').appendTo(r4dr);
      r4dr2lbl = $('<div>').addClass('d-label').text('最高速度：').appendTo(r4dr2);
      var r4dr2val = $('<div>').addClass('d-value').appendTo(r4dr2);
      r4dr2val.text(data.maxV);
      var r4dr2nt = $('<div>').addClass('d-unit').text('km/h').appendTo(r4dr2);

      r4dr3 = $('<div>').appendTo(r4dr);
      var r4dr3lbl = $('<div>').addClass('d-label').text('最大加速度：').appendTo(r4dr3);

      r4dr4 = $('<div>').appendTo(r4dr);
      var r4dr4lbl = $('<div>').addClass('d-label2').text('アクセル・ブレーキ').appendTo(r4dr4);
      var r4dr4val = $('<div>').addClass('d-value').appendTo(r4dr4);
      r4dr4val.text(data.maxGY);
      var r4dr4nt = $('<div>').addClass('d-unit').text('G').appendTo(r4dr4);

      r4dr5 = $('<div>').appendTo(r4dr);
      var r4dr5lbl = $('<div>').addClass('d-label2').text('ハンドル操作　　　').appendTo(r4dr5);
      var r4dr5val = $('<div>').addClass('d-value').appendTo(r4dr5);
      r4dr5val.text(data.maxGX);
      var r4dr5nt = $('<div>').addClass('d-unit').text('G').appendTo(r4dr5);

      // row5
      var r5 = this.r5 = $('<div>').addClass('row5').appendTo(parent);
      var r5pnl = $('<div>').addClass('panel').appendTo(r5);
      var r5tlt = $('<div>').addClass('title').text('多角的分析結果').appendTo(r5pnl);
      var r5cnt = $('<div>').addClass('contents').appendTo(r5pnl);

      var r5cntgt = $('<div>').addClass('graph-title').text('「速度」と「前後加速度（アクセル・ブレーキ）の関係').appendTo(r5cnt);
      var r5cntgt2 = $('<div>').addClass('graph-title2').text('「速度」と「横加速度（ハンドル操作）」との関係').appendTo(r5cnt);

      var r5cntg = $('<div>').addClass('graph').appendTo(r5cnt);
      var r5cntgp1 = $('<div>').addClass('image').addClass('p1').appendTo(r5cntg);
      this.setTakaku(r5cntgp1, data, 'y');

      var r5cntgp2 = $('<div>').addClass('image').addClass('p2').appendTo(r5cntg);
      this.setTakaku(r5cntgp2, data, 'x');
    };

    Sheet.prototype.createBody2 = function (parent, data) {
      var list = $('<div>').addClass('list').appendTo(parent);
      for (var i = 0; i < data.length; i++) {
        var item = this.createItem(data[i]);
        item.appendTo(list);
      }
    };

    Sheet.prototype.createItem = function (data) {
      var _self = this;

      // ここでID－＞PATHへ変換
      var _imgs = [];
      for (var i = 0; i < data.tid.length; i++) {
        _imgs.push(BST.MvListData.getMoviePath(data, data.tid[i]));
      }

      var _i2d = BST.MvListData.get2dPath(data);

      var item = $('<div>').addClass('item').data('data', data);
      var detail = this.detail = $('<div>').addClass('detail').appendTo(item);
      // var text1 = $("<div>").addClass("text").text(data.nm).appendTo(detail);
      // var text2 = $("<div>").addClass("text").text(data.dnm).appendTo(detail);
      var tbase = $('<div>').addClass('time-base').appendTo(detail);
      $('<span>').addClass('g').text('★').appendTo(tbase);
      var time = $('<span>').addClass('time').text(data.date).appendTo(tbase);
      var g = $('<span>').addClass('g').text(BST.Com.adjustGrav(data.grav) + 'G').appendTo(tbase);

      var cbase = $('<div>').addClass('contents-base').appendTo(item);
      var mbase = $('<div>').addClass('movie-base').appendTo(cbase);

      // movie
      var movie = $('<div>').addClass('movie').appendTo(mbase);
      if (data.fid) {
        if (_imgs[0]) {
          movie.on('mouseenter', function () {
            _self.imgParaParaStart(movie, _imgs);
          })
            .on('mouseleave', function () {
              _self.imgParaParaEnd(movie, _imgs);
            });
          movie.css({
            'background-image': "url('" + _imgs[0] + "')"
          });
        } else {
          // サムネイルなし
          $('<div>').addClass('text').text('サムネイルを作成できませんでした。').appendTo(movie);
          $('<div>').addClass('no-img').appendTo(movie);
        }
      } else {
        // 動画なし
        $('<div>').addClass('text').text('動画データが存在しません。').appendTo(movie);
        $('<div>').addClass('no-movie').appendTo(movie);
        mbase.addClass('no-cursor');
      }

      // 2d
      var i2d = $('<div>').addClass('i2d').appendTo(cbase);
      if (_i2d) {
        i2d.css({
          'background-image': "url('" + _i2d + "')"
        });
      }
      // var kbtn = $("<div>").addClass("kbtn").appendTo(i2d);
      // $("<div>").text("運転カルテを開く").appendTo(kbtn);

      // 動画表示
      mbase.on('click', function (e) {
        e.stopPropagation();

        if (_self.callbackSelected) {
          _self.callbackSelected(data);
        }
      });

      // カルテ開く
      // kbtn.on("click", function (e) {
      //    e.stopPropagation();

      //    if (_self.callbackKarute)
      //        _self.callbackKarute(data);
      // });

      return item;
    };

    // Sheet.prototype.imgParaParaId;
    Sheet.prototype.imgParaParaStart = function (elem, imgs) {
      var _self = this;

      this.imgParaParaEnd(elem, imgs);

      var index = 0;
      this.imgParaParaPreLoad(index, imgs);
      this.imgParaParaId = setInterval(function () {
        index++;
        if (imgs.length <= index) index = 0;
        elem.css({
          'background-image': "url('" + imgs[index] + "')"
        });

        _self.imgParaParaPreLoad(index, imgs);
      }, 1200);
    };
    Sheet.prototype.imgParaParaEnd = function (elem, imgs) {
      if (this.imgParaParaId) clearInterval(this.imgParaParaId);
      this.imgParaParaId = null;
      elem.css({
        'background-image': "url('" + imgs[0] + "')"
      });
    };

    Sheet.prototype.imgParaParaPreLoad = function (index, imgs) {
      index++;
      if (imgs.length <= index) index = 0;

      $('<img>').attr('src', imgs[index]);
    };

    Sheet.prototype.set2d = function (elem, data) {
      /// 2d/{from}/{orgCode}/{driverCode}/{routeCode}/{width}/{height}/{sessCode}
      // var date = BST.Util.replaceAll(data.from, "/", "-");
      var date = new Date(data.from);
      var _date = BST.Util.dateFormat(date, 'yyyy-MM-dd');
      var url = BST.Const.imgurl + '2d/' + _date + '/' +
        data.cd + '/' + data.dcd + '/' + data.rcd + '/' +
        247 + '/' + 247 + '/' + BST.Com.session;
      // elem.css({
      //    "background-image": "url('" + url + "')"
      //    , "background-repeat": "no-repeat"
      // });
      $('<img border="0" src="' + url + '" width="247" height="247">').appendTo(elem);
    };
    Sheet.prototype.setTiko = function (elem, data) {
      /// taiko/karte/{from}/{orgCode}/{driverCode}/{routeCode}/{width}/{height}/{sessCode}"            var date = BST.Util.replaceAll(data.from, "/", "-");
      // var date = BST.Util.replaceAll(data.from, "/", "-");
      var date = new Date(data.from);
      var _date = BST.Util.dateFormat(date, 'yyyy-MM-dd');
      var url = BST.Const.imgurl + 'taiko/karte/' + _date + '/' +
        // + data.kijun + "/"
        // + 999 + "/"
        data.cd + '/' + data.dcd + '/' + data.rcd + '/' +
        247 + '/' + 247 + '/' + BST.Com.session;
      // elem.css({
      //    "background-image": "url('" + url + "')"
      //    , "background-repeat": "no-repeat"
      // });
      $('<img border="0" src="' + url + '" width="247" height="247">').appendTo(elem);
    };

    Sheet.prototype.setTakaku = function (elem, data, type) {
      /// takaku/x/{from}/{orgCode}/{driverCode}/{routeCode}/{width}/{height}/{sessCode}
      // var date = BST.Util.replaceAll(data.from, "/", "-");
      var date = new Date(data.from);
      var _date = BST.Util.dateFormat(date, 'yyyy-MM-dd');
      var url = BST.Const.imgurl + 'takaku/' + type + '/' + _date + '/' +
        data.cd + '/' + data.dcd + '/' + data.rcd + '/' +
        320 + '/' + 192 + '/' + BST.Com.session;
      // elem.css({
      //    "background-image": "url('" + url + "')"
      //    , "background-repeat": "no-repeat"
      // });
      $('<img border="0" src="' + url + '" width="320" height="192">').appendTo(elem);
    };

    Sheet.prototype.clear = function () {
      this.clearBody();
      if (this.base) this.base.remove();
      this.base = null;
    };

    Sheet.prototype.clearBody = function () {
      this.clearMap();

      if (this.r2) this.r2.remove();
      this.r2 = null;
      if (this.r3) this.r3.remove();
      this.r3 = null;
      if (this.r4) this.r4.remove();
      this.r4 = null;
      if (this.r5) this.r5.remove();
      this.r5 = null;
    };

    Sheet.prototype.clearMap = function () {

    };

    Sheet.prototype.convTime = function (time) {
      if (!time) return '-時-分-秒';
      var d = new Date('1900/1/1 ' + time);
      return BST.Util.dateFormat(d, 'hh時mm分ss秒');
    };

    Sheet.prototype.convDate = function (date) {
      if (!date) return '-年-月-日 -時-分';
      var d = new Date(date);
      return BST.Util.dateFormat(d, 'yyyy年MM月dd日 hh時mm分');
    };

    return Sheet;
  })();
})(BST || (BST = {}));
