var BST;
(function (BST) {
  var Login = BST.Login = (function () {
    function Login (parent, options) {
      parent = parent || $('body');
      var opt = this.opt = options || {};
      var data = this.data = opt.data || null;
      this.type = opt.type || '';
      this.callbackLogin = opt.callbackLogin;

      var base = this.base = $('<div>').addClass('bst-login').appendTo(parent);
      this.create();
    }

    Login.prototype.create = function () {
      var _self = this;

      var contents = $('<div>').addClass('contents');
      var img = $('<div>').addClass('image' + this.type).appendTo(contents);
      var mess = this.mess = $('<div>').addClass('mess').appendTo(contents);
      // var line0 = $("<div>").addClass("line").appendTo(contents);
      // var title0 = $("<div>").addClass("ccd-title").text("企業コード").appendTo(line0);
      // var ccd = this.ccd = $('<input type="text">').addClass("ccd").appendTo(line0);
      var form = $('<form onSubmit="return false;">').appendTo(contents);

      var line1 = $('<div>').addClass('line').appendTo(form);
      var title1 = $('<div>').addClass('id-title').text('ログインID').appendTo(line1);
      var id = this.id = $('<input type="text" name="bstusername">').addClass('id').appendTo(line1);

      var line2 = $('<div>').addClass('line').appendTo(form);
      var title2 = $('<div>').addClass('pass-title').text('パスワード').appendTo(line2);
      var pass = this.pass = $('<input type="password" name="bstpassword">').addClass('pass').appendTo(line2);

      var line0 = $('<div>').addClass('line').appendTo(form);
      var title0 = $('<div>').addClass('ccd-title').text('企業コード').appendTo(line0);
      var ccd = this.ccd = $('<input type="text">').addClass('ccd').appendTo(line0);

      var line3 = $('<div>').addClass('line').appendTo(form);
      var title3 = $('<div>').addClass('ccd-title').text('').appendTo(line3);
      var button = $('<input type="submit">').addClass('button' + this.type).val('ログイン').appendTo(line3);

      button.on('click', function () {
        if (_self.callbackLogin) {
          _self.callbackLogin(ccd.val(), id.val(), pass.val());
        }
      });

      contents.appendTo(this.base);
    };

    Login.prototype.visible = function (b) {
      if (b) {
        this.base.show();
      } else {
        this.base.hide();
      }
    };

    Login.prototype.defaultIdPass = function (ccd, id, pass) {
      this.ccd.val(ccd);
      this.id.val(id);
      this.pass.val(pass);
    };

    Login.prototype.message = function (text) {
      this.mess.text(text);
    };

    return Login;
  })();
})(BST || (BST = {}));
