var BST;
(function (BST) {
  var MvListSrv = BST.MvListSrv = (function () {
    function MvListSrv (parent) {
      var _self = this;

      var d = this._data = new BST.MvListData();

      var _offsetElem = null;
      var updatePos = function () {
        if (!_offsetElem) return;
        var offset = _offsetElem.position();
        offset.top += _offsetElem.outerHeight(true);
        _self._2d.offset(offset);
      };
      $(window).on('resize', function () {
        updatePos();
      });

      // var _disp2d = false;
      var view = this._view = new BST.MvList(parent
        , {
          max: d.pageMax,
          callbackSelected: function (data) {
            _self.onSelectedItem(data);
          },
          callbackKarute: function (data) {
            _self.onKarute(data);
          },
          callbackClickStarBtn: function (elem) {
            _offsetElem = $(elem);
            /// /var pos = $(elem).position();
            // var offset = $(elem).offset();
            // offset.top += $(elem).outerHeight(true);
            // _self._2d.offset(offset);
            updatePos();

            // open
            // if (!_disp2d) {
            if (!_2d.disp()) {
              var movies = view.getCurrentDatas() || {};
              if (!movies || movies.length === 0) {
                // ページデータなし
              } else {
                _self.update2d();

                // _disp2d = !_disp2d;
                // _self._2d.disp(_disp2d);
                _self._2d.disp(!_self._2d.disp());
              }
            } else {
              // close
              // _disp2d = !_disp2d;
              // _self._2d.disp(_disp2d);
              _self._2d.disp(!_self._2d.disp());
            }

            // if (_self._2d) {
            //    _self._2d = new BST.VideoPlayer(elem, {

            //    });
            // }

            /// / open
            // if (!_disp2d) {
            //    _disp2d = !_disp2d;
            //    _self._2d.disp(_disp2d);

            // }
            //    // close
            // else {
            //    _disp2d = !_disp2d;
            //    _self._2d.disp(_disp2d);
            // }
          },
          callbackSorted: function () {
            _self.update2d();
          },
          callbackChangePaged: function (page) {
            _self.update2d();
          }
        });

      var _2d = this._2d = new BST.Mv2d(parent, {
        callbackStar: function (data) {
          _self._view.centerItem('fid', data.fid);
          _self.play(data, data.fid);
        }
      });
      // _2d.disp(_disp2d);
      _2d.disp(false);

      this.player = new BST.VideoPlayer();

      // this.change("dept");
    }

    MvListSrv.prototype.change = function (type, cd, sc, from, kijun, callback) {
      var _self = this;

      this._2d.disp(false);

      // if (this._type == type) return;
      // this._type = type;
      // if (this._cd == cd) return;
      this._cd = cd;

      var fmt = this._data.format;
      if (!fmt) return;

      this._data.load(type, cd, sc, from, kijun
        , function (res) {
          if (res.suc) {
            _self._type = type;

            if (res.data.movies) {
              // デフォルトを降順にしとく。ソートタブが選択されていたら内部でそちらにソートされる
              _self._view.sort(res.data.movies, _self._data.format.sort[1], true);
              _self._view.update(fmt, res.data.movies);
            }
          } else {
            _self._view.clear();
            _self._view.setMess(res.mess);
            _self._type = type;
          }
          if (callback) callback(res);
        }
      );
    };

    MvListSrv.prototype.onSelectedItem = function (data) {
      // var src = BST.MvListData.getMoviePath(data,data.fid);
      // this.player.open(data, src);
      this.play(data, data.fid);

      // alert(node.label)
      // var param={
      //    type: this._type
      //    ,data:data
      // }

      // $(this).trigger("select",param);
    };

    MvListSrv.prototype.update2d = function () {
      var _self = this;

      this._2d.clear();

      var movies = this._view.getCurrentDatas() || {};
      var ids = [];
      for (var i = 0; i < movies.length; i++) {
        ids.push(movies[i].fid);
      }
      this._data.imgJson(BST.Com.session, 274, 274, ids, function (res) {
        if (res.suc) {
          _self._2d.update(_self._data.img(BST.Com.session, 274, 274, ids), res.data, movies);
        } else {
          _self._2d.update(null, null, null);
        }
      });
    };

    MvListSrv.prototype.play = function (data) {
      if (!data.fid) return;

      var src = BST.MvListData.getMoviePath(data, data.fid);
      this.player.open(data, src);
      this._view.selectedItem('fid', data.fid);
    };

    MvListSrv.prototype.onKarute = function (data) {
      var param = {
        data: data
      };

      $(this).trigger('karute', param);
    };

    MvListSrv.prototype.disp = function (b) {
      this._view.disp(b);
      if (b === false) {
        this._2d.disp(false);
      }
    };

    return MvListSrv;
  })();
})(BST || (BST = {}));
