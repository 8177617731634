var BST;
(function (BST) {
  var DlCtr = BST.DlCtr = (function () {
    var TYPE = 'dl-ctr';

    function DlCtr () {
      var param = {};
      BST.BaseCtr.call(this, TYPE, param);

      this._data = new BST.DlData();

      this.menu = $('<div>').addClass('bst-dl-ctr-menu').appendTo($('#app-dl'));

      $('<div>').addClass('title').appendTo(this.menu);
      $('<a href="' + BST.DlData.manual_url + '" download="' + BST.DlData.manual_dl_name + '">').addClass('dl-ml').addClass('item').appendTo(this.menu);
      // $("<a>").addClass("dl-ml").addClass("item").appendTo(this.menu)
      // .on("click", function () {
      //    location.href = BST.DlData.manualurl;
      // });
      $('<a href="' + BST.DlData.viewer_url + '" download="' + BST.DlData.viewer_dl_name + '">').addClass('dl-vw').addClass('item').appendTo(this.menu);
      // $("<a>").addClass("dl-vw").addClass("item").appendTo(this.menu)
      // .on("click", function () {
      //    location.href = BST.DlData.viewerurl;
      // });
    }
    BST.Util.inherits(DlCtr, BST.BaseCtr);

    // BaseCtr関係
    // DlCtr.prototype.getPanel = function () {
    //    return $("#app-dl-contents");
    // }

    // DlCtr.prototype.__changedPanel = function (type) {
    //    if (type != TYPE) {
    //        for (var i = 0; i < this.items.length; i++)
    //            this.items[i].removeClass("item-active");
    //    }
    // }

    // BaseCtr関係以外
    // DlCtr.prototype.active = function () {
    //    //this.dispPanel(3);
    //    this.dispPanel();
    // }

    return DlCtr;
  })();
})(BST || (BST = {}));
