var BST;
(function (BST) {
  var DriveCtr = BST.DriveCtr = (function () {
    var TYPE = 'drive-ctr';

    function DriveCtr () {
      var param = {};
      BST.BaseCtr.call(this, TYPE, param);

      var _self = this;

      var tree = $('#app-tree');
      var contents = $('#app-tree-contents');
      // var driveBase = $("#app-tree-contents>.drive-ctr");
      var driveList = $('#app-tree-contents>.drive-ctr>.list');
      var title = this.title = $('#app-tree-contents>.drive-ctr>.row2>#title');
      var icon = this.icon = $('#app-tree-contents>.drive-ctr>.row2>#icon');
      var csv = this.csv = $('#app-tree-contents>.drive-ctr>.row1 #csv');
      if (!BST.Com.isCsv()) {
        csv.hide();
      } else {
        csv.on('click', function () {
          var kijun = 999;
          _self.listSrv.csv(BST.Com.current.node.type, BST.Com.current.node.cd, BST.Com.session, _self.dp1.value(), _self.dp2.value(), kijun, BST.Com.current.pnode.cd);
        });
      }
      var print = this.print = $('#app-tree-contents>.drive-ctr>.list > #print');
      print.on('click', function () {
        var kijun = 999;
        var param = {
          type: BST.Com.current.node.type,
          cd: BST.Com.current.node.cd,
          sc: BST.Com.session,
          from: _self.dp1.value(),
          to: _self.dp2.value(),
          kijun: kijun,
          cd2: BST.Com.current.pnode.cd,
          nm: BST.Com.current.node.nm
        };
        BST.WinLink.open(BST.WinLink.TYPE_DRIVE_LIST, param);
      });

      // report
      var rpc = this.reportSrv = new BST.ReportSrv();

      // カルテ
      var shc = this.sheetSrv = new BST.SheetSrv();

      // tree
      var trs = this.treeSrv = new BST.TreeSrv(tree);
      $(trs).on('change', function (e, param) {
        var node = BST.Com.current.node = param.node;
        var pnode = BST.Com.current.pnode = param.pnode || {};
        // var pld = _self.pl.value();
        // var kijun = pld.value;
        // if( kijun == undefined || kijun == null) kijun = 999;
        // lc.change(node.type, node.cd, BST.Com.session, _self.dp1.value(), _self.dp2.value(), kijun, pnode.cd);

        _self.updateList(999, true);

        // var _nm = node.nm;
        // try {
        //    if (_nm.length > 20) _nm = _nm.substr(0, 20) + "…";
        // } catch (e) { }
        var _nm = BST.Util.textOverflowEllipsis(node.nm, 25);
        title.text(_nm);
        icon.removeClass();
        icon.addClass(node.type);

        _self.active();// treeパネル表示
      })
        .on('report', function (e, param) {
          var node = param.node;
          var pnode = param.pnode || {};
          // var pld = _self.pl.value();
          rpc.view(pnode.cd, node.cd, BST.Com.session, node.nm, _self._now);
        });
      trs.update(BST.Com.lgData.comp);

      // 運行一覧
      var lc = this.listSrv = new BST.ListSrv(driveList);
      $(lc).on('select', function (e, param) {
        var data = param.data;
        var type = param.type;
        if (type === 'comp') {
          trs.clickItem('org', data.cd);
        } else if (type === 'org') {
          if (data.dcd) {
            trs.clickItem('driver', data.dcd);
          } else {
            trs.clickItem('org', data.cd);
          }

          // if (trs.getItem("org", data.cd)) {
          //    trs.clickItem("org", data.cd);
          // }
          // else
          //    trs.clickItem("driver", data.dcd);
        } else if (type === 'driver') {
          // if (data.cd != BST.Com.current.node.cd) {
          //    try {
          //        console.assert("Not the same tree select cd and list select cd ");
          //    } catch (e) { }
          // }
          shc.view(BST.Com.current.pnode.cd, BST.Com.current.node.cd, data.rcd, data.from, BST.Com.session);
        }
      });

      // ★動画一覧
      var mlc = this.mvlistSrv = new BST.MvListSrv(driveList);
      $(mlc).on('karute', function (e, param) {
        var data = param.data;
        // var type = param.type;
        // カルテ表示 *** パラメータは仮
        shc.view(data.cd, data.dcd, data.rcd, data.from, BST.Com.session);
      });

      // プランごとに表示できる日にちがちがうので。
      var rank = new BST.RankData();
      rank.loadAll(BST.Com.session, function (res) {
        var now;
        if (res.suc) {
          if (res.data.day && res.data.day.length > 0) {
            var d = res.data.day[res.data.day.length - 1].cd;
            now = new Date(d);
          }
          // res.data.rank = _self.sample[10];
        }
        now = _self._now = now || new Date();
        _self.createPicker(now);
      });

      $('.right-panel>#app-tree-contents>.drive-ctr>.row1 #up-btn').on('click', function () {
        _self.updateList(999, true);
      });

      // tabクリック
      $('.right-panel>#app-tree-contents>.drive-ctr>.tabs > .tabdr').on('click', function () {
        _self.changeTab(1);
        _self.updateList(999, true);
      });
      $('.right-panel>#app-tree-contents>.drive-ctr>.tabs > .tabmv').on('click', function () {
        _self.changeTab(2);
        _self.updateList(999, true);
      });

      this.changeTab(1);
    }
    BST.Util.inherits(DriveCtr, BST.BaseCtr);

    DriveCtr.prototype.changeTab = function (no) {
      this.tabNo = no;

      var tabdr = $('.right-panel>#app-tree-contents>.drive-ctr>.tabs>.tabdr');
      var tabmv = $('.right-panel>#app-tree-contents>.drive-ctr>.tabs>.tabmv');

      var titledr = $('#app-tree-contents>.drive-ctr>.row2>#title-dr');
      var titlemv = $('#app-tree-contents>.drive-ctr>.row2>#title-mv');

      var row1 = $('.right-panel>#app-tree-contents>.drive-ctr>.row1');
      var rowmv1 = $('.right-panel>#app-tree-contents>.drive-ctr>.row1-mv');

      tabdr.removeClass('tabdr-selected');
      tabmv.removeClass('tabmv-selected');

      titledr.hide();
      titlemv.hide();

      row1.hide();
      rowmv1.hide();

      if (no === 1) {
        row1.show();
        titledr.show();
        tabdr.addClass('tabdr-selected');
        this.listSrv.disp(true);
        this.mvlistSrv.disp(false);
      } else {
        rowmv1.show();
        titlemv.show();
        tabmv.addClass('tabmv-selected');
        this.listSrv.disp(false);
        this.mvlistSrv.disp(true);
      }
    };

    DriveCtr.prototype.createPicker = function (now) {
      var _self = this;
      // そのた
      var dayRange = 0;
      var monthRange = 1;

      now = now || new Date();
      var bfr = new Date(now);
      bfr.setDate(bfr.getDate() - 6);
      // var bfrmin = new Date(now);
      // bfrmin.setMonth(bfrmin.getMonth() -areaBfrMonth);
      // var bfrmax = new Date(now);
      // bfrmax.setDate(bfrmax.getDate() -areaBfrDay);

      var update1 = function () {
        var date1 = dp1.getDate();
        var date1max = new Date(date1);
        date1max.setMonth(date1max.getMonth() + monthRange);
        date1max.setDate(date1max.getDate() - 1);// -1日
        var date2 = dp2.getDate();

        // 開始日が1日が選択された場合、終了日が1か月先になるようにする。特殊処理
        if (date1.getDate() === 1) {
          dp2.setDate(date1max);
        } else if (date2.getTime() < date1.getTime()) {
          dp2.setDate(date1);
        } else if (date1max.getTime() < date2.getTime()) {
          dp2.setDate(date1max);
        }
      };
      var dp1 = this.dp1 = new BST.DatePicker($('.right-panel>#app-tree-contents>.drive-ctr>.row1>#date1')
        , {
          callbackSelected: function (text) {
            update1();
          },
          callbackClose: function (text, inst) {
            update1();
          }
        });
      dp1.setDate(bfr);
      // dp1.setMinDate(bfrmin);
      // dp1.setMaxDate(bfrmax);

      var update2 = function () {
        var date1 = dp1.getDate();
        var date1max = new Date(date1);
        date1max.setMonth(date1max.getMonth() + monthRange);
        date1max.setDate(date1max.getDate() - 1);// -1日
        var date2 = dp2.getDate();
        var date2min = new Date(date2);
        date2min.setMonth(date2min.getMonth() - monthRange);
        date2min.setDate(date2min.getDate() + 1);// +1日

        // date2の最後の日
        var date2Last = new Date(date2);
        date2Last.setDate(1);// 1/31に+1カ月すると3/2になるので(2月は28日なので、一カ月たすとオーバーして3/2になるみたい)、1/1 +1カ月 = 2/1    2/1-1日=1/31にしている
        date2Last.setMonth(date2Last.getMonth() + 1);
        date2Last.setDate(date2Last.getDate() - 1);

        if (date2.getTime() === date2Last.getTime()) {
          // date2の初日
          var date2First = new Date(date2);
          date2First.setDate(1);
          dp1.setDate(date2First);
        } else if (date2.getTime() < date1.getTime()) {
          dp1.setDate(date2);
        } else if (date1max.getTime() < date2.getTime()) {
          dp1.setDate(date2min);
        }
      };

      var dp2 = this.dp2 = new BST.DatePicker($('.right-panel>#app-tree-contents>.drive-ctr>.row1>#date2')
        , {
          callbackSelected: function (text) {
            update2();
          },
          callbackClose: function (text, inst) {
            update2();
          }
        });
      dp2.setDate(now);

      var months = [
        { label: '1', value: 1, data: '01' },
        { label: '2', value: 2, data: '02' },
        { label: '3', value: 3, data: '03' },
        { label: '4', value: 4, data: '04' },
        { label: '5', value: 5, data: '05' },
        { label: '6', value: 6, data: '06' },
        { label: '7', value: 7, data: '07' },
        { label: '8', value: 8, data: '08' },
        { label: '9', value: 9, data: '09' },
        { label: '10', value: 10, data: '10' },
        { label: '11', value: 11, data: '11' },
        { label: '12', value: 12, data: '12' }

      ];

      var year = now.getFullYear();
      var basey = $('<div>').addClass('date1').appendTo($('.right-panel>#app-tree-contents>.drive-ctr>.row1-mv > #year'));
      // this.selectMvYear = $(".right-panel>#app-tree-contents>.drive-ctr>.row1-mv >#year").addClass("sel");
      this.selectMvYear = $('<select>').addClass('sel').appendTo(basey);
      for (var i = 0; i < 10; i++) {
        var o = $('<option>').text(year).val(year).appendTo(this.selectMvYear);
        if (i === 0) {
          o.get(0).selected = true;
        }
        year--;
      }
      this.selectMvYear.on('change', function () {
        _self.updateList(999, true);
      });

      var month = now.getMonth() + 1;
      var basem = $('<div>').addClass('date1').appendTo($('.right-panel>#app-tree-contents>.drive-ctr>.row1-mv > #month'));
      // this.selectMvMonth = $(".right-panel>#app-tree-contents>.drive-ctr>.row1-mv>#month").addClass("sel");
      this.selectMvMonth = $('<select>').addClass('sel').appendTo(basem);
      for (i = 0; i < months.length; i++) {
        o = $('<option>').text(months[i].label).data('data', months[i]).val(months[i].data).appendTo(this.selectMvMonth);
        if (month === months[i].value) {
          o.get(0).selected = true;
        }
      }
      this.selectMvMonth.on('change', function () {
        _self.updateList(999, true);
      });

      // var dpmv1 = this.dpmv1 = new BST.DatePicker($(".right-panel>#app-tree-contents>.drive-ctr>.row1-mv>#date1")
      //    , {
      //        callbackSelected: function (text) {
      //            _self.updateList(999, true);
      //        }
      //        , callbackClose: function (text, inst) {
      //            _self.updateList(999, true);
      //        }
      //    });
      // dpmv1.setDate(now);
    };

    // BaseCtr関係
    DriveCtr.prototype.getPanel = function () {
      return $('#app-tree-contents');
    };

    DriveCtr.prototype.__changedPanel = function (type) {
      if (type !== TYPE) {
        this.treeSrv.clearSelect();
        // this.listSrv.clear();
      }
    };

    // BaseCtr関係以外
    DriveCtr.prototype.createPlData = function (datas) {
      var res = [
        {
          label: 'すべて',
          value: 999
        }
      ];

      if (datas) {
        for (var i = 0; i < datas.length; i++) {
          res.push({
            label: datas[i],
            value: datas[i]
          });
        }
      }

      return res;
    };

    // Main.prototype.updateList = function (uppl, _kijun) {
    DriveCtr.prototype.updateList = function () {
      if (!BST.Com.current.node || !BST.Com.current.pnode) return;

      var kijun = 999;
      var callback = null;

      // 運行一覧
      if (this.tabNo === 1) {
        this.listSrv.change(BST.Com.current.node.type, BST.Com.current.node.cd, BST.Com.session, this.dp1.value(), this.dp2.value(), kijun, BST.Com.current.pnode.cd, callback);
      } else if (this.tabNo === 2) {
        // var sps = this.dpmv1.value().split("/");
        // var ym = sps[0] + sps[1];
        var ym = this.selectMvYear.find(':selected').val() + this.selectMvMonth.find(':selected').val();
        this.mvlistSrv.change(BST.Com.current.node.type, BST.Com.current.node.cd, BST.Com.session, ym, kijun, callback);
      }

      // var _self = this;
      // var pld = this.pl.value() || {};
      // var kijun = pld.value;
      // if (kijun == undefined || kijun == null) kijun = 999;
      // if (_kijun != undefined && _kijun != null) kijun = _kijun;
      // var callback;
      // if (uppl) callback = function (res) {
      //    if (res.suc) {
      //        //_self.pl.update(_self.createPlData(res.data.kijun));
      //    }
      // }
      // this.listSrv.change(BST.Com.current.node.type, BST.Com.current.node.cd, BST.Com.session, this.dp1.value(), this.dp2.value(), kijun, BST.Com.current.pnode.cd, callback);
    };

    DriveCtr.prototype.active = function () {
      // this.dispPanel(2);
      this.dispPanel();
    };

    return DriveCtr;
  })();
})(BST || (BST = {}));
