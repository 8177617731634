var BST;
(function (BST) {
  var List = BST.List = (function () {
    function List (parent, options) {
      parent = parent || $('body');
      var opt = this.opt = options || {};
      var data = this.data = opt.data || null;
      this.callbackSelected = opt.callbackSelected;
      this.extendData = opt.extendData;

      var base = this.base = $('<div>').addClass('bst-list').appendTo(parent);
      // var table = this.table = $("<table>").appendTo(base);

      // this.update(data);

      this._useClick = false;

      // test
      // var _self = this;
      // BST.List._______index = BST.List._______index || 0;
      // $("<button>").text("text").css({ "z-index": 999, "position": "absolute", left: BST.List._______index*20 + "px" }).appendTo("body")
      // .on("click", function () {
      //    var __imgs = [];
      //    _self.base.find("img").each(function (i, elem) {
      //        var a = BST.Util.ImageToBase64(elem, "image/png");
      //        __imgs.push(a);
      //    });

      //    BST.WinLink.open(BST.WinLink.TYPE_DRIVE_LIST, __imgs);
      //    //alert(__imgs.length);
      // });
      // BST.List._______index++;
    }

    //        List.prototype.getPrintData = function () {
    //            var __imgs = [];
    //            this.base.find("img").each(function (i, elem) {
    //                var bs64 = BST.Util.ImageToBase64(elem, "image/png");
    //                __imgs.push(bs64);
    //            });

    //            return _imgs;
    //        }

    List.prototype.useClick = function (b) {
      this._useClick = b;
    };

    List.prototype.clear = function () {
      // if (this.table)
      //    this.table.empty();

      this.base.empty();
      this.table1 = null;
      this.table2 = null;

      this.rows = null;

      this.mess = null;
    };

    List.prototype.update = function (fmt, data) {
      this.clear();
      this.createHeader(fmt);
      this.createBody(fmt, data, {
        top: this.table1.outerHeight(true) + 'px'
      });

      // this.table.floatThead({
      //    scrollContainer: function ($table) {
      //        return $table.closest('.wrapper');
      //    }
      // });
    };

    List.prototype.createHeader = function (format) {
      var _self = this;

      var table = this.table1 = $('<table>').appendTo(this.base);
      var thead = $('<thead>').addClass('scroll-head');

      // header１行目
      var tr = $('<tr>').appendTo(thead);
      for (var i = 0; i < format.header1.length; i++) {
        var h = format.header1[i];
        var style = h.style || {};
        var th = $('<th>').addClass('header header1')
          .css(style)
          .attr('rowspan', style.rowspan)
          .attr('colspan', style.colspan)
          .html(h.label).appendTo(tr);
        if (i === 0) th.addClass('corner-top-left');
        if (i === format.header1.length - 1) th.addClass('corner-top-right');
      }

      // header２行目
      tr = $('<tr>').appendTo(thead);
      for (i = 0; i < format.header2.length; i++) {
        h = format.header2[i];
        style = h.style || {};
        th = $('<th>').addClass('header').css(style).html(h.label).appendTo(tr);
      }

      // sort
      if (format['sort-all-off'] !== true) {
        tr = $('<tr>').appendTo(thead);
        for (i = 0; i < format.body.length; i++) {
          var d = format.body[i];
          style = d.style || {};

          if (d.sort) {
            th = $('<th>').css('width', style.width + 'px').data('data', d).addClass('sort').appendTo(tr);
            // var dddd = th.data("data");

            th.on('click', function (e) {
              if (_self._sortSelected) {
                _self._sortSelected.removeClass('sort-selected');
              }

              _self._sortSelected = $(this);
              _self._sortSelected.addClass('sort-selected');

              // var d = e.data("data");
              var fd = $.data(this, 'data');
              var sortData = fd['sort-data'];
              var sortArrow = $.data(this, 'sort');
              if (!sortArrow) sortArrow = 0;
              sortArrow++;
              if (sortArrow > 2) sortArrow = 1;
              $.data(this, 'sort', sortArrow);

              _self.rows.sortElements(function (a, b) {
                var da = $.data(a, 'data');
                var db = $.data(b, 'data');

                a = da[sortData];
                b = db[sortData];

                var aNan = (a !== a);
                var bNan = (b !== b);

                if (sortArrow === 1) {
                  // 降順
                  if (aNan && bNan) return 0;
                  if (aNan) return -1;
                  if (bNan) return 1;
                  // ここまで来ればaもbもNaNの可能性はない
                  if (a < b) return 1;
                  if (a > b) return -1;
                  return 0;
                } else if (sortArrow === 2) {
                  // 昇順
                  if (aNan && bNan) return 0;
                  if (aNan) return 1;
                  if (bNan) return -1;
                  // ここまで来ればaもbもNaNの可能性はない
                  if (a < b) return -1;
                  if (a > b) return 1;
                  return 0;
                }

                /// / 降順
                // if (sortArrow == 1) {
                //    if (da[sortData] < db[sortData]) return 1;
                //    else return -1;
                // }
                //    // 昇順
                // else if (sortArrow == 2) {
                //    if (da[sortData] > db[sortData]) return 1;
                //    else return -1;
                // }
              });

              if (sortArrow === 1) {
                $(this).removeClass('sort-ascending-order');
              } else if (sortArrow === 2) {
                $(this).addClass('sort-ascending-order');
              }
            });
            // th.text("▼");
          } else {
            th = $('<th>').css('width', style.width + 'px').data('data', d).addClass('sort-none').appendTo(tr);
          }
        }
      }

      thead.appendTo(table);
    };

    List.prototype.createBody = function (fmt, dt, css) {
      var baseBody = $('<div>').addClass('body-base').css(css);
      var table = this.table2 = $('<table>').appendTo(baseBody);
      // var tbody = $("<tbody>").addClass("scroll-body").css({ "width": fmt["body-width"] }).appendTo(table);
      var tbody = $('<tbody>').addClass('scroll-body').appendTo(table);

      // var dds = dt.details;
      var dds = dt;
      var len = dds.length;

      for (var i = 0; i < len; i++) {
        var rowdata = dds[i];

        // データ追加
        // this.data.extendData(rowdata);
        if (this.extendData) this.extendData(rowdata);

        // 要素生成
        // for (var j = 0; j < rowdata.length; j++) {
        var tr = this.createTr(fmt.body, rowdata);
        tr.appendTo(tbody);

        // }
      }

      // for (var i = 0; i < fmt.data.length; i++) {
      //    var d = fmt.data[i];
      //    var datas = d.data;
      //    //var style = d.style || {};

      //    var tr = $("<tr>").appendTo(tbody);

      //    for (var j = 0; j < datas.length; j++) {
      //        var tr = this.createItem(datas[i]);
      //        tr.appendTo(tr);
      //    }
      // }

      // tbody.appendTo(this.table);

      baseBody.appendTo(this.base);

      // sort用にelementを取得
      this.rows = tbody.find('tr');

      // var a = tr.data("data");
      // var b = $.data(this.rows[0], "data");

      // this.rows.sort(function (aa, bb) {
      //    var c = $.data(aa, "data");
      //    return 1;
      // });
    };

    List.prototype.createTr = function (fbody, data) {
      var tr = $('<tr>').data('data', data);

      var _self = this;
      tr.on('click', function () {
        if (!_self._useClick) return;

        if (_self.callbackSelected) {
          _self.callbackSelected(data);
        }
      });

      tr.on('mouseenter', function () {
        if (!_self._useClick) return;

        $(this).children().addClass('hover');
        // $(this).children().append($("<div>").addClass("hover"));
      });

      tr.on('mouseleave', function () {
        if (!_self._useClick) return;

        $(this).children().removeClass('hover');
        // $(this).children().find(".hover").remove();
      });

      for (var i = 0; i < fbody.length; i++) {
        var fd = fbody[i].data;
        var style = fbody[i].style || {};
        var td = this.createTd(fd, data);
        td.css(style).appendTo(tr);
      }

      return tr;
    };

    List.prototype.createTd = function (fds, data) {
      var td = $('<td>');
      if (data.invalid === 9) {
        td.addClass('mask');
      }

      for (var i = 0; i < fds.length; i++) {
        var fd = fds[i];
        switch (fd.type) {
          case 'text':
            if (fd.label) {
              $('<div>').addClass('text').html(data[fd.label]).appendTo(td);
            } else if (fd.labels) {
              for (var j = 0; j < fd.labels.length; j++) {
                var lb = data[fd.labels[j]];
                if (lb) {
                  $('<div>').addClass('text').html(lb).appendTo(td);
                  break;
                }
              }
            }
            break;
          case 'date':
            if (data.invalid === 9) {
              var exc = $('<div>').addClass('exclusion').appendTo(td);
              $('<img>').attr('src', '../img/exclusion.png').appendTo(exc);
            }

            $('<div>').addClass('date').html(data[fd.label]).appendTo(td);
            break;
          case 'time':
            var t = data[fd.label] || '-';
            $('<div>').addClass('time').html((fd['label-s'] || '') + t + (fd['label-e'] || '')).appendTo(td);
            break;
          case 'image':
            // $("<img>").addClass("image").attr("src", imgurl + Math.random()).appendTo(td);
            $('<img>').addClass('image').attr('src', fd.url(data)).appendTo(td);
            // クロスドメイン画像ではcanvasからbase64に変換できないので、対応
            // http://phiary.me/html5-canvas-cors/
            // $("<img>").addClass("image").attr("crossOrigin", "anonymous").attr("src", fd.url(data)).appendTo(td);
            break;
          case 'num':
            var num;
            if (fd.filter) {
              num = fd.filter(fd, data);
            } else {
              num = Math.round(data[fd.label] * fd.decimal) / fd.decimal;
            }
            $('<div>').addClass('num').html(num).appendTo(td);
            break;
          case 'unit':
            var un = fd.label;
            if (fd.filter) {
              un = fd.filter(fd, data);
            }
            $('<div>').addClass('unit').html(un).appendTo(td);
            // $("<div>").addClass("unit").html(fd.label).appendTo(td);
            break;
          case 'custom':
            // clsが使える
            $('<div>').addClass(data.cls || '').html(fd.label).appendTo(td);
            break;
          default:
            // 何も記入しない
            // はそのままhtmlで表示
            // 改行などに使う
            td.html(fd.label);

            break;
        }
      }

      return td;
    };

    List.prototype.setMess = function (text) {
      if (!this.mess) {
        this.mess = $('<div>').addClass('mess').appendTo(this.base);
      }

      this.mess.text(text);
    };

    List.prototype.disp = function (b) {
      if (b) {
        this.base.show();
      } else {
        this.base.hide();
      }
    };

    return List;
  })();
})(BST || (BST = {}));
