var BST;
(function (BST) {
  var HomeCtr = BST.HomeCtr = (function () {
    var TYPE = 'home-ctr';

    function HomeCtr () {
      var param = {};
      BST.BaseCtr.call(this, TYPE, param);

      var _self = this;
      this.btn = $('#app-home');
      this.name = $('#app-home-contents>.home-ctr>.title-logo>.head>.name');
      this.base = $('#app-home-contents>.home-ctr>.contents');

      this.rank = new BST.RankSrv(this.base);
    }
    BST.Util.inherits(HomeCtr, BST.BaseCtr);

    // BaseCtr関係
    HomeCtr.prototype.getPanel = function () {
      return $('#app-home-contents');
    };

    HomeCtr.prototype.__changedPanel = function (type) {
      if (type === TYPE) {
        if (BST.Com.lgData.comp) {
          this.name.text(BST.Com.lgData.comp.nm);
        }
      } else {
        this.btn.removeClass('app-home-active');
      }
    };

    // HomeCtr.prototype.onChangeDisp = function (type) {
    //    if (type == 1) {
    //        if (BST.Com.lgData.comp)
    //            this.name.text(BST.Com.lgData.comp.nm);
    //    }
    // }

    // BaseCtr関係以外
    HomeCtr.prototype.active = function () {
      // this.dispPanel(1);
      this.dispPanel();
    };

    return HomeCtr;
  })();
})(BST || (BST = {}));
