var BST;
(function (BST) {
  var RankData = BST.RankData = (function () {
    function RankData () {
      this._all = null;
      this._rank = null;
    }

    // RankData.prototype.data = function (d) {
    //    if (d)
    //        return this._data = d;
    //    else
    //        return this._data;
    // }

    //* @param param.sc
    //* @param param.tyep day/week/month
    //* @param param.date       YYYY/MM/DD
    //* @param param.num       all:全件、それ以外:10件
    RankData.prototype.load = function (sc, type, date, rankCd, num, callback) {
      var url = BST.Const.csurl + 'home/rank';
      var req = {
        sc: sc,
        cd: date,
        rankType: type,
        rankCd: rankCd,
        to: num
      };

      var _self = this;

      BST.Com.post(url, req, function (res) {
        if (res.suc) {
          _self._rank = res.data;
        } else {
          _self._rank = null;
        }

        callback(res);
      });
    };
    // RankData.prototype.load = function (sc, type, date, num, callback) {
    //    var url = url = BST.Const.csurl + "home/rank"
    //    var req = {
    //        sc: sc
    //        , cd: date
    //        , rankType: type
    //        , to: num
    //    };

    //    var _self = this;

    //    $.ajax({
    //        type: "POST"
    //        , datatype: "json"
    //        , data: JSON.stringify(req)
    //        , url: url
    //        , contentType: "application/json; charset=utf-8"
    //    }).then(function (data) {
    //        _self._rank = data;
    //        if (callback) {
    //            //var res = _self.createResult(true, _self._data);
    //            var res = BST.Com.createAjaxResult(true, _self._rank);
    //            callback(res);
    //        }

    //    }).fail(function (err) {
    //        //_self._data = null;
    //        if (callback) {
    //            //var res = _self.createResult(false, err);
    //            var res = BST.Com.createAjaxResult(false, err);
    //            callback(res);
    //        }
    //        console.log('rank load data error:' + err);
    //    }).always(function () {
    //    });
    // }

    //* @param param.sc
    RankData.prototype.loadAll = function (sc, callback) {
      var url = BST.Const.csurl + 'home/all';
      var req = {
        sc: sc
      };

      var _self = this;

      BST.Com.post(url, req, function (res) {
        if (res.suc) {
          if (res.data.myRanking === 1) {
            // 先頭に追加
            res.data.myRankingData = { nm: 'マイランキング', cd: -1 };
          }
          _self._all = res.data;
        } else {
          _self._all = null;
        }

        callback(res);
      });
    };

    /*
    RankData.prototype.loadAll = function (sc, callback) {
      var url = BST.Const.csurl + 'home/all';
      var req = {
        sc: sc
      };

      var _self = this;

      $.ajax({
        type: 'POST',
        datatype: 'json',
        data: JSON.stringify(req),
        url: url,
        contentType: 'application/json; charset=utf-8'
      }).then(function (data) {
        _self._all = data;
        if (callback) {
          // var res = _self.createResult(true, _self._data);
          var res = BST.Com.createAjaxResult(true, _self._all);
          callback(res);
        }
      }).fail(function (err) {
        // _self._data = null;
        if (callback) {
          // var res = _self.createResult(false, err);
          var res = BST.Com.createAjaxResult(false, err);
          callback(res);
        }
        console.log('rank load all  data error:' + err);
      }).always(function () {
      });
    };
*/

    return RankData;
  })();
})(BST || (BST = {}));
