var BST;
(function (BST) {
  var SettingDialogOrgEdit = BST.SettingDialogOrgEdit = (function () {
    function SettingDialogOrgEdit () {
      // this.base;
      this.labels = ['nm', 'nm2', 'nm3', 'nm4'];
    }

    SettingDialogOrgEdit.prototype.create = function () {
      var _self = this;

      this.bg = $('<div>').addClass('bst-setting-dialog-org-edit-bg').appendTo('body').hide();
      var base = this.base = $('<div>').addClass('bst-setting-dialog-org-edit').appendTo('body').hide();
      var panel = $('<div>').addClass('panel').appendTo(base);
      this.title = $('<div>').addClass('title').text('タイトル').appendTo(panel);
      $('<div>').addClass('ms').text('※*は入力必須項目です。').appendTo(panel);
      var row1 = $('<div>').addClass('row').appendTo(panel);
      $('<div>').addClass('label').text('拠点名').appendTo(row1);
      $('<span>').addClass('mark').text('*').appendTo(row1);
      // this.input1 = $("<input>").addClass("ip1").attr("maxlength", 42).appendTo(row1)
      // .on("change", function () {
      //    _self.change();
      // });
      this.inputs = [];
      for (var i = 0; i < this.labels.length; i++) {
        var input = $('<input>').addClass('ip1').attr('maxlength', 10).appendTo(row1)
          .on('change', function () {
            _self.change();
          });
        this.inputs.push(input);
      }

      var row2 = $('<div>').addClass('btns').appendTo(panel);
      var btn1 = this.btn1 = $('<button>').addClass('ok').text('OK').appendTo(row2);
      var btn2 = $('<button>').addClass('cancel').text('キャンセル').appendTo(row2);

      btn1.on('click', function () {
        if (_self.check(_self._data) === false) return;

        try {
          if (_self._callback1) {
            _self._callback1(_self._data);
          }
        } catch (e) {
          console.log(e);
        }
      });
      btn2.on('click', function () {
        try {
          if (_self._callback2) {
            _self._callback2();
          }
        } catch (e) {
          console.log(e);
        }
      });
    };
    SettingDialogOrgEdit.prototype.setData = function (data) {
      // this._data = {
      //    sl1: sl1
      //    , sl2: sl2
      //    , sl3: sl3
      //    , data: data
      // };
      this._data = data;

      var view = this.base;
      if (view) {
        // ドライバー名
        // this.input1.val(data.nm);
        for (var i = 0; i < this.labels.length; i++) {
          this.inputs[i].val(data[this.labels[i]]);
        }
      }
    };

    SettingDialogOrgEdit.prototype.change = function () {
      try {
        // this._data.nm = this.input1.val();
        for (var i = 0; i < this.labels.length; i++) {
          this._data[this.labels[i]] = this.inputs[i].val();
        }
      } catch (e) {
        console.log(e);
      }
    };
    SettingDialogOrgEdit.prototype.check = function (data) {
      var err = '';
      // 空白
      if (BST.Util.isBlank(data.nm)) {
        err = this.addText(err, '※拠点名を入力して下さい。');
      }

      var on = false;
      for (var i = this.labels.length - 1; i >= 0; i--) {
        if (i === 0) continue; // 1つめは入力チェックがはいっているからここではチェックしない

        var nm = data[this.labels[i]];
        if (nm === '' && on) {
          err = this.addText(err, '※拠点名は左詰めで入力して下さい。');
          break;
        }

        if (nm !== '' && !on) {
          on = true;
        }
      }

      if (err === '') return true;

      BST.Com.dialogLErr(err);
      return false;
    };

    SettingDialogOrgEdit.prototype.type = function (type) {
      if (!this.base) this.create();

      if (type === 1) {
        // new
        this.title.text('新規登録');
        this.btn1.text('登録');
      } else if (type === 2) {
        // edit
        this.title.text('編集');
        this.btn1.text('OK');
      }
    };
    SettingDialogOrgEdit.prototype.open = function (data, callback1, callback2, type) {
      if (!this.base) this.create();

      this.type(type);

      this._callback1 = callback1;
      this._callback2 = callback2;

      this.setData(data);

      this.disp(true);
    };

    SettingDialogOrgEdit.prototype.close = function (type) {
      var view = this.base;
      if (view) {
        this._callback1 = null;
        this._callback2 = null;
        this._data = null;
      }

      this.disp(false);
    };

    SettingDialogOrgEdit.prototype.disp = function (b) {
      if (!this.base) {
        this.create();
      }
      if (b) {
        this.bg.show();
        this.base.show();
      } else {
        this.bg.hide();
        this.base.hide();
      }
    };

    // SettingDialogOrgEdit.prototype._text = function (text) {
    //    if (!this.base)
    //        this.create();
    //    this.text.html(text);
    // }
    SettingDialogOrgEdit.prototype.addText = function (str, add) {
      if (str !== '') str += '<br>';

      str += add;

      return str;
    };

    return SettingDialogOrgEdit;
  })();
})(BST || (BST = {}));
