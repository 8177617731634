var BST;
(function (BST) {
  var SettingDialogUserInfo = BST.SettingDialogUserInfo = (function () {
    function SettingDialogUserInfo () {
    }

    SettingDialogUserInfo.prototype.create = function () {
      var _self = this;

      this.bg = $('<div>').addClass('bst-setting-dialog-user-info-bg').appendTo('body').hide();
      var base = this.base = $('<div>').addClass('bst-setting-dialog-user-info').appendTo('body').hide();
      var panel = this.panel = $('<div>').addClass('panel').appendTo(base);
      this.title = $('<div>').addClass('title').text('以下の内容で登録されました。').appendTo(panel);
      this.title2 = $('<div>').addClass('title').text('').appendTo(panel);

      // var row0 = $("<div>").addClass("row").appendTo(panel);
      // $("<span>").addClass("label").text("企業コード：").appendTo(row0);
      // this.text0 = $("<span>").addClass("text").appendTo(row0);

      var row1 = $('<div>').addClass('row').appendTo(panel);
      $('<span>').addClass('label').text('ログインID：').appendTo(row1);
      this.text1 = $('<span>').addClass('text').appendTo(row1);

      var row3 = $('<div>').addClass('row').appendTo(panel);
      $('<span>').addClass('label').text('ユーザー名：').appendTo(row3);
      this.text3 = $('<span>').addClass('text').appendTo(row3);

      var row4 = $('<div>').addClass('row').appendTo(panel);
      $('<span>').addClass('label').addClass('pass').text('パスワード：').appendTo(row4);
      this.text4 = $('<span>').addClass('text').addClass('pass-text').appendTo(row4);

      var row5 = $('<div>').addClass('row').addClass('row4').appendTo(panel);
      $('<span>').addClass('text').addClass('pass2').html('＊パスワードはこのダイアログを閉じると<br>見ることができなくなるので、記録を残してください。').appendTo(row5);

      // かいらん
      var waku1 = this.waku1 = $('<div>').appendTo(panel);
      // var row6 = $("<div>").addClass("row").appendTo(panel);
      // $("<span>").addClass("label").text("閲覧可能な拠点：").appendTo(row6);

      var row7 = $('<div>').addClass('row').addClass('row2').appendTo(waku1);

      var li = $('<div>').addClass('list-label').appendTo(row7);
      $('<div>').addClass('item').text('閲覧可能な拠点：').appendTo(li);
      // var sp = $("<span>").addClass("label").appendTo(row7);
      // $("<div>").text("閲覧可能な拠点：").appendTo(sp);
      // $("<div>").text("　").appendTo(sp);
      // $("<div>").text("　").appendTo(sp);
      // $("<div>").text("　").appendTo(sp);
      // $("<div>").text("　").appendTo(sp);

      // var a = row6.height();
      // row7.css({ "margin-top": row6.height() + "px" });
      this.list1 = $('<div>').addClass('list').appendTo(row7);

      // var row8 = $("<div>").addClass("row").appendTo(panel);
      // $("<span>").addClass("label").text("権限付与：").appendTo(row8);
      // $("<span>").addClass("text").appendTo(row8);

      // var row9 = $("<div>").addClass("row").addClass("row3").appendTo(waku1);
      var row9 = $('<div>').addClass('row').appendTo(waku1);
      var li2 = $('<div>').addClass('list-label').addClass('list-label-wrap').appendTo(row9);
      $('<div>').addClass('item').text('権限付与：').appendTo(li2);
      this.list2 = $('<div>').addClass('list').addClass('list-wrap').appendTo(row9);

      // ドライバー
      var waku2 = this.waku2 = $('<div>').appendTo(panel);

      var row10 = $('<div>').addClass('row').appendTo(waku2);
      $('<span>').addClass('label').text('所属拠点：').appendTo(row10);
      this.text5 = $('<span>').addClass('text').appendTo(row10);

      var row2 = $('<div>').addClass('row').appendTo(waku2);
      $('<span>').addClass('label').text('ドライバー：').appendTo(row2);
      this.text2 = $('<span>').addClass('text').appendTo(row2);

      var row11 = $('<div>').addClass('btns').appendTo(panel);
      var btn1 = this.btn1 = $('<button>').addClass('ok').text('OK').appendTo(row11);

      btn1.on('click', function () {
        try {
          if (_self._callback1) {
            _self._callback1(_self._data);
          }
        } catch (e) {
          console.log(e);
        }
        _self.close();
      });
    };

    SettingDialogUserInfo.prototype.setData = function (sl1, sl2, data) {
      var _self = this;

      this._data = data;

      var view = this.base;
      if (view) {
        // 企業コード
        // this.text0.text(data.ccd);

        // ID
        this.text1.text(data.cd);

        // ドライバーにひもづくユーザ
        for (var i = 0; i < sl2.length; i++) {
          if (data.roleDriver === sl2[i].cd) {
            this.text2.text(sl2[i].nm);
            break;
          }
        }

        // ユーザー名
        this.text3.text(data.nm);

        // パスワード
        this.text4.text(data.emsg);

        // 所属拠点
        this.list1.empty();

        var orgmap = {};
        for (i = 0; i < sl1.length; i++) {
          orgmap[sl1[i].cd] = sl1[i];
        }
        for (i = 0; i < data.roleCloud.length; i++) {
          var d = orgmap[data.roleCloud[i]];
          var item = $('<div>').addClass('item').appendTo(this.list1);
          var name = '-';
          if (d && !d.nm) {
            name = '';
          } else if (d) {
            name = d.nm;
          }
          $('<span>').addClass('name').text('・' + name).appendTo(item);
        }

        this.list2.empty();
        // 管理者
        if (data.roleAdmin === 1) {
          item = $('<div>').addClass('item').appendTo(this.list2);
          $('<span>').addClass('name').text('管理者').appendTo(item);
        }

        // ビューアーからアップロード
        // if (data.roleView == 1){
        //    var item = $("<div>").addClass("item").appendTo(this.list2);
        //    $("<span>").addClass("name").text("ビューアからのデータアップロード").appendTo(item);
        // }

        if (this.kengen(data) === 0) {
          this.title2.text('（閲覧者ユーザー）');
          this.waku1.show();
          this.waku2.hide();
        } else if (this.kengen(data) === 1) {
          this.title2.text('（ドライバーユーザー）');
          this.waku1.hide();
          this.waku2.show();
        } else if (this.kengen(data) === 2) {
          this.title2.text('（データアップロード用ユーザー）');
          this.waku1.hide();
          this.waku2.hide();
        } else {
          this.title2.text('（閲覧者ユーザー）');
          this.waku1.show();
          this.waku2.hide();
        }
      }
    };

    SettingDialogUserInfo.prototype.open = function (orgs, drvs, data, callback1) {
      if (!this.base) this.create();

      this._callback1 = callback1;

      this.setData(orgs, drvs, data);

      this.disp(true);
    };

    SettingDialogUserInfo.prototype.close = function (type) {
      var view = this.base;
      if (view) {
        this._callback1 = null;
        this._callback2 = null;
        this._data = null;
      }

      this.disp(false);
    };

    SettingDialogUserInfo.prototype.disp = function (b) {
      if (!this.base) {
        this.create();
      }
      if (b) {
        this.bg.show();
        this.base.show();
      } else {
        this.bg.hide();
        this.base.hide();
      }
    };

    SettingDialogUserInfo.prototype.kengen = function (data) {
      if (data.roleCloud.length > 0 || data.roleAdmin === 1) {
        return 0;
      } else if (data.roleDriver !== '' && data.roleDriver !== undefined && data.roleDriver !== null) {
        return 1;
      } else if (data.roleView === 1) {
        return 2;
      }

      return -1;
    };

    return SettingDialogUserInfo;
  })();
})(BST || (BST = {}));
