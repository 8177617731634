var BST;
(function (BST) {
  var ListData = BST.ListData = (function () {
    ListData.prototype.format =
    {
      comp: {
        width: '857px',
        'body-width': '855px',
        header1: [
          {
            label: '拠点名/氏名',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '86px'
            }
          },
          //, {
          //    "label": "氏名"
          //    , "style": {
          //        "rowspan": "2"
          //        , "colspan": "1"
          //        , "width": "84px"
          //    }
          // }
          {
            label: '運行日付',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '102px'
            }
          },
          {
            label: '総記録時間</br>（走行時間）',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '100px'
            }
          },
          {
            label: '指導ポイント</br>グラフ',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '106px'
            }
          },
          {
            label: '1時間</br>あたり</br>★個数',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '44px'
            }
          },
          {
            label: '評価</br>基準G',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '44px'
            }
          },
          {
            label: '平均</br>評価点',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '50px'
            }
          },
          {
            label: '最高</br>速度',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '54px'
            }
          },
          {
            label: '最大加速度',
            style: {
              rowspan: '1',
              colspan: '2',
              width: '89px'
            }
          },
          {
            label: '総走行距離',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '70px'
            }
          }

        ],
        header2: [
          {
            label: '前後',
            style: {
              width: '44px'
            }
          },
          {
            label: '左右',
            style: {
              width: '44px'
            }
          }
        ],
        body: [
          // 部署
          {
            data: [
              {
                type: 'text',
                label: 'nm'
                //, "cls":"text"
              }
            ],
            // "data-label": "affiliation"
            style: {
              width: '86px',
              'background-color': '#f5e79f'
            },
            sort: false,
            'sort-data': ''
          },
          // 氏名
          //, {
          //    "data":[
          //        {
          //            "type":"text"
          //            ,"label":"name"
          //            //,"cls":"text"
          //        }
          //    ]
          //    //"data-label": "name"
          //    , "style": {
          //        "width": "84px"
          //    }
          // }
          // 運航日
          {
            data: [
              {
                type: 'date',
                label: 'from'
                //, "cls":"date"
              },
              {
                type: 'custom',
                label: '～',
                cls: 'date'
              },
              {
                type: 'date',
                label: 'to'
                //, "cls":"date"
              }

            ],
            // "data-label": "operationDate"
            style: {
              width: '102px'
            },
            sort: false,
            'sort-data': ''
          },
          {
            data: [
              {
                type: 'time',
                label: 'termAll'
                //, "cls":"time"
              },
              {
                type: 'time',
                label: 'termGps',
                //, "cls":"time"
                'label-s': '(',
                'label-e': ')'
              }

            ],
            // "data-label": "totalRecordingTime"
            style: {
              width: '100px'
            },
            sort: true,
            'sort-data': 'termAll'

          },
          {
            data: [
              {
                type: 'image',
                // URLとパラメータのオブジェクト名
                //, "url": BST.Const.imgurl + "/taiko/org"
                //, "param": ["from", "to", "kijun", "orgCode", "width", "height", "sessCode"]
                url: function (data) {
                  var url = BST.Const.imgurl + 'taiko/org' +
                    '/' + BST.Util.replaceAll(data.from, '/', '-') + '/' + BST.Util.replaceAll(data.to, '/', '-') + '/' + 999 +
                    '/' + BST.Util.percentEncode(data.cd) + '/' + 102 + '/' + 102 + '/' + BST.Com.session;

                  return url;
                }

                // "/taiko/org/{from}/{to}/{kijun}/{orgCode}/{width}/{height}/{sessCode}"

                //, "cls":"time"
              }
            ],
            // "data-label": "InstructionPoint"
            style: {
              width: '106px'
            },
            sort: false,
            'sort-data': ''
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1 小数点第一位
                label: 'starNum'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: '個/h'
                //, "cls":"date"
              }
            ],
            // "data-label": "numberPerHour"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'starNum'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 100, // 2 小数点第一位
                label: 'saiten'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'G'
                //, "cls":"date"
              }
            ],
            // "data-label": "evaluationCriteriaG"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'saiten'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1
                label: 'point',
                //, "cls":"date"
                filter: function (fd, data) {
                  var num = data[fd.label];
                  if (num === -1) {
                    return '0';
                  }

                  return num;
                }
              },
              {
                type: 'unit',
                label: '点'
                //, "cls":"date"
              }
            ],
            // "data-label": "averageScore"
            style: {
              width: '50px'
            },
            sort: true,
            'sort-data': 'point'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1
                label: 'maxV'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'km/h'
                //, "cls":"date"
              }
            ],
            // "data-label": "maxSpeed"
            style: {
              width: '54px'
            },
            sort: true,
            'sort-data': 'maxV'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 100, // 2
                label: 'maxGY'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'G'
                //, "cls":"date"
              }
            ],
            // "data-label": "maxABfrAfr"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'maxGY'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 100, // 2
                label: 'maxGX'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'G'
                //, "cls":"date"
              }
            ],
            // "data-label": "maxALeftRight"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'maxGX'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1
                label: 'dist',
                //, "cls":"date"
                filter: function (fd, data) {
                  // 1kmより小さい場合はmにする
                  var num = data.distm;
                  if (num < 1000) {
                    num = data.distm;
                  } else {
                    num = Math.round(data[fd.label] * fd.decimal) / fd.decimal;
                    if ((data[fd.label] % 1) === 0) {
                      num = num + '.0';
                    }
                  }

                  return num;

                  // var num = Math.round(data[fd.label] * fd.decimal) / fd.decimal;
                  // if ((data[fd.label] % 1) == 0)
                  //    num = num + ".0";

                  // return num;
                }
              },
              {
                type: 'unit',
                label: 'km',
                //, "cls":"date"
                filter: function (fd, data) {
                  // 1kmより小さい場合はmにする
                  var num = data.distm;
                  if (num < 1000) {
                    return 'm';
                  }

                  return fd.label;
                }
              }
            ],
            // "data-label": "totalGpsMileage"
            style: {
              width: '70px'
            },
            sort: true,
            'sort-data': 'distm'
          }
        ]
      },
      org: {
        width: '857px',
        'body-width': '855px',
        header1: [
          {
            label: '拠点名/氏名',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '84px'
            }
          },
          {
            label: '運行日付',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '102px'
            }
          },
          {
            label: '総記録時間</br>（走行時間）',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '100px'
            }
          },
          {
            label: '指導ポイント</br>グラフ',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '106px'
            }
          },
          {
            label: '1時間</br>あたり</br>★個数',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '44px'
            }
          },
          {
            label: '評価</br>基準G',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '44px'
            }
          },
          {
            label: '平均</br>評価点',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '50px'
            }
          },
          {
            label: '最高</br>速度',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '54px'
            }
          },
          {
            label: '最大加速度',
            style: {
              rowspan: '1',
              colspan: '2',
              width: '89px'
            }
          },
          {
            label: '総走行距離',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '70px'
            }
          }

        ],
        header2: [
          {
            label: '前後',
            style: {
              width: '44px'
            }
          },
          {
            label: '左右',
            style: {
              width: '44px'
            }
          }
        ],
        body: [
          // 氏名
          {
            data: [
              {
                type: 'text',
                //, "label": "dnm"
                labels: [
                  'dnm',
                  'nm'
                ]
                //, "cls":"text"
              }
            ],
            // "data-label": "name"
            style: {
              width: '84px',
              'background-color': '#f5e79f'
            },
            sort: false,
            'sort-data': ''
          },
          // 運航日
          {
            data: [
              {
                type: 'date',
                label: 'from'
                //, "cls":"date"
              },
              {
                type: 'custom',
                label: '～',
                cls: 'date'
              },
              {
                type: 'date',
                label: 'to'
                //, "cls":"date"
              }

            ],
            // "data-label": "operationDate"
            style: {
              width: '102px'
            },
            sort: false,
            'sort-data': ''
          },
          {
            data: [
              {
                type: 'time',
                label: 'termAll'
                //, "cls":"time"
              },
              {
                type: 'time',
                label: 'termGps',
                //, "cls":"time"
                'label-s': '(',
                'label-e': ')'
              }

            ],
            // "data-label": "totalRecordingTime"
            style: {
              width: '100px'
            },
            sort: true,
            'sort-data': 'termAll'

          },
          {
            data: [
              {
                type: 'image',
                // URLとパラメータのオブジェクト名
                //, "url": ""
                //, "param":["cd"]
                //, "cls":"time"
                // "/taiko/driver/{from}/{to}/{kijun}/{orgCode}/{driverCode}/{width}/{height}/{sessCode}",
                url: function (data) {
                  var url = null;

                  if (data.dnm) {
                    url = BST.Const.imgurl + 'taiko/driver' +
                      '/' + BST.Util.replaceAll(data.from, '/', '-') + '/' + BST.Util.replaceAll(data.to, '/', '-') + '/' + 999 +
                      '/' + BST.Util.percentEncode(data.cd) + '/' + data.dcd + '/' + 102 + '/' + 102 + '/' + BST.Com.session;
                  } else {
                    url = BST.Const.imgurl + 'taiko/org' +
                      '/' + BST.Util.replaceAll(data.from, '/', '-') + '/' + BST.Util.replaceAll(data.to, '/', '-') + '/' + 999 +
                      '/' + BST.Util.percentEncode(data.cd) + '/' + 102 + '/' + 102 + '/' + BST.Com.session;
                  }

                  return url;
                }
              }
            ],
            // "data-label": "InstructionPoint"
            style: {
              width: '106px'
            },
            sort: false,
            'sort-data': ''
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1 小数点第一位
                label: 'starNum'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: '個/h'
                //, "cls":"date"
              }
            ],
            // "data-label": "numberPerHour"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'starNum'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 100, // 2 小数点第一位
                label: 'saiten'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'G'
                //, "cls":"date"
              }
            ],
            // "data-label": "evaluationCriteriaG"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'saiten'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1
                label: 'point',
                //, "cls":"date"
                filter: function (fd, data) {
                  var num = data[fd.label];
                  if (num === -1) {
                    return '0';
                  }

                  return num;
                }
              },
              {
                type: 'unit',
                label: '点'
                //, "cls":"date"
              }
            ],
            // "data-label": "averageScore"
            style: {
              width: '50px'
            },
            sort: true,
            'sort-data': 'point'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1
                label: 'maxV'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'km/h'
                //, "cls":"date"
              }
            ],
            // "data-label": "maxSpeed"
            style: {
              width: '54px'
            },
            sort: true,
            'sort-data': 'maxV'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 100, // 2
                label: 'maxGY'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'G'
                //, "cls":"date"
              }
            ],
            // "data-label": "maxABfrAfr"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'maxGY'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 100, // 2
                label: 'maxGX'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'G'
                //, "cls":"date"
              }
            ],
            // "data-label": "maxALeftRight"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'maxGX'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1
                label: 'dist',
                //, "cls":"date"
                filter: function (fd, data) {
                  // 1kmより小さい場合はmにする
                  var num = data.distm;
                  if (num < 1000) {
                    num = data.distm;
                  } else {
                    num = Math.round(data[fd.label] * fd.decimal) / fd.decimal;
                    if ((data[fd.label] % 1) === 0) {
                      num = num + '.0';
                    }
                  }

                  return num;

                  // var num = Math.round(data[fd.label] * fd.decimal) / fd.decimal;
                  // if ((data[fd.label] % 1) == 0)
                  //    num = num + ".0";

                  // return num;
                }
              },
              {
                type: 'unit',
                label: 'km',
                //, "cls":"date"
                filter: function (fd, data) {
                  // 1kmより小さい場合はmにする
                  var num = data.distm;
                  if (num < 1000) {
                    return 'm';
                  }

                  return fd.label;
                }
              }
            ],
            // "data-label": "totalGpsMileage"
            style: {
              width: '70px'
            },
            sort: true,
            'sort-data': 'distm'
          }
        ]
      },
      'driver-h': {
        width: '857px',
        'body-width': '855px',
        'sort-all-off': true,
        header1: [
          {
            label: '運行日付',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '102px'
            }
          },
          {
            label: '出発時間',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '56px'
            }
          },
          {
            label: '総記録時間</br>（走行時間）',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '100px'
            }
          },
          {
            label: '指導ポイント</br>グラフ',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '106px'
            }
          },
          {
            label: '1時間</br>あたり</br>★個数',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '44px'
            }
          },
          {
            label: '評価</br>基準G',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '44px'
            }
          },
          {
            label: '平均</br>評価点',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '50px'
            }
          },
          {
            label: '平均</br>速度',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '54px'
            }
          },
          {
            label: '最高</br>速度',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '54px'
            }
          },
          {
            label: '最大加速度',
            style: {
              rowspan: '1',
              colspan: '2',
              width: '89px'
            }
          },
          {
            label: '総走行距離',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '70px'
            }
          }

        ],
        header2: [
          {
            label: '前後',
            style: {
              width: '44px'
            }
          },
          {
            label: '左右',
            style: {
              width: '44px'
            }
          }
        ],
        body: [
          // 運航日
          {
            data: [
              {
                type: 'date',
                label: 'from'
                //, "cls":"date"
              },
              {
                type: 'custom',
                label: '～',
                cls: 'date'
              },
              {
                type: 'date',
                label: 'to'
                //, "cls":"date"
              }

            ],
            // "data-label": "operationDate"
            style: {
              width: '102px'
            },
            sort: false,
            'sort-data': ''
          },
          {
            data: [
              {
                type: 'time',
                label: 'time'
                //, "cls":"time"
              }

            ],
            // "data-label": "totalRecordingTime"
            style: {
              width: '56px'
            },
            sort: true,
            'sort-data': 'time'

          },
          {
            data: [
              {
                type: 'time',
                label: 'termAll'
                //, "cls":"time"
              },
              {
                type: 'time',
                label: 'termGps',
                //, "cls":"time"
                'label-s': '(',
                'label-e': ')'
              }

            ],
            // "data-label": "totalRecordingTime"
            style: {
              width: '100px'
            },
            sort: true,
            'sort-data': 'termAll'

          },
          {
            data: [
              {
                type: 'image',
                // URLとパラメータのオブジェクト名
                //, "url": ""
                //, "param": ["cd"]
                //, "cls":"time"
                // "/taiko/driver/{from}/{to}/{kijun}/{orgCode}/{driverCode}/{width}/{height}/{sessCode}"
                url: function (data) {
                  var url = BST.Const.imgurl + 'taiko/driver' +
                    '/' + BST.Util.replaceAll(data.from, '/', '-') + '/' + BST.Util.replaceAll(data.to, '/', '-') + '/' + 999 +
                    '/' + BST.Util.percentEncode(data.cd) + '/' + data.dcd + '/' + 102 + '/' + 102 + '/' + BST.Com.session;

                  return url;
                }
              }
            ],
            // "data-label": "InstructionPoint"
            style: {
              width: '106px'
            },
            sort: false,
            'sort-data': ''
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1 小数点第一位
                label: 'starNum'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: '個/h'
                //, "cls":"date"
              }
            ],
            // "data-label": "numberPerHour"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'starNum'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 100, // 2 小数点第一位
                label: 'saiten'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'G'
                //, "cls":"date"
              }
            ],
            // "data-label": "evaluationCriteriaG"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'saiten'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1
                label: 'point',
                //, "cls":"date"
                filter: function (fd, data) {
                  var num = data[fd.label];
                  if (num === -1) {
                    return '0';
                  }

                  return num;
                }
              },
              {
                type: 'unit',
                label: '点'
                //, "cls":"date"
              }
            ],
            // "data-label": "averageScore"
            style: {
              width: '50px'
            },
            sort: true,
            'sort-data': 'point'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1
                label: 'aveV'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'km/h'
                //, "cls":"date"
              }
            ],
            // "data-label": "maxSpeed"
            style: {
              width: '54px'
            },
            sort: true,
            'sort-data': 'aveV'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1
                label: 'maxV'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'km/h'
                //, "cls":"date"
              }
            ],
            // "data-label": "maxSpeed"
            style: {
              width: '54px'
            },
            sort: true,
            'sort-data': 'maxV'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 100, // 2
                label: 'maxGY'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'G'
                //, "cls":"date"
              }
            ],
            // "data-label": "maxABfrAfr"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'maxGY'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 100, // 2
                label: 'maxGX'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'G'
                //, "cls":"date"
              }
            ],
            // "data-label": "maxALeftRight"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'maxGX'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1
                label: 'dist',
                //, "cls":"date"
                filter: function (fd, data) {
                  // 1kmより小さい場合はmにする
                  var num = data.distm;
                  if (num < 1000) {
                    num = data.distm;
                  } else {
                    num = Math.round(data[fd.label] * fd.decimal) / fd.decimal;
                    if ((data[fd.label] % 1) === 0) {
                      num = num + '.0';
                    }
                  }

                  return num;

                  // var num = Math.round(data[fd.label] * fd.decimal) / fd.decimal;
                  // if ((data[fd.label] % 1) == 0)
                  //    num = num + ".0";

                  // return num;
                }
              },
              {
                type: 'unit',
                label: 'km',
                //, "cls":"date"
                filter: function (fd, data) {
                  // 1kmより小さい場合はmにする
                  var num = data.distm;
                  if (num < 1000) {
                    return 'm';
                  }

                  return fd.label;
                }
              }
            ],
            // "data-label": "totalGpsMileage"
            style: {
              width: '70px'
            },
            sort: true,
            'sort-data': 'distm'
          }
        ]
      },
      driver: {
        width: '857px',
        'body-width': '855px',
        header1: [
          {
            label: '運行日付',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '102px'
            }
          },
          {
            label: '出発時間',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '56px'
            }
          },
          {
            label: '総記録時間</br>（走行時間）',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '100px'
            }
          },
          {
            label: '加速度分布図',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '106px'
            }
          },
          {
            label: '★個数',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '44px'
            }
          },
          {
            label: '評価</br>基準G',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '44px'
            }
          },
          {
            label: '評価点',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '50px'
            }
          },
          {
            label: '平均</br>速度',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '54px'
            }
          },
          {
            label: '最高</br>速度',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '54px'
            }
          },
          {
            label: '最大加速度',
            style: {
              rowspan: '1',
              colspan: '2',
              width: '89px'
            }
          },
          {
            label: '走行距離',
            style: {
              rowspan: '2',
              colspan: '1',
              width: '70px'
            }
          }

        ],
        header2: [
          {
            label: '前後',
            style: {
              width: '44px'
            }
          },
          {
            label: '左右',
            style: {
              width: '44px'
            }
          }
        ],
        body: [
          // 氏名
          // {
          //    "data": [
          //        {
          //            "type": "text"
          //            , "label": "nm"
          //            //,"cls":"text"
          //        }
          //    ]
          //    //"data-label": "name"
          //    , "style": {
          //        "width": "84px"
          //        , "background-color": "#f5e79f"
          //    }
          //   , "sort": false
          //    , "sort-data": ""
          // }
          // 運航日
          {
            data: [
              {
                type: 'date',
                label: 'from'
                //, "cls":"date"
              }
              //, {
              //    "type": "custom"
              //    , "label": "～"
              //    , "cls": "date"
              // }
              //, {
              //    "type": "date"
              //    , "label": "to"
              //    //,"cls":"date"
              // }

            ],
            // "data-label": "operationDate"
            style: {
              width: '102px'
            },
            sort: false,
            'sort-data': ''
          },
          {
            data: [
              {
                type: 'time',
                label: 'time'
                //, "cls":"time"
              }

            ],
            // "data-label": "totalRecordingTime"
            style: {
              width: '56px'
            },
            sort: true,
            'sort-data': 'fromTime'

          },
          {
            data: [
              {
                type: 'time',
                label: 'termAll'
                //, "cls":"time"
              },
              {
                type: 'time',
                label: 'termGps',
                //, "cls":"time"
                'label-s': '(',
                'label-e': ')'
              }

            ],
            // "data-label": "totalRecordingTime"
            style: {
              width: '100px'
            },
            sort: true,
            'sort-data': 'termAll'

          },
          {
            data: [
              {
                type: 'image',
                // URLとパラメータのオブジェクト名
                //, "url": ""
                //, "param": ["cd"]
                //, "cls":"time"
                /// 2d/{from}/{orgCode}/{driverCode}/{routeCode}/{width}/{height}/{sessCode}
                url: function (data) {
                  var url = BST.Const.imgurl + '2d' +
                    '/' + BST.Util.replaceAll(data.from, '/', '-') +
                    '/' + BST.Util.percentEncode(data.cd) + '/' + data.dcd + '/' + data.rcd + '/' + 102 + '/' + 102 + '/' + BST.Com.session;

                  return url;
                }

              }
            ],
            // "data-label": "InstructionPoint"
            style: {
              width: '106px'
            },
            sort: false,
            'sort-data': ''
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1 小数点第一位
                label: 'starNum'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: '個'
                //, "cls":"date"
              }
            ],
            // "data-label": "numberPerHour"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'starNum'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 100, // 2 小数点第一位
                label: 'saiten'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'G'
                //, "cls":"date"
              }
            ],
            // "data-label": "evaluationCriteriaG"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'saiten'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1
                label: 'point',
                //, "cls":"date"
                filter: function (fd, data) {
                  var num = data[fd.label];
                  if (num === -1) {
                    return '0';
                  }

                  return num;
                }
              },
              {
                type: 'unit',
                label: '点'
                //, "cls":"date"
              }
            ],
            // "data-label": "averageScore"
            style: {
              width: '50px'
            },
            sort: true,
            'sort-data': 'point'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1
                label: 'aveV'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'km/h'
                //, "cls":"date"
              }
            ],
            // "data-label": "maxSpeed"
            style: {
              width: '54px'
            },
            sort: true,
            'sort-data': 'aveV'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1
                label: 'maxV'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'km/h'
                //, "cls":"date"
              }
            ],
            // "data-label": "maxSpeed"
            style: {
              width: '54px'
            },
            sort: true,
            'sort-data': 'maxV'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 100, // 2
                label: 'maxGY'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'G'
                //, "cls":"date"
              }
            ],
            // "data-label": "maxABfrAfr"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'maxGY'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 100, // 2
                label: 'maxGX'
                //, "cls":"date"
              },
              {
                type: 'unit',
                label: 'G'
                //, "cls":"date"
              }
            ],
            // "data-label": "maxALeftRight"
            style: {
              width: '44px'
            },
            sort: true,
            'sort-data': 'maxGX'
          },
          {
            data: [
              {
                type: 'num',
                decimal: 10, // 1
                label: 'dist',
                //, "cls":"date"
                filter: function (fd, data) {
                  // 1kmより小さい場合はmにする
                  var num = data.distm;
                  if (num < 1000) {
                    num = data.distm;
                  } else {
                    num = Math.round(data[fd.label] * fd.decimal) / fd.decimal;
                    if ((data[fd.label] % 1) === 0) {
                      num = num + '.0';
                    }
                  }

                  return num;
                }
              },
              {
                type: 'unit',
                label: 'km',
                //, "cls":"date"
                filter: function (fd, data) {
                  // 1kmより小さい場合はmにする
                  var num = data.distm;
                  if (num < 1000) {
                    return 'm';
                  }

                  return fd.label;
                }
              }
            ],
            // "data-label": "totalGpsMileage"
            style: {
              width: '70px'
            },
            sort: true,
            'sort-data': 'distm'
          }
        ]
      }
    };

    ListData.prototype.sampleData1 = {
      details: [
        {
          affiliation: '11第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '11:30:40',
          gpsRecordingTime: '11:30:40',
          id: '1', // image urlの作成につかう
          numberPerHour: 1.7,
          evaluationCriteriaG: 0.3,
          averageScore: 70.1,
          maxSpeed: 50.4,
          maxABfrAfr: 0.3,
          maxALeftRight: 0.3,
          totalGpsMileage: 66
        },
        {
          affiliation: '2第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '11:30:50',
          gpsRecordingTime: '11:30:40',
          id: '2', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '3第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '11:40:40',
          gpsRecordingTime: '11:30:40',
          id: '3', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '4第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '11:40:50',
          gpsRecordingTime: '11:30:40',
          id: '4', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '5第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:30:40',
          gpsRecordingTime: '11:30:40',
          id: '5', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '6第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:40:40',
          gpsRecordingTime: '11:30:40',
          id: '6', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '7第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:40:50',
          gpsRecordingTime: '11:30:40',
          id: '7', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '8第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:30:40',
          gpsRecordingTime: '11:30:40',
          id: '8', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '9第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:30:40',
          gpsRecordingTime: '11:30:40',
          id: '9', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:30:40',
          gpsRecordingTime: '11:30:40',
          id: '100', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:30:40',
          gpsRecordingTime: '11:30:40',
          id: '100', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:30:40',
          gpsRecordingTime: '11:30:40',
          id: '100', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:30:40',
          gpsRecordingTime: '11:30:40',
          id: '100', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:30:40',
          gpsRecordingTime: '11:30:40',
          id: '100', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:30:40',
          gpsRecordingTime: '11:30:40',
          id: '100', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:30:40',
          gpsRecordingTime: '11:30:40',
          id: '100', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:30:40',
          gpsRecordingTime: '11:30:40',
          id: '100', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:30:40',
          gpsRecordingTime: '11:30:40',
          id: '100', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:30:40',
          gpsRecordingTime: '11:30:40',
          id: '100', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        },
        {
          affiliation: '第1営業部',
          //, "name": "長谷川智昭"
          operationDateStart: '2017/11/12',
          operationDateEnd: '2017/11/12',
          totalRecordingTime: '12:30:40',
          gpsRecordingTime: '11:30:40',
          id: '100', // image urlの作成につかう
          numberPerHour: 1.8,
          evaluationCriteriaG: 0.35,
          averageScore: 80.1,
          maxSpeed: 57.4,
          maxABfrAfr: 0.34,
          maxALeftRight: 0.34,
          totalGpsMileage: 66.6
        }

      ]

    };

    function ListData () {

    }

    ListData.prototype.load = function (type, cd, sc, from, to, kijun, cd2, callback) {
      var url = '';
      var req = {
        sc: sc,
        from: from,
        to: to,
        kijun: kijun
      };

      if (type === 'comp') {
        url = BST.Const.csurl + 'comp';
        req.cd = cd;
      } else if (type === 'org') {
        url = BST.Const.csurl + 'org';
        req.cd = cd;
      } else if (type === 'driver') {
        url = BST.Const.csurl + 'drive';
        req.dcd = cd;
        req.cd = cd2;
      }

      var _self = this;

      BST.Com.post(url, req, function (res) {
        if (res.suc) {
          _self._data = res.data;
        } else {
          _self._data = null;
        }

        callback(res);
      });
    };

    ListData.prototype.csv = function (type, cd, sc, from, to, kijun, cd2, callback) {
      var url = '';
      var req = {
        sc: sc,
        from: from,
        to: to,
        kijun: kijun
      };

      if (type === 'comp') {
        url = BST.Const.csurl + 'csv/route';
        req.rcd = cd;// 企業コード
      } else if (type === 'org') {
        url = BST.Const.csurl + 'csv/route';
        req.cd = cd;// 拠点コード
      } else if (type === 'driver') {
        url = BST.Const.csurl + 'csv/route';
        req.dcd = cd;// ドライバコード
        req.cd = cd2;// 拠点コード
      }

      var _self = this;

      BST.Com.post(url, req, function (res) {
        // if (res.suc)
        //    _self._data = res.data;
        // else
        //    _self._data = null;

        callback(res);
      });
    };

    // ListData.prototype.load = function (type, cd, sc, from, to, kijun, cd2, callback) {
    //    var url = "";
    //    var req = {
    //        sc: sc
    //        , from: from
    //        , to: to
    //        , kijun: kijun
    //    };

    //    if (type == "comp") {
    //        url = BST.Const.csurl + "comp";
    //        req.cd = cd;
    //    } else if (type == "org") {
    //        url = BST.Const.csurl + "org";
    //        req.cd = cd;
    //    } else if (type == "driver") {
    //        url = BST.Const.csurl + "drive";
    //        req.dcd = cd;
    //        req.cd = cd2;
    //    }

    //    var _self = this;

    //    $.ajax({
    //        type: "POST"
    //        , datatype: "json"
    //        , data: JSON.stringify(req)
    //        , url: url
    //        , contentType: "application/json; charset=utf-8"
    //    }).then(function (data) {
    //        _self._data = data;
    //        if (callback) {
    //            //var res = _self.createResult(true, _self._data);
    //            var res = BST.Com.createAjaxResult(true, _self._data);
    //            callback(res);
    //        }

    //    }).fail(function (err) {
    //        //_self._data = null;
    //        if (callback) {
    //            //var res = _self.createResult(false, err);
    //            var res = BST.Com.createAjaxResult(false, err);
    //            callback(res);
    //        }
    //        console.log('list data error:' + err);
    //    }).always(function () {
    //    });
    // }

    // まとめたいなー
    // ListData.prototype.createResult = function (success, data) {
    //    var res = {
    //        suc: false
    //        , mess: "通信エラーが発生しました。(" + data.status + ")"
    //        , data: data
    //    };
    //    if (success) {
    //        // エラーなし
    //        if (data.ecd == 0) {
    //            res.suc = true;
    //            res.mess = "";
    //        }
    //            // サーブレット内でエラーあり
    //        else {
    //            //res.login = false;
    //            res.mess = data.emsg;
    //        }

    //    }
    //        // それ以外のエラー
    //    else {
    //        //res.login = false;
    //    }

    //    return res;

    // }

    ListData.prototype.extendData = function (data) {
      data.fromTime = data.from + ' ' + data.time;
    };

    return ListData;
  })();
})(BST || (BST = {}));
