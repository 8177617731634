var BST;
(function (BST) {
  var SettingData = BST.SettingData = (function () {
    function SettingData () {
      // this._data = null;
    }

    SettingData.prototype.sexs = [
      { id: 1, nm: '男' },
      { id: 2, nm: '女' }
    ];

    SettingData.prototype.format = {
      menu: [
        {
          type: 'eva',
          name: '評価'
        },
        {
          type: 'kyoten',
          name: '拠点'
        },
        {
          type: 'car',
          name: '車両'
        },
        {
          type: 'driver',
          name: 'ドライバー'
        },
        {
          type: 'user',
          name: 'ユーザー'
        },
        {
          type: 'vehicle',
          name: '車載器'
        }

      ]
    };

    SettingData.prototype.format2 = {
      menu: [
        {
          type: 'kyoten',
          name: '拠点'
        },
        {
          type: 'car',
          name: '車両'
        },
        {
          type: 'driver',
          name: 'ドライバー'
        },
        {
          type: 'user',
          name: 'ユーザー'
        },
        {
          type: 'vehicle',
          name: '車載器'
        }

      ]
    };

    /**
     * 拠点の一覧
     * @param param.sc
     * @return
     */
    SettingData.prototype.kyotenList = function (sc, callback) {
      var url = BST.Const.adminurl + 'org/list';
      var req = {
        sc: sc
      };
      var _self = this;

      BST.Com.post(url, req, function (res) {
        callback(res);
      });
    };

    /**
     * 拠点の新規登録
     * @param param.sc セッションコード
     * @param param.nm 拠点名
     * @return
     */
    SettingData.prototype.kyotenCreate = function (sc, nm, nm2, nm3, nm4, callback) {
      var url = BST.Const.adminurl + 'org/create';
      var req = {
        sc: sc,
        nm: nm,
        nm2: nm2,
        nm3: nm3,
        nm4: nm4
      };
      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    /**
     * 拠点の変更
     * @param param.sc セッションコード
     * @param param.cd 拠点コード
     * @param param.nm 拠点名
     * @return
     */
    SettingData.prototype.kyotenUpdate = function (sc, cd, nm, nm2, nm3, nm4, callback) {
      var url = BST.Const.adminurl + 'org/update';
      var req = {
        sc: sc,
        cd: cd,
        nm: nm,
        nm2: nm2,
        nm3: nm3,
        nm4: nm4
      };
      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    /**
     * 拠点の削除
     * @param param.sc セッションコード
     * @param param.cd 拠点コード
     * @return
     */
    SettingData.prototype.kyotenDelete = function (sc, cd, callback) {
      var url = BST.Const.adminurl + 'org/delete';
      var req = {
        sc: sc,
        cd: cd
      };
      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    /**
     * 評価基準の新規登録
     * @param param.sc セッションコード
     * @param param.grav
     * @param param.saiten
     * @param param.blank
     * @param param.term
     * @return
     * @return
     */
    SettingData.prototype.evaCreate = function (sc, grav, saiten, blank, term, dist, callback) {
      var url = BST.Const.adminurl + 'kijun/create';
      var req = {
        sc: sc,
        grav: grav,
        saiten: saiten,
        blank: blank,
        term: term,
        dist: dist
      };
      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    /**
     * 評価基準の変更
     * @param param.sc セッションコード
     * @param param.grav
     * @param param.saiten
     * @param param.blank
     * @param param.term
     * @return
     */
    SettingData.prototype.evaUpdate = function (sc, grav, saiten, blank, term, dist, callback) {
      var url = BST.Const.adminurl + 'kijun/update';
      var req = {
        sc: sc,
        grav: grav,
        saiten: saiten,
        blank: blank,
        term: term,
        dist: dist
      };
      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    /**
     * 現在の評価基準の取得
     * @param param.sc セッションコード
     * @return
     */
    SettingData.prototype.evaData = function (sc, callback) {
      var url = BST.Const.adminurl + 'kijun/data';
      var req = {
        sc: sc
      };
      var _self = this;

      BST.Com.post(url, req, function (res) {
        callback(res);
      });
    };

    /**
     * ドライバーの一覧
     * @param param.sc
     * @return
     */
    SettingData.prototype.driverList = function (sc, callback) {
      var url = BST.Const.adminurl + 'driver/data';
      var req = {
        sc: sc
      };
      var _self = this;

      BST.Com.post(url, req, function (res) {
        callback(res);
      });
    };

    SettingData.prototype.driverCreate = function (sc, nm, ocd, sx, nt, ni, callback) {
      var url = BST.Const.adminurl + 'driver/create';
      var req = {
        sc: sc,
        nm: nm,
        ocd: ocd,
        sx: sx,
        nt: nt,
        ni: ni
      };
      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    SettingData.prototype.driverUpdate = function (sc, cd, nm, ocd, sx, nt, ni, callback) {
      var url = BST.Const.adminurl + 'driver/update';
      var req = {
        sc: sc,
        cd: cd,
        nm: nm,
        ocd: ocd,
        sx: sx,
        nt: nt,
        ni: ni
      };
      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    SettingData.prototype.driverDelete = function (sc, cd, nm, ocd, sx, nt, ni, callback) {
      var url = BST.Const.adminurl + 'driver/delete';
      var req = {
        sc: sc,
        cd: cd,
        nm: nm,
        ocd: ocd,
        sx: sx,
        nt: nt,
        ni: ni
      };
      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    SettingData.prototype.driverCsv = function (sc, callback) {
      var url = BST.Const.adminurl + 'driver/csv';
      var req = {
        sc: sc
      };
      var _self = this;

      BST.Com.post(url, req, function (res) {
        callback(res);
      });
    };

    /**
     * 車両の一覧
     * @param param.sc
     * @return
     */
    SettingData.prototype.carList = function (sc, callback) {
      var url = BST.Const.adminurl + 'car/data';
      var req = {
        sc: sc
      };
      var _self = this;

      BST.Com.post(url, req, function (res) {
        callback(res);
      });
    };

    SettingData.prototype.carCreate = function (sc, nm, ocd, rcd, type, callback) {
      var url = BST.Const.adminurl + 'car/create';
      var req = {
        sc: sc,
        nm: nm,
        ocd: ocd,
        rcd: rcd,
        type: type
      };
      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    SettingData.prototype.carUpdate = function (sc, cd, nm, ocd, rcd, type, callback) {
      var url = BST.Const.adminurl + 'car/update';
      var req = {
        sc: sc,
        cd: cd,
        nm: nm,
        ocd: ocd,
        rcd: rcd,
        type: type
      };
      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    SettingData.prototype.carDelete = function (sc, cd, nm, ocd, rcd, type, callback) {
      var url = BST.Const.adminurl + 'car/delete';
      var req = {
        sc: sc,
        cd: cd,
        nm: nm,
        ocd: ocd,
        rcd: rcd,
        type: type
      };
      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    /**
     * ユーザーの一覧
     * @param param.sc
     * @return
     */
    SettingData.prototype.userList = function (sc, callback) {
      var url = BST.Const.adminurl + 'user/data';
      var req = {
        sc: sc
      };
      var _self = this;

      BST.Com.post(url, req, function (res) {
        callback(res);
      });
    };

    SettingData.prototype.userInfo = function (sc, cd, ucd, callback) {
      var url = BST.Const.adminurl + 'user/info';
      var req = {
        sc: sc,
        cd: cd,
        ucd: ucd
      };
      var _self = this;

      BST.Com.post(url, req, function (res) {
        callback(res);
      });
    };

    SettingData.prototype.userCreate = function (sc, data, callback) {
      var url = BST.Const.adminurl + 'user/create';

      var req = BST.Util.deepCopy(data);
      req.sc = sc;

      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    SettingData.prototype.userUpdate = function (sc, data, callback) {
      var url = BST.Const.adminurl + 'user/update';

      var req = BST.Util.deepCopy(data);
      req.sc = sc;

      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    SettingData.prototype.userDelete = function (sc, data, callback) {
      var url = BST.Const.adminurl + 'user/delete';

      var req = BST.Util.deepCopy(data);
      req.sc = sc;

      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    // SettingData.prototype.userData = [
    //    { type: 1, nm: "閲覧者" , rl:["C", "D"]}
    //    , { type: 2, nm: "閲覧者（管理者）", rl: ["B", "C", "D"] }
    //    , { type: 3, nm: "ドライバー", rl: ["D", "DR"] }
    //    , { type: 4, nm: "データアップロード用", rl: ["D"] }
    // ];

    SettingData.prototype.userData = [
      { type: 0, nm: 'システム管理者', rl: 'B', cd: 'admin', useFilter: false },
      { type: 1, nm: '閲覧者', rl: 'C', cd: null, useFilter: true },
      { type: 2, nm: '閲覧者（管理者）', rl: 'B', cd: null, useFilter: true },
      { type: 3, nm: 'ドライバー', rl: 'DR', cd: null, useFilter: true },
      { type: 4, nm: 'データアップロード用', rl: 'D', cd: null, useFilter: true }
    ];

    SettingData.prototype.userCreatePw = function (sc, data, callback) {
      var url = BST.Const.adminurl + 'user/pwd';

      var req = BST.Util.deepCopy(data);
      req.sc = sc;

      var _self = this;

      BST.Com.post(url, req, function (res) {
        callback(res);
      });
    };

    /**
     * 車載器の一覧
     * @param param.sc
     * @return
     */
    SettingData.prototype.devList = function (sc, callback) {
      var url = BST.Const.adminurl + 'dev/data';
      var req = {
        sc: sc
      };
      var _self = this;

      BST.Com.post(url, req, function (res) {
        callback(res);
      });
    };

    SettingData.prototype.devCreate = function (sc, sn, ocd, ccd, callback) {
      var url = BST.Const.adminurl + 'dev/create';
      var req = {
        sc: sc,
        sn: sn,
        ocd: ocd,
        ccd: ccd
      };
      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    SettingData.prototype.devUpdate = function (sc, cd, sn, ocd, ccd, callback) {
      var url = BST.Const.adminurl + 'dev/update';
      var req = {
        sc: sc,
        cd: cd,
        sn: sn,
        ocd: ocd,
        ccd: ccd
      };
      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    SettingData.prototype.devDelete = function (sc, cd, sn, ocd, ccd, callback) {
      var url = BST.Const.adminurl + 'dev/delete';
      var req = {
        sc: sc,
        cd: cd,
        sn: sn,
        ocd: ocd,
        ccd: ccd
      };
      var _self = this;

      BST.Com.postSync(url, req, function (res) {
        callback(res);
      });
    };

    return SettingData;
  })();
})(BST || (BST = {}));
