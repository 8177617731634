var BST;
(function (BST) {
  var DatePicker = BST.DatePicker = (function () {
    function DatePicker (parent, options) {
      var _self = this;

      parent = parent || 'body';
      var opt = this._opt = options || {};
      var css = opt.css || {};

      this._callbackSelected = opt.callbackSelected;
      this._callbackClose = opt.callbackClose;

      var base = this.base = $('<div>').addClass('bst-datepicker').css(css).appendTo(parent);
      // var base = $("<div>").addClass("bst-datepicker").appendTo("body");
      var dp = this.dp = $('<input type="text">').appendTo(base);
      var dpparam = {
        onSelect: function (dateText) {
          // alert(dateText);
          if (_self._callbackSelected) {
            _self._callbackSelected(dateText);
          }
        },
        onClose: function (dateText, inst) {
          if (_self._callbackClose) {
            _self._callbackClose(dateText);
          }
        },
        //, showOn: 'button'
        showOn: 'both',
        //, showButtonPanel: true
        buttonText: '',
        buttonImageOnly: true,
        buttonImage: 'img/cl-btn-n.png',
        dateFormat: 'yy/mm/dd',
        constrainInput: true, // データフォーマットの入力だけうけつける
        beforeShow: function (input, inst) {
          var widget = $(inst).datepicker('widget');
          widget.css('margin-left', ($(input).outerWidth() - widget.outerWidth()) + 'px');
        }

      };
      // if (opt.right)
      //    dpparam.beforeShow= function (input, inst) {
      //        var widget = $(inst).datepicker('widget');
      //        widget.css('margin-left', ($(input).outerWidth() - widget.outerWidth()) + "px");
      //    }

      dp.datepicker(dpparam);
      // dp.datepicker("option", "showOn", 'both');
      // dp.datepicker("option", "buttonImageOnly", true);
      // dp.datepicker("option", "buttonImage", 'ico_calendar.png');
      dp.next('.ui-datepicker-trigger').addClass('button');
      base.find('.button').on('mousedown', function () {
        $(this).attr('src', 'img/cl-btn-p.png');
      }).on('mouseup', function () {
        $(this).attr('src', 'img/cl-btn-n.png');
      }).on('hover', function () {
        $(this).attr('src', 'img/cl-btn-h.png');
      }, function () {
        $(this).attr('src', 'img/cl-btn-n.png');
      });
      // dp.datepicker('setDate', 'today');
      // dp.datepicker('setDate', new Date());
    }

    DatePicker.prototype.setDate = function (date) {
      this.dp.datepicker('setDate', date);
    };
    DatePicker.prototype.getDate = function () {
      return this.dp.datepicker('getDate');
    };

    DatePicker.prototype.setMaxDate = function (date) {
      this.dp.datepicker('option', 'maxDate', date);
    };

    DatePicker.prototype.setMinDate = function (date) {
      this.dp.datepicker('option', 'minDate', date);
    };

    DatePicker.prototype.value = function () {
      return this.dp.val();
    };

    return DatePicker;
  })();
})(BST || (BST = {}));
