var BST;
(function (BST) {
  var BaseCtr = BST.BaseCtr = (function () {
    function BaseCtr (type, param) {
      // this.palHome = $("#app-home-contents");
      // this.palTree = $("#app-tree-contents");
      // this.palSet = $("#app-setting-contents");

      this._type = type;
      _ctrAll.push(this);
    }

    var _ctrAll = []; // new された全部のコントローラ
    var _stype; // static

    // 使わなくなったら必ず呼ぶ
    BaseCtr.prototype.delete = function () {
      /* FIXME: Invalid loop. Its body allows only one iteration. */
      for (var i = 0; i < _ctrAll.length; i++) {
        _ctrAll[i] = this;
        _ctrAll.splice(i, 1);
        break;
      }
    };

    BaseCtr.prototype.getPanel = function () {
      return null;
    };

    BaseCtr.prototype.dispPanel = function () {
      if (_stype === this._type) return;

      _stype = this._type;

      this.dispOffPanel();

      for (var i = 0; i < _ctrAll.length; i++) {
        if (this === _ctrAll[i]) _ctrAll[i].getPanel().show();
      }

      // switch(type){
      //    case 1:
      //        this.palHome.show();
      //        break;
      //    case 2:
      //        this.palTree.show();
      //        break;
      //    case 3:
      //        this.palSet.show();
      //        break;
      // }

      // this.onChangeDisp(type);
      for (i = 0; i < _ctrAll.length; i++) {
        // $(_ctrAll[i]).trigger("change-panel");
        _ctrAll[i].__changedPanel(_stype);
      }
    };

    BaseCtr.prototype.dispOffPanel = function () {
      for (var i = 0; i < _ctrAll.length; i++) {
        if (_ctrAll[i].getPanel()) _ctrAll[i].getPanel().hide();
      }
      // this.palHome.hide();
      // this.palTree.hide();
      // this.palSet.hide();
    };

    BaseCtr.prototype.__changedPanel = function (type) {

    };

    // BaseCtr.prototype.onChangeDisp = function (type) {

    // }

    return BaseCtr;
  })();
})(BST || (BST = {}));
