var BST;
(function (BST) {
  var PlList = BST.PlList = (function () {
    function PlList (parent, data, options) {
      parent = parent || $('body');
      var opt = this._opt = options || {};

      this._data = data;

      this._callbackSelected = opt.callbackSelected;

      var base = this.base = $('<div>').addClass('bst-pllist').appendTo(parent);
      // index 初回クリック
      this.create(data, opt.index || 0);
      this.open(opt.open || false);
    }

    PlList.prototype.create = function (data, index) {
      var _self = this;

      var btn = this.btn = $('<div>').addClass('button').appendTo(this.base)
        .on('click', function (e) {
          _self.open(!_self._open);
        });

      this.createList(data, index, { top: this.btn.outerHeight(true) });

      /*
      var list = this.list = $('<div>').addClass('list').css({ top: btn.outerHeight(true) + 'px' }).hide();

      var init;
      for (var i = 0; i < data.length; i++) {
        var d = data[i];
        var o = $('<div>').addClass('item').text(d.label).data('data', d).appendTo(list)
          .on('click', function (e) {
            if (_self._selectedItem) {
              _self._selectedItem.removeClass('selected');
            }

            _self._selectedItem = $(this);
            _self._selectedItem.addClass('selected');

            var res = $.data(this, 'data');
            _self.btn.text(res.label);
            if (_self._callbackSelected) {
              _self._callbackSelected(res);
            }

            _self.open(false);
          });
        if (i === index) o.trigger('click'); // 初回クリック
        // if (i == index) {
        // init = o;
        // _self.btn.text(d.label);
        // }
      }

      list.appendTo(this.base);

      if (init) {
        // 初回クリック
        init.trigger('click');
      }
      */
    };

    PlList.prototype.createList = function (data, index, opt) {
      var _self = this;
      opt = opt || {};
      var list = this.list = $('<div>').addClass('list').css({ top: opt.top || 0 + 'px' }).hide();

      for (var i = 0; i < data.length; i++) {
        var d = data[i];
        var o = $('<div>').addClass('item').text(d.label).data('data', d).appendTo(list)
          .on('click', function (e) {
            if (_self._selectedItem) {
              _self._selectedItem.removeClass('selected');
            }

            _self._selectedItem = $(this);
            _self._selectedItem.addClass('selected');

            var res = $.data(this, 'data');
            _self.btn.text(res.label);
            if (_self._callbackSelected) {
              _self._callbackSelected(res);
            }

            _self.open(false);
          });

        // 初期値
        if (i === 0) {
          _self._selectedItem = o;
          _self._selectedItem.addClass('selected');
          _self.btn.text(d.label);
        }

        if (i === index) o.trigger('click'); // 初回クリック
        // 選択だけ
        // if (i == index) {
        //    _self._selectedItem = o;
        //    _self._selectedItem.addClass("selected");
        //    _self.btn.text(d.label);
        // }
      }

      list.appendTo(this.base);
    };

    PlList.prototype.update = function (data) {
      this.open(false);
      this._selectedItem = null;
      this.list.remove();
      this.createList(data, -1, { top: this.btn.outerHeight(true) });
    };

    PlList.prototype.open = function (open) {
      this._open = open;

      if (open) {
        this.list.show();
      } else {
        this.list.hide();
      }
    };

    PlList.prototype.value = function () {
      if (this._selectedItem) {
        var d = this._selectedItem.data('data');
        return d;
      }
      return null;
    };

    return PlList;
  })();
})(BST || (BST = {}));
