var BST;
(function (BST) {
  var SettingDialogVehicleEdit = BST.SettingDialogVehicleEdit = (function () {
    function SettingDialogVehicleEdit () {
      // this.base;
    }

    SettingDialogVehicleEdit.prototype.create = function () {
      var _self = this;

      this.bg = $('<div>').addClass('bst-setting-dialog-vehicle-edit-bg').appendTo('body').hide();
      var base = this.base = $('<div>').addClass('bst-setting-dialog-vehicle-edit').appendTo('body').hide();
      var panel = $('<div>').addClass('panel').appendTo(base);
      this.title = $('<div>').addClass('title').text('タイトル').appendTo(panel);
      $('<div>').addClass('ms').text('※*は入力必須項目です。').appendTo(panel);

      var row1 = $('<div>').addClass('row').appendTo(panel);
      $('<div>').addClass('label').text('シリアルNO.').appendTo(row1);
      $('<span>').addClass('mark').text('*').appendTo(row1);
      this.input1 = $('<input>').addClass('ip1').attr('maxlength', 20).appendTo(row1)
        .on('change', function () {
          _self.change();
        });

      var row2 = $('<div>').addClass('row').appendTo(panel);
      $('<div>').addClass('label').text('所属拠点').appendTo(row2);
      $('<span>').addClass('mark').text('*').appendTo(row2);
      this.select1 = $('<select>').addClass('pl1').appendTo(row2)
        .on('change', function () {
          _self.filterCar();
          _self.change();
        });
      var row3 = $('<div>').addClass('row').appendTo(panel);
      $('<div>').addClass('label').text('車両名').appendTo(row3);
      $('<span>').addClass('mark').text('*').appendTo(row3);
      this.select2 = $('<select>').addClass('pl2').appendTo(row3)
        .on('change', function () {
          _self.change();
        });

      var row4 = $('<div>').addClass('btns').appendTo(panel);
      var btn1 = this.btn1 = $('<button>').addClass('ok').text('OK').appendTo(row4);
      var btn2 = $('<button>').addClass('cancel').text('キャンセル').appendTo(row4);

      btn1.on('click', function () {
        if (_self.check(_self._data) === false) return;

        try {
          if (_self._callback1) {
            _self._callback1(_self._data);
          }
        } catch (e) {
          console.log(e);
        }
      });
      btn2.on('click', function () {
        try {
          if (_self._callback2) {
            _self._callback2();
          }
        } catch (e) {
          console.log(e);
        }
      });
    };
    SettingDialogVehicleEdit.prototype.setData = function (sl1, sl2, data) {
      this._data = data;
      this._sl1 = sl1;
      this._sl2 = sl2;

      var view = this.base;
      if (view) {
        // シリアルNo.
        this.input1.val(data.sn);

        // 拠点
        this.select1.empty();
        for (var i = 0; i < sl1.length; i++) {
          var o = $('<option>').text(sl1[i].nm).data('data', sl1[i]).appendTo(this.select1);
          if (sl1[i].cd === data.ocd) {
            this.select1.get(0).options[i].selected = true;
          }
        }

        // 車両名
        this.select2.empty();
        for (i = 0; i < sl2.length; i++) {
          o = $('<option>').text(sl2[i].cnm).data('data', sl2[i]).appendTo(this.select2);
          if (sl2[i].ccd === data.ccd) {
            this.select2.get(0).options[i].selected = true;
          }
        }
      }
    };

    SettingDialogVehicleEdit.prototype.change = function () {
      try {
        this._data.sn = this.input1.val();
      } catch (e) {
        console.log(e);
        this._data.sn = '';
      }
      try {
        this._data.ocd = this.select1.find(':selected').data('data').cd;
      } catch (e) {
        console.log(e);
        this._data.ocd = '';
      }
      try {
        this._data.ccd = this.select2.find(':selected').data('data').ccd;
      } catch (e) {
        console.log(e);
        this._data.ccd = '';
      }
    };

    SettingDialogVehicleEdit.prototype.check = function (data) {
      var err = '';
      // 空白

      if (BST.Util.isBlank(data.sn)) {
        err = this.addText(err, '※シリアルNO.を入力して下さい。');
      }

      if (BST.Util.isBlank(data.ocd)) {
        err = this.addText(err, '※所属拠点を選択して下さい。');
      }

      if (BST.Util.isBlank(data.ccd)) {
        err = this.addText(err, '※車両を選択して下さい。');
      }

      if (err === '') return true;

      BST.Com.dialogLErr(err);
      return false;
    };

    SettingDialogVehicleEdit.prototype.type = function (type) {
      if (!this.base) this.create();

      if (type === 1) {
        // new
        this.title.text('新規登録');
        this.btn1.text('登録');
      } else if (type === 2) {
        // edit
        this.title.text('編集');
        this.btn1.text('OK');
      }
    };
    SettingDialogVehicleEdit.prototype.open = function (orgs, cars, data, callback1, callback2, type) {
      if (!this.base) this.create();

      this.type(type);

      this._callback1 = callback1;
      this._callback2 = callback2;

      this.setData(orgs, cars, data);

      this.filterCar();

      this.change();

      this.disp(true);
    };

    SettingDialogVehicleEdit.prototype.close = function (type) {
      var view = this.base;
      if (view) {
        this._callback1 = null;
        this._callback2 = null;
        this._data = null;
      }

      this.disp(false);
    };

    SettingDialogVehicleEdit.prototype.disp = function (b) {
      if (!this.base) {
        this.create();
      }
      if (b) {
        this.bg.show();
        this.base.show();
      } else {
        this.bg.hide();
        this.base.hide();
      }
    };

    SettingDialogVehicleEdit.prototype.addText = function (str, add) {
      if (str !== '') str += '<br>';

      str += add;

      return str;
    };

    SettingDialogVehicleEdit.prototype.filterCar = function () {
      var _self = this;
      try {
        // ドライバーを絞り込む
        var sd1 = this.select1.find(':selected').data('data');
        var ocd1 = sd1.cd;
        var sd2 = this.select2.find(':selected').data('data') || {};
        var ocd2 = sd2.ocd;
        var ccd2 = sd2.ccd;
        // var index=null;

        // var dispFirst;
        // this.select2.find("option").each(function (i) {
        //    var s2d = $(this).data("data");
        //    // 同じ拠点。何も選択しないは表示
        //    //if (s2d.ocd == ocd1 || s2d.ocd == "") $(this).show();
        //    // s2d.cd == "" は選択しないってデータ
        //    if (s2d.ocd == ocd1 || s2d.cd == "") {
        //        $(this).show();
        //        if (!dispFirst) dispFirst = $(this);
        //    }
        //    else $(this).hide();
        // });

        var dispFirst;

        this.select2.empty();
        for (var i = 0; i < this._sl2.length; i++) {
          // 同じ拠点。何も選択しないは表示
          // if (s2d.ocd == ocd1 || s2d.ocd == "") $(this).show();
          // s2d.cd == "" は選択しないってデータ
          if (this._sl2[i].ocd === ocd1 || this._sl2[i].cd === '') {
            var o = $('<option>').text(this._sl2[i].cnm).data('data', this._sl2[i]).appendTo(this.select2);
            // filterがよばれたけど、選択している車がある場合は選択状態へ
            if (this._sl2[i].ccd === ccd2) {
              o.get(0).selected = true;
            }

            if (!dispFirst) dispFirst = o;
          }
        }

        // 拠点が変わったなら車も変わるので、最初のものを選択
        if (ocd1 !== ocd2) {
          if (dispFirst) dispFirst.get(0).selected = true;
        }
        // this.select2.get(0).options[0].selected = true;
        // this.select2.get(0).options[index].selected = true;
      } catch (e) {
        console.log(e);
      }
    };

    return SettingDialogVehicleEdit;
  })();
})(BST || (BST = {}));
