var BST;
(function (BST) {
  var Util = BST.Util = (function () {
    // trim ie9以前対応
    if (!String.prototype.trim) {
      // eslint-disable-next-line no-extend-native
      String.prototype.trim = function () {
        return this.replace(/^\s+|\s+$/g, '');
      };
    }

    function Util () {
    }

    Util.inherits = function (childCtor, parentCtor) {
      // 子クラスの prototype のプロトタイプとして 親クラスの
      // prototype を指定することで継承が実現される
      /** @constructor */
      function TempCtor () { }
      TempCtor.prototype = parentCtor.prototype;
      childCtor.superClass_ = parentCtor.prototype;
      childCtor.prototype = new TempCtor();
      /** @override */
      childCtor.prototype.constructor = childCtor;
    };

    // dateFormat(new Date('2015/03/04'), 'MMM dt, yyyy [w]');
    var _dataFormat = {
      hh: function (date) {
        return ('0' + date.getHours()).slice(-2);
      },
      h: function (date) {
        return date.getHours();
      },
      mm: function (date) {
        return ('0' + date.getMinutes()).slice(-2);
      },
      m: function (date) {
        return date.getMinutes();
      },
      ss: function (date) {
        return ('0' + date.getSeconds()).slice(-2);
      },
      dd: function (date) {
        return ('0' + date.getDate()).slice(-2);
      },
      d: function (date) {
        return date.getDate();
      },
      s: function (date) {
        return date.getSeconds();
      },
      yyyy: function (date) {
        return date.getFullYear() + '';
      },
      yy: function (date) {
        return date.getYear() + '';
      },
      t: function (date) {
        return date.getDate() <= 3 ? ['st', 'nd', 'rd'][date.getDate() - 1] : 'th';
      },
      w: function (date) {
        return ['Sun', '$on', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()];
      },
      MMMM: function (date) {
        return ['January', 'February', '$arch', 'April', '$ay', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][date.getMonth()];
      },
      MMM: function (date) {
        return ['Jan', 'Feb', '$ar', 'Apr', '@ay', 'Jun', 'Jly', 'Aug', 'Spt', 'Oct', 'Nov', 'Dec'][date.getMonth()];
      },
      MM: function (date) {
        return ('0' + (date.getMonth() + 1)).slice(-2);
      },
      M: function (date) {
        return date.getMonth() + 1;
      },
      $: function (date) {
        return 'M';
      }
    };
    Util.dateFormat = function dateFormat (date, format) {
      var result = format;
      for (var key in _dataFormat) {
        result = result.replace(key, _dataFormat[key](date));
      }
      return result;
    };

    //= ==============================================================
    /**
         * @brief ある文字列内の指定文字列を別の文字列に置き換える
         *
         * @param _targetStr_ 置換を行う前の文字列
         * @param _searchStr_ 検索する文字列
         * @param _replaceStr_ 検索で見つかった文字列と置き換える文字列
         *
         * @return 置換処理後の文字列
         */
    //= ==============================================================
    Util.replaceAll = function (_targetStr_, _searchStr_, _replaceStr_) {
      return _targetStr_.split(_searchStr_).join(_replaceStr_);
    };

    // 深いコピー
    Util.deepCopy = function (obj) {
      return JSON.parse(JSON.stringify(obj));
    };

    // 半角英数チェック
    Util.isHanEisu = function (str) {
      str = (str == null) ? '' : str;
      if (str.match(/^[A-Za-z0-9]*$/)) {
        return true;
      } else {
        return false;
      }
    };

    Util.isHanNum = function (str) {
      str = (str == null) ? '' : str;
      if (str.match(/[^0-9]+/)) {
        return true;
      } else {
        return false;
      }
    };

    // 空白か？
    Util.isBlank = function (str) {
      if (str === undefined || str === null) return true;

      if (str === '') return true;
      try {
        if (str.trim() === '') return true;
      } catch (e) { }
      return false;
    };

    Util.textOverflowEllipsis = function (text, num) {
      try {
        if (text.length > num) text = text.substr(0, num) + '…';
      } catch (e) { }

      return text;
    };

    // %->%25にするだけ
    Util.percentEncode = function (str) {
      return BST.Util.replaceAll(str, '%', '%25');
    };

    //= =======================================================
    // ImageToBase64
    // Params
    //   image     : HTMLImageElement | Source Image Element
    //   mime_type : string | Source Image MIME Type
    // Return
    //   string | Base64 Image Data
    //= =======================================================
    Util.ImageToBase64 = function (imageSrc, mimeType, originWidth, originHeight) {
      var width = originWidth || imageSrc.width;
      var height = originHeight || imageSrc.height;

      // New Canvas
      var canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      // Draw
      var ctx = canvas.getContext('2d');
      ctx.drawImage(imageSrc, 0, 0);
      // Image Base64
      return canvas.toDataURL(mimeType);
    };

    return Util;
  })();
})(BST || (BST = {}));
