var BST;
(function (BST) {
  var SheetData = BST.SheetData = (function () {
    function SheetData () {
      this._data = null;
    }

    SheetData.prototype.tabs =
        [
          { label: '運転カルテ', id: 1 },
          { label: '★動画リスト', id: 2 }
        ];

    SheetData.prototype.data = function () {
      return this._data;
    };

    SheetData.prototype.load = function (cd, dcd, rcd, sc, from, kijun, callback) {
      var url = BST.Const.csurl + 'karte';
      var req = {
        cd: cd,
        dcd: dcd,
        rcd: rcd,
        sc: sc,
        from: from,
        kijun: kijun
      };
      var _self = this;

      BST.Com.post(url, req, function (res) {
        if (res.suc) {
          _self._data = res.data;
        } else {
          _self._data = null;
        }

        callback(res);
      });
    };

    // SheetData.prototype.load = function (cd, dcd, rcd, sc, from, kijun, callback) {
    //    var url = BST.Const.csurl + "karte";
    //    var req = {
    //        cd: cd
    //        , dcd: dcd
    //        , rcd: rcd
    //        , sc: sc
    //        , from: from
    //        , kijun: kijun
    //    };
    //    var _self = this;

    //    $.ajax({
    //        type: "POST"
    //        , datatype: "json"
    //        , data: JSON.stringify(req)
    //        , url: url
    //        , contentType: "application/json; charset=utf-8"
    //    }).then(function (data) {
    //        _self._data = data;
    //        if (callback) {
    //            var res = BST.Com.createAjaxResult(true, _self._data);
    //            callback(res);
    //        }

    //    }).fail(function (err) {
    //        //_self._data = null;
    //        if (callback) {
    //            var res = BST.Com.createAjaxResult(false, err);
    //            callback(res);
    //        }
    //        console.log('sheet data error:' + err);
    //    }).always(function () {
    //    });
    // }

    return SheetData;
  })();
})(BST || (BST = {}));
