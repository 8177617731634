var BST;
(function (BST) {
  var SettingVehicle = BST.SettingVehicle = (function () {
    function SettingVehicle (parent, options) {
      var _self = this;

      parent = parent || 'body';
      var opt = this.opt = options || {};
      this._callbackCreate = opt.callbackCreate;
      this._callbackUpdate = opt.callbackUpdate;
      this._callbackDelete = opt.callbackDelete;

      this.create(parent);
      this.createTable([], [], []);
    }

    SettingVehicle.prototype.setData = function (datas, orgs, cars) {
      this._datas = datas || [];
      this._orgs = orgs || [];
      this._cars = cars || [];
    };

    SettingVehicle.prototype.create = function (parent) {
      var _self = this;

      var base = this.base = $('<div>').addClass('setting-vehicle').appendTo(parent);

      var header = $('<div>').addClass('header').appendTo(base);

      var row1 = $('<div>').addClass('row').appendTo(header);
      this.btnNew = $('<span>').addClass('btn-new').text('新規作成').appendTo(row1)
        .on('click', function () {
          if (_self._disableNewBtn) return;
          if (_self._callbackCreate) _self._callbackCreate(_self._orgs, _self._cars);
        });
      $('<span>').addClass('label').html('車載器設定').appendTo(row1);

      var row2 = $('<div class="row"> ').appendTo(header);
      $("<input type='password' name='dummypass' style='visibility: hidden; position: absolute;' />").addClass('search').appendTo(row2);
      this.search = $('<input>').addClass('search').attr('autocomplete', 'off').attr('placeholder', 'フリーワード検索').appendTo(row2);
    };

    // テーブル作成
    SettingVehicle.prototype.createTable = function (drs, orgs, cars) {
      var _self = this;

      // テーブル
      if (!this.table) {
        this.table = $('<table>').addClass('table').appendTo(this.base);
      } else {
        this.table.empty();
      }
      var table = this.table;
      var header = $('<thead>').addClass('scrollHead').appendTo(table);
      var tr = $('<tr> ').appendTo(header);
      $('<td>').addClass('col0').addClass('label0').text('').appendTo(tr);
      $('<td>').addClass('col1').addClass('label').text('シリアルNo').appendTo(tr);
      $('<td>').addClass('col2').addClass('label').text('所属拠点').appendTo(tr);
      $('<td>').addClass('col3').addClass('label').text('車両名').appendTo(tr);
      $('<td>').addClass('col4').addClass('label').text('').appendTo(tr);

      // ハッシュ
      var orgMap = {};
      for (var i = 0; i < orgs.length; i++) {
        orgMap[orgs[i].cd] = orgs[i];
      }

      var carMap = {};
      for (i = 0; i < cars.length; i++) {
        carMap[cars[i].ccd] = cars[i];
      }

      var body = $('<tbody>').addClass('scrollBody').appendTo(table);
      // 1行分
      for (i = 0; i < drs.length; i++) {
        var d = drs[i];

        // 所属
        var org = orgMap[d.ocd] || { nm: '' };
        // 車両名
        var car = carMap[d.ccd] || { nm: '' };

        tr = $('<tr class="tr"> ').data('data', d).appendTo(_self.table);
        $('<td>').addClass('col0').addClass('item').addClass('item0').text(i + 1).appendTo(tr);
        $('<td>').addClass('col1').addClass('item').text(d.sn).appendTo(tr);
        $('<td>').addClass('col2').addClass('item').text(org.nm).appendTo(tr);
        $('<td>').addClass('col3').addClass('item').text(car.cnm).appendTo(tr);
        var btns = $('<td>').addClass('col4').addClass('item').appendTo(tr);
        $('<span>').addClass('btn-edit').text('編集').appendTo(btns)
          .on('click', function () {
            var _d = $(this).parent().parent().data('data');
            if (_self._callbackUpdate) {
              _self._callbackUpdate(_d, _self._orgs, _self._cars);
            }
          });
        $('<span>').addClass('btn-delete').text('削除').appendTo(btns)
          .on('click', function () {
            var _d = $(this).parent().parent().data('data');
            if (_self._callbackDelete) {
              _self._callbackDelete(_d);
            }
          });
      }// end of for
      if (drs.length > 0) {
        this.table.exTableFilter(this.search
          , {
            onFilteringEnd: function (api) {
              _self.resetNumber();
            }
          }
        );
      }
      this.search.trigger('change');
    };

    SettingVehicle.prototype.update = function (drs, orgs, cars) {
      this.setData(drs, orgs, cars);
      this.createTable(drs, orgs, cars);
    };

    SettingVehicle.prototype.resetNumber = function () {
      var no = 1;

      this.table.find('.scrollBody').find('.tr').each(function (i, elem) {
        var $tr = $(elem);

        // console.debug($tr.css('display'));
        if ($tr.css('display') !== 'none') {
          var $no = $tr.find('.col0');
          $no.text(no);
          no++;
        }
      });
    };

    SettingVehicle.prototype.disp = function (b) {
      if (b) {
        this.base.show();
      } else {
        this.base.hide();
      }
    };

    return SettingVehicle;
  })();
})(BST || (BST = {}));
