var BST;
(function (BST) {
  var Com = BST.Com = (function () {
    Com.session = null;
    Com.lgData = null;

    Com.current = {};

    Com.setLogin = function (l) {
      if (l) {
        Com.session = l.sc;
        Com.lgData = l;
      } else {
        Com.session = null;
        Com.lgData = null;
      }
    };
    Com.getLogin = function () {
      return Com.lgData;
    };

    // admin
    Com.isAdmin = function () {
      // if (Com.lgData.master) return true;
      if (Com.lgData.ut === 60) return true;
      if (Com.lgData.ut === 70) return true;
      return false;
    };

    // 唯一のadmin
    Com.isAdminU = function () {
      // if (Com.lgData.master) return true;
      if (Com.lgData.ut === 70) return true;
      return false;
    };

    Com.isCsv = function () {
      return Com.lgData.csv === 1;
    };

    Com.post = function (url, req, callback, opt) {
      opt = opt || {
        type: 'post'
      };

      $.ajax({
        type: 'POST',
        datatype: 'json',
        data: JSON.stringify(req),
        url: url,
        contentType: 'application/json; charset=utf-8'
      }).then(function (data) {
        if (callback) {
          // セッションがきれました。
          if (data.ecd === 200) {
            BST.Com.secDialog(function (b) {
              BST.Com.reload();
            });
            return;
          }

          var res = BST.Com.createAjaxResult(true, data);
          callback(res);
        }
      }).fail(function (err) {
        if (callback) {
          var res = BST.Com.createAjaxResult(false, err);
          callback(res);
        }
        console.log(opt.type + ' data error:' + err);
      }).always(function () {
      });
    };

    // 通信中は通信を行わないajax
    Com._postSync = false;
    Com.postSync = function (url, req, callback, opt) {
      if (Com._postSync) {
        console.log('Com.postSync通信中');
        return;
      }

      Com._postSync = true;

      Com.post(url, req, function (res) {
        Com._postSync = false;
        callback(res);
      }, opt);
    };

    Com.createAjaxResult = function (success, data) {
      var res = {
        suc: false,
        mess: '通信エラーが発生しました。(' + data.status + ')',
        data: data
      };
      if (success) {
        if (data.ecd === 0) {
          // エラーなし
          res.suc = true;
          res.mess = '';
        } else {
          // サーブレット内でエラーあり
          // res.login = false;
          res.mess = data.emsg + '[' + data.ecd + ']';
        }
      } else {
        // それ以外のエラー
        // res.login = false;
      }

      return res;
    };

    Com.reload = function () {
      location = location.href;
    };

    var dialog = new BST.VDialog();
    Com.dialog = function (text, opt) {
      dialog.open(text, opt);
      dialog.textCenter();
    };
    Com.dialogLErr = function (text, opt) {
      dialog.open(text, opt);
      dialog.textLeft();
      dialog.textColor(BST.VDialog.TYPE_COL_ERR);
    };

    Com.secDialog = function (callback) {
      dialog.open('セッションが切れました。<br>ログインをしなおしてください。', { callback1: callback });
      dialog.textCenter();
    };

    var dialogYesNo = new BST.Dialog({ type: BST.Dialog.TYPE_YESNO });
    // 削除、キャンセル
    Com.dialogDelCan = function (text, callback1, callback2, opt) {
      dialogYesNo.open(text, callback1, callback2, opt);
      dialogYesNo.btn1Text('削除する');
      dialogYesNo.btn2Text('キャンセル');
    };
    Com.dialogDelCanClose = function () {
      dialogYesNo.close();
    };
    // OK、キャンセル
    Com.dialogOkCan = function (text, callback1, callback2, ok, can) {
      dialogYesNo.open(text, callback1, callback2);
      if (ok) {
        dialogYesNo.btn1Text(ok);
      } else {
        dialogYesNo.btn1Text('OK');
      }
      if (can) {
        dialogYesNo.btn2Text(can);
      } else {
        dialogYesNo.btn2Text('キャンセル');
      }
    };

    Com.dialogOkCanClose = function () {
      dialogYesNo.close();
    };

    /// / dateFormat(new Date('2015/03/04'), 'MMM dt, yyyy [w]');
    // var _dataFormat = {
    //    hh: function (date) { return ('0' + date.getHours()).slice(-2); },
    //    h: function (date) { return date.getHours(); },
    //    mm: function (date) { return ('0' + date.getMinutes()).slice(-2); },
    //    m: function (date) { return date.getMinutes(); },
    //    ss: function (date) { return ('0' + date.getSeconds()).slice(-2); },
    //    dd: function (date) { return ('0' + date.getDate()).slice(-2); },
    //    d: function (date) { return date.getDate(); },
    //    s: function (date) { return date.getSeconds(); },
    //    yyyy: function (date) { return date.getFullYear() + ''; },
    //    yy: function (date) { return date.getYear() + ''; },
    //    t: function (date) { return date.getDate() <= 3 ? ["st", "nd", "rd"][date.getDate() - 1] : 'th'; },
    //    w: function (date) { return ["Sun", "$on", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getDay()]; },
    //    MMMM: function (date) { return ["January", "February", "$arch", "April", "$ay", "June", "July", "August", "September", "October", "November", "December"][date.getMonth()]; },
    //    MMM: function (date) { return ["Jan", "Feb", "$ar", "Apr", "@ay", "Jun", "Jly", "Aug", "Spt", "Oct", "Nov", "Dec"][date.getMonth()]; },
    //    MM: function (date) { return ('0' + (date.getMonth() + 1)).slice(-2); },
    //    M: function (date) { return date.getMonth() + 1; },
    //    $: function (date) { return 'M'; }
    // };
    // Com.dateFormat = function dateFormat(date, format) {
    //    var result = format;
    //    for (var key in _dataFormat)
    //        result = result.replace(key, _dataFormat[key](date));
    //    return result;
    // }

    /// /===============================================================
    /// **
    // * @brief ある文字列内の指定文字列を別の文字列に置き換える
    // *
    // * @param _targetStr_ 置換を行う前の文字列
    // * @param _searchStr_ 検索する文字列
    // * @param _replaceStr_ 検索で見つかった文字列と置き換える文字列
    // *
    // * @return 置換処理後の文字列
    // */
    /// /===============================================================
    // Com.replaceAll = function(_targetStr_, _searchStr_, _replaceStr_) {

    //    return _targetStr_.split(_searchStr_).join(_replaceStr_);

    // }

    // Gの値を調整する。
    Com.adjustGrav = function (grav) {
      return Math.round(grav * 100) / 100;
    };

    function Com () {
    }

    return Com;
  })();
})(BST || (BST = {}));
