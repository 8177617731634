var BST;
(function (BST) {
  var ReportSrv = BST.ReportSrv = (function () {
    function ReportSrv () {
      var _self = this;
      var view = this._view = new BST.Report('body', {
        callbackChangeDate: function (text) {
          _self.onChangeDate(text);
        }
      });
      var d = this._data = new BST.ReportData();

      // this.change("dept");
    }

    ReportSrv.prototype.view = function (cd, dcd, sc, name, _date) {
      _date = _date || new Date();

      var _self = this;
      var g = 999;
      var date = BST.Util.dateFormat(_date, 'yyyy/MM/dd');
      // var date = "2012/10/09";
      // var date = "2017/11/27";

      this.current = {
        cd: cd,
        dcd: dcd,
        sc: sc,
        g: g,
        date: date
      };

      this._data.load(cd, dcd, sc, date, g, function (res) {
        if (res.suc) {
          // res.data.list = [
          //    {
          //        aaa: 49
          //        , bbb: 26.2
          //        , ccc: 15
          //    }
          // ];

          _self._view.createView(res.data, _date);
          // _self._view.clear();
          // _self._view.create(res.data.head, _date);
          // _self._view.create(res.data, _date);
        } else {
          _self._view.createViewMess(name, _date, res.mess);
          // _self._view.createHeader(name, _date);
          // _self._view.setMess(res.mess);
        }
      });

      // this._data.loadImg(cd, dcd, sc,date, 999, 200, 200, function (res) {
      //    var a = res;
      // });
    };

    ReportSrv.prototype.onChangeDate = function (text) {
      var _self = this;
      this.current.date = text;
      this._data.load(this.current.cd, this.current.dcd, this.current.sc, this.current.date, this.current.g, function (res) {
        if (res.suc) {
          // _self._view.clearBody();
          // _self._view.createBody(res.data.head);
          _self._view.changeView(res.data);
        } else {
          // _self._view.clearBody();
          // _self._view.setMess(res.mess);
          _self._view.changeViewMess(res.mess);
        }
      });
    };

    return ReportSrv;
  })();
})(BST || (BST = {}));
