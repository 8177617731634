var BST;
(function (BST) {
  var ListSrv = BST.ListSrv = (function () {
    function ListSrv (parent) {
      var _self = this;

      var vh = this.vh = $('<div>').css({
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        height: '182px'
      }).appendTo(parent);

      var v = this.v = $('<div>').css({
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0'
      }).appendTo(parent);

      var d = this._data = new BST.ListData();
      var viewh = this._viewh = new BST.List(vh);
      var view = this._view = new BST.List(v
        , {
          callbackSelected: function (data) {
            _self.onSelectedItem(data);
          },
          extendData: function (data) {
            d.extendData(data);
          }
        });

      // this.change("dept");
    }

    ListSrv.prototype.change = function (type, cd, sc, from, to, kijun, cd2, callback) {
      var _self = this;
      // if (this._type == type) return;
      // this._type = type;
      // if (this._cd == cd) return;
      this._cd = cd;

      var fmt = this._data.format[type];
      if (!fmt) return;

      this._data.load(type, cd, sc, from, to, kijun, cd2
        , function (res) {
          if (res.suc) {
            _self._viewh.clear();
            _self._type = type;

            var top = _self.vh.position().top;

            if (res.data.head) {
              top += _self.vh.outerHeight(true);
              var fmth = _self._data.format[type + '-h'];
              if (fmth) {
                _self._viewh.update(fmth, [res.data.head]);
              }
            }

            // view は常にクリックを許可
            // if (type == "driver") _self._view.useClick(true);
            // else _self._view.useClick(false);
            _self._view.useClick(true);

            if (res.data.list) {
              _self.v.css({ top: Math.round(top) + 'px' });
              _self._view.update(fmt, res.data.list);
            }
          } else {
            _self._viewh.clear();
            _self._view.clear();
            _self.v.css({ top: '0px' });
            _self._view.setMess(res.mess);
            _self._type = type;
          }
          if (callback) callback(res);
        }
      );

      // var data = this._data.sampleData1;
      // for( var i=0; i<500; i++){
      //    data.details.push(data.details[data.details.length - 1]);

      // }
      // this._view.update(fmt, data);
    };

    ListSrv.prototype.csv = function (type, cd, sc, from, to, kijun, cd2) {
      var _self = this;

      this._data.csv(type, cd, sc, from, to, kijun, cd2, function (res) {
        // if (res.ecd == 0) {
        //    location.href = res.emsg;
        // }
        if (res.suc) {
          if (res.data.ecd === 0) {
            // location.href = res.data.emsg;
            location.href = BST.Const.csd + res.data.emsg;
          }
        } else {
          // error
          BST.Com.dialog(res.mess);
        }
      });
    };

    ListSrv.prototype.onSelectedItem = function (data) {
      // alert(node.label)
      var param = {
        type: this._type,
        data: data
      };

      $(this).trigger('select', param);
    };

    ListSrv.prototype.disp = function (b) {
      this._viewh.disp(b);
      this._view.disp(b);
    };

    return ListSrv;
  })();
})(BST || (BST = {}));
