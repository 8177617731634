var BST;
(function (BST) {
  var SheetSrv = BST.SheetSrv = (function () {
    function SheetSrv () {
      var _self = this;
      var d = this._data = new BST.SheetData();
      var view = this._view = new BST.Sheet('body', {
        tabs: d.tabs,
        callbackSelected: function (data) {
          // var src = BST.MvListData.getMoviePath(data, data.fid);
          _self.play(data);
        }
      });
      this.player = new BST.VideoPlayer();

      // this.change("dept");
    }

    SheetSrv.prototype.view = function (cd, dcd, rcd, date, sc) {
      var _self = this;
      // var date = "2012/10/09";
      // var date = "2017/11/27";
      // var date = BST.Util.dateFormat(new Date(), "yyyy/MM/dd");
      this._data.load(cd, dcd, rcd, sc, date, 999, function (res) {
        if (res.suc) {
          _self._view.update(res.data.head, res.data.movies);
          // _self._view.clear();
          // _self._view.create(res.data.head);
        } else {
          _self._view.setMess(res.mess);
        }
      });
    };

    SheetSrv.prototype.play = function (data) {
      if (!data.fid) return;

      var src = BST.MvListData.getMoviePath(data, data.fid);
      this.player.open(data, src);
    };

    return SheetSrv;
  })();
})(BST || (BST = {}));
