var BST;
(function (BST) {
  var TreeSrv = BST.TreeSrv = (function () {
    function TreeSrv (parent) {
      var _self = this;
      var view = this._view = new BST.Tree(parent, {
        callbackSelected: function (node, pnode) {
          _self.onSelectedItem(node, pnode);
        },
        callbackReport: function (node, pnode) {
          _self.onClickReport(node, pnode);
        }

      });
      var d = this._data = new BST.TreeData();
      var dn = {
        label: 'nm',
        nodes: 'child'
      };
      // d.setDataName(dn);
      view.setDataName(dn);
      view.visible(false);

      // this.update();
    }

    TreeSrv.prototype.getItem = function (type, cd) {
      return this._view.getItem(type, cd);
    };

    TreeSrv.prototype.clickItem = function (type, cd) {
      this._view.clickItem(type, cd);
    };

    TreeSrv.prototype.update = function (_json) {
      var json = _json;
      if (!Array.isArray(json)) {
        json = {};
        json.child = [_json];
      }

      // 外部からのデータなので、一応、持っとく
      this._data.data(json);
      this._view.visible(true);
      this._view.update(json);

      // this._data.updateLatest(function (json) {
      //    _self._view.update(json);
      // });
    };

    TreeSrv.prototype.clear = function () {
      this._view.visible(false);
      this._view.clear();
    };

    TreeSrv.prototype.clearSelect = function () {
      this._view.clearSelect();
    };

    TreeSrv.prototype.onSelectedItem = function (node, pnode) {
      // alert(node.label)
      $(this).trigger('change',
        {
          node: node,
          pnode: pnode
        });
    };

    TreeSrv.prototype.onClickReport = function (node, pnode) {
      $(this).trigger('report',
        {
          node: node,
          pnode: pnode
        });
    };

    return TreeSrv;
  })();
})(BST || (BST = {}));
