var BST;
(function (BST) {
  var SettingDialogCarEdit = BST.SettingDialogCarEdit = (function () {
    function SettingDialogCarEdit () {
      // this.base;
    }

    SettingDialogCarEdit.prototype.create = function () {
      var _self = this;

      this.bg = $('<div>').addClass('bst-setting-dialog-car-edit-bg').appendTo('body').hide();
      var base = this.base = $('<div>').addClass('bst-setting-dialog-car-edit').appendTo('body').hide();
      var panel = $('<div>').addClass('panel').appendTo(base);
      this.title = $('<div>').addClass('title').text('タイトル').appendTo(panel);
      $('<div>').addClass('ms').text('※*は入力必須項目です。').appendTo(panel);
      // $("<div>").addClass("title").appendTo(panel);
      var row1 = $('<div>').addClass('row').appendTo(panel);
      $('<div>').addClass('label').text('所属拠点').appendTo(row1);
      $('<span>').addClass('mark').text('*').appendTo(row1);
      this.select1 = $('<select>').addClass('pl1').appendTo(row1)
        .on('change', function () {
          _self.change();
        });
      var row2 = $('<div>').addClass('row').appendTo(panel);
      $('<div>').addClass('label').text('車体形状').appendTo(row2);
      $('<span>').addClass('mark').text('*').appendTo(row2);
      this.select2 = $('<select>').addClass('pl2').appendTo(row2)
        .on('change', function () {
          _self.change();
        });
      var row3 = $('<div>').addClass('row').appendTo(panel);
      $('<div>').addClass('label').text('ドライブレコーダー').appendTo(row3);
      $('<span>').addClass('mark').text('*').appendTo(row3);
      this.select3 = $('<select>').addClass('pl2').appendTo(row3)
        .on('change', function () {
          _self.change();
        });
      var row4 = $('<div>').addClass('row').appendTo(panel);
      $('<div>').addClass('label').text('車両名').appendTo(row4);
      $('<span>').addClass('mark').text('*').appendTo(row4);
      this.input1 = $('<input>').addClass('ip1').attr('maxlength', 100).appendTo(row4)
        .on('change', function () {
          _self.change();
        });

      var row5 = $('<div>').addClass('btns').appendTo(panel);
      var btn1 = this.btn1 = $('<button>').addClass('ok').text('OK').appendTo(row5);
      var btn2 = $('<button>').addClass('cancel').text('キャンセル').appendTo(row5);

      btn1.on('click', function () {
        if (_self.check(_self._data) === false) return;

        try {
          if (_self._callback1) {
            _self._callback1(_self._data);
          }
        } catch (e) {
          console.log(e);
        }
      });
      btn2.on('click', function () {
        try {
          if (_self._callback2) {
            _self._callback2();
          }
        } catch (e) {
          console.log(e);
        }
      });
    };
    SettingDialogCarEdit.prototype.setData = function (sl1, sl2, sl3, data) {
      // this._data = {
      //    sl1: sl1
      //    , sl2: sl2
      //    , sl3: sl3
      //    , data: data
      // };
      this._data = data;

      var view = this.base;
      if (view) {
        // 拠点
        this.select1.empty();
        for (var i = 0; i < sl1.length; i++) {
          var o = $('<option>').text(sl1[i].nm).data('data', sl1[i]).appendTo(this.select1);
          if (sl1[i].cd === data.ocd) {
            // o.prop("selected", "selected");
            this.select1.get(0).options[i].selected = true;
          }
        }

        // 車種
        this.select2.empty();
        for (i = 0; i < sl2.length; i++) {
          o = $('<option>').text(sl2[i].nm).data('data', sl2[i]).appendTo(this.select2);
          if (sl2[i].id === data.type) {
            // o.prop("selected", "selected");
            this.select2.get(0).options[i].selected = true;
          }
        }

        // どられこ
        this.select3.empty();
        for (i = 0; i < sl3.length; i++) {
          o = $('<option>').text(sl3[i].nm).data('data', sl3[i]).appendTo(this.select3);
          if (sl3[i].cd === data.rcd) {
            // o.prop("selected", "selected");
            this.select3.get(0).options[i].selected = true;
          }
        }

        // 車両名
        this.input1.val(data.nm);
      }
    };

    SettingDialogCarEdit.prototype.change = function () {
      try {
        this._data.nm = this.input1.val();
      } catch (e) {
        console.log(e);
      }
      try {
        // var d = this.select1.val();
        // this._data.ocd = d.cd;

        // var d2 = this.select1.find(":selected").data("data");
        this._data.ocd = this.select1.find(':selected').data('data').cd;
      } catch (e) {
        console.log(e);
      }
      try {
        // var d = this.select2.val();
        // this._data.type = d.cd;
        this._data.type = this.select2.find(':selected').data('data').id;
      } catch (e) {
        console.log(e);
      }
      try {
        // var d = this.select3.val();
        // this._data.rcd = d.cd;

        this._data.rcd = this.select3.find(':selected').data('data').cd;
      } catch (e) {
        console.log(e);
      }
    };

    SettingDialogCarEdit.prototype.check = function (data) {
      var err = '';
      // 空白
      if (BST.Util.isBlank(data.ocd)) {
        err = this.addText(err, '※所属拠点を選択して下さい。');
      }
      if (BST.Util.isBlank(data.type)) {
        err = this.addText(err, '※車体形状を選択して下さい。');
      }
      if (BST.Util.isBlank(data.rcd)) {
        err = this.addText(err, '※ドライブレコーダーを選択して下さい。');
      }
      if (BST.Util.isBlank(data.nm)) {
        err = this.addText(err, '※車両名を入力して下さい。');
      }

      if (err === '') return true;

      BST.Com.dialogLErr(err);
      return false;
    };

    SettingDialogCarEdit.prototype.type = function (type) {
      if (!this.base) this.create();

      if (type === 1) {
        // new
        this.title.text('新規登録');
        this.btn1.text('登録');
      } else if (type === 2) {
        // edit
        this.title.text('編集');
        this.btn1.text('OK');
      }
    };
    SettingDialogCarEdit.prototype.open = function (orgs, types, recs, data, callback1, callback2, type) {
      if (!this.base) this.create();

      this.type(type);

      this._callback1 = callback1;
      this._callback2 = callback2;

      this.setData(orgs, types, recs, data);

      this.disp(true);
    };

    SettingDialogCarEdit.prototype.close = function (type) {
      var view = this.base;
      if (view) {
        this._callback1 = null;
        this._callback2 = null;
        this._data = null;
      }

      this.disp(false);
    };

    SettingDialogCarEdit.prototype.disp = function (b) {
      if (!this.base) {
        this.create();
      }
      if (b) {
        this.bg.show();
        this.base.show();
      } else {
        this.bg.hide();
        this.base.hide();
      }
    };

    // SettingDialogCarEdit.prototype._text = function (text) {
    //    if (!this.base)
    //        this.create();
    //    this.text.html(text);
    // }

    SettingDialogCarEdit.prototype.addText = function (str, add) {
      if (str !== '') str += '<br>';

      str += add;

      return str;
    };

    return SettingDialogCarEdit;
  })();
})(BST || (BST = {}));
