var BST;
(function (BST) {
  var Dialog = BST.Dialog = (function () {
    Dialog.TYPE_OK = 1;
    Dialog.TYPE_YESNO = 2;

    // text1,text2
    // type
    function Dialog (opt) {
      this._opt = opt || {};
    }

    Dialog.prototype.create = function () {
      var _self = this;
      this.bg = $('<div>').addClass('bst-dialog-bg').appendTo('body').hide();
      this.base = $('<div>').addClass('bst-dialog').appendTo('body').hide();
      this.panel = $('<div>').addClass('panel').appendTo(this.base);
      this.text = $('<div>').addClass('text').appendTo(this.panel);

      var type = this._opt.type || Dialog.TYPE_OK;

      if (Dialog.TYPE_OK === type) {
        this.btn1 = $('<div>').addClass('btn').text(this._opt.text1 || 'OK').appendTo(this.panel);
        this.btn1.on('click', function () {
          try {
            if (_self.callback1) {
              _self.callback1(true);
            }
          } catch (e) {
            console.log(e);
          }

          _self._disp(false);
          _self._text('');
          _self.callback1 = null;
        });
      } else if (Dialog.TYPE_YESNO === type) {
        this.btn1 = $('<div>').addClass('btn1').text(this._opt.text1 || 'OK').appendTo(this.panel);
        this.btn1.on('click', function () {
          try {
            if (_self.callback1) {
              _self.callback1(true);
            }
          } catch (e) {
            console.log(e);
          }
        });

        this.btn2 = $('<div>').addClass('btn2').text(this._opt.text2 || 'キャンセル').appendTo(this.panel);
        this.btn2.on('click', function () {
          try {
            if (_self.callback2) {
              _self.callback2(true);
            }
          } catch (e) {
            console.log(e);
          }
        });
      }
    };

    Dialog.prototype.open = function (text, callback1, callback2, opt) {
      this.callback1 = callback1;
      this.callback2 = callback2;
      this._text(text);
      this._disp(true);

      opt = opt || {};
      if (opt.textSelect) {
        this.text.addClass('text-select');
        this.text.removeClass('text-select-none');
      } else {
        this.text.addClass('text-select-none');
        this.text.removeClass('text-select');
      }
    };

    Dialog.prototype.close = function () {
      this.callback1 = null;
      this.callback2 = null;
      this._text('');
      this._disp(false);
    };

    Dialog.prototype._disp = function (b) {
      if (!this.base) {
        this.create();
      }
      if (b) {
        this.bg.show();
        this.base.show();
      } else {
        this.bg.hide();
        this.base.hide();
      }
    };

    Dialog.prototype._text = function (text) {
      if (!this.base) {
        this.create();
      }
      this.text.html(text);
    };

    Dialog.prototype.btn1Text = function (text) {
      this.btn1.text(text);
    };
    Dialog.prototype.btn2Text = function (text) {
      this.btn2.text(text);
    };

    return Dialog;
  })();
})(BST || (BST = {}));
