var BST;
(function (BST) {
  var RankSrv = BST.RankSrv = (function () {
    function RankSrv (parent) {
      var _self = this;
      var view = this._view = new BST.Rank(parent, {
        callbackChangeDate: function (data, node, type) {
          _self.updateGraph(data, node, type, 10);
        },
        callbackClickAll: function (data, node, type) {
          _self.updateGraph(data, node, type, 'all');
        },
        callbackChangeTreeListItem: function (data, node, type) {
          _self.updateGraph(data, node, type, 10);
        },
        callbackPrint: function () {
          BST.WinLink.open(BST.WinLink.TYPE_RANK, _self._param);
        }
      });
      var d = this._data = new BST.RankData();

      this._param = {};

      // this.change("dept");

      this.init(parent);
    }

    RankSrv.prototype.init = function (parent) {
      var _self = this;

      this._data.loadAll(BST.Com.session, function (res) {
        if (res.suc) {
          // res.data.rank = _self.sample[10];
          _self._view.updateAll(res.data);
        }
      });
    };

    RankSrv.prototype.updateGraph = function (data, node, type, num) {
      var _self = this;

      this._param = {
        sc: BST.Com.session,
        type: type,
        date: data.cd,
        rankCd: node.cd,
        num: num
      };
      this._data.load(BST.Com.session, type, data.cd, node.cd, num, function (res) {
        if (res.suc) {
          // res.data.rank = _self.sample[num];
          _self._view.updateGraph(res.data.rank, res.data.ave);
          if (num !== 'all' && res.data.rankNum > 10) {
            _self._view.dispGraphBtn(true);
          } else {
            _self._view.dispGraphBtn(false);
          }
        }
      });
    };

    return RankSrv;
  })();
})(BST || (BST = {}));
