var BST;
(function (BST) {
  var Main = BST.Main = (function () {
    function Main () {
      var _self = this;

      // BST.Com.secDialog(function (b) {
      //    alert("ok");
      // });

      // var param = {};
      // BST.BaseCtr.call(this, param);

      var home = $('#app-home');
      var tree = $('#app-tree');
      var setting = $('#app-setting');
      var dl = $('#app-dl');

      // var homeCnts = $("#app-home-contents");
      // var treeCnts = $("#app-tree-contents");
      // var setCnts = $("#app-setting-contents");

      // login
      var lgUser = $('.header-panel #login-user>div');
      var lgBtn = $('.header-panel #login-state').on('click', function (e) {
        lgc.logout();
      });
      var lgBtnText = $('.header-panel #login-state>div');

      var lgc = this.loginCtr = new BST.LoginCtr();
      // this.loginCtr.callbackLogin(this.onLogin);
      lgc.login();
      $(lgc).on('login', function (e, param) {
        // 成功
        if (param.suc) {
          // BST.Com.session = param.data.sc;
          BST.Com.setLogin(param.data);

          lgUser.text(param.data.nm || '');
          lgBtnText.text('ログアウト');

          // treeを表示（ランキングも含む）
          if (param.data.comp) {
            home.show()
              .on('click', function (e) {
                _self.homeCtr.active();// homeパネル表示
                home.addClass('app-home-active');
              });
            tree.show();
            // trc.update(param.data.comp);
            if (!_self.homeCtr) {
              _self.homeCtr = new BST.HomeCtr();
            }

            if (!_self.driveCtr) {
              _self.driveCtr = new BST.DriveCtr();
            }

            _self.homeCtr.active();// homeパネル表示
            home.addClass('app-home-active');
          } else {
            // trc.clear();
          }

          // 設定を表示
          // if (param.data.master) {
          if (BST.Com.isAdmin()) {
            _self.setCtr = new BST.SettingCtr();
            $('#app-setting').show();

            // $("#app-setting").show()
            // .on("click", function (e) {
            //    _self.setCtr.active();// 設定パネル表示
            // });

            if (!param.data.comp) {
              _self.setCtr.active();// 設定パネル表示
              _self.setCtr.click(0);
            }
          }

          // ダウンロードを表示
          _self.dlCtr = new BST.DlCtr();
          $('#app-dl').show();
        }
      });
      // this.loginCtr.on("login", function (e) {

      // });

      /// / report
      // var rpc = this.reportCtr = new BST.ReportCtr();

      /// / カルテ
      // var shc = this.sheetCtr = new BST.SheetCtr();

      /// / tree
      // var trc = this.treeCtr = new BST.TreeCtr();
      // $(trc).on("change", function (e, param) {
      //    var node = BST.Com.current.node = param.node;
      //    var pnode = BST.Com.current.pnode = param.pnode || {};
      //    //var pld = _self.pl.value();
      //    //var kijun = pld.value;
      //    //if( kijun == undefined || kijun == null) kijun = 999;
      //    //lc.change(node.type, node.cd, BST.Com.session, _self.dp1.value(), _self.dp2.value(), kijun, pnode.cd);

      //    _self.updateList(999, true);

      // eslint-disable-next-line no-irregular-whitespace
      //    title.text(node.nm + "　評価概要一覧");
      // })
      // .on("report", function (e, param) {
      //    var node = param.node;
      //    var pnode = param.pnode || {};
      //    var pld = _self.pl.value();
      //    rpc.view(pnode.cd, node.cd, BST.Com.session);
      // });

      /// / list
      // var lc = this.listCtr = new BST.ListCtr();
      // $(lc).on("select", function (e, param) {
      //    var data = param.data;
      //    var type = param.type;
      //    if (type == "driver") {
      //        //if (data.cd != BST.Com.current.node.cd) {
      //        //    try {
      //        //        console.assert("Not the same tree select cd and list select cd ");
      //        //    } catch (e) { }
      //        //}
      //        shc.view(BST.Com.current.pnode.cd, BST.Com.current.node.cd, data.rcd, data.from, BST.Com.session);
      //    }

      // });

      /// / そのた
      // var now = new Date();
      // var bfr = new Date(now);
      // bfr.setDate(bfr.getDate() - 1);
      // var dp1 = this.dp1 = new BST.DatePicker($(".right-panel>#app-tree-contents>#row1>#date1")
      //, {
      //    callbackSelected: function (text) {
      //        //alert(text);
      //        _self.updateList(999, true);
      //    }
      // });
      // dp1.setDate(bfr);

      // var dp2 = this.dp2 = new BST.DatePicker($(".right-panel>#app-tree-contents>#row1>#date2")
      //, {
      //    callbackSelected: function (text) {
      //        //alert(text);
      //        _self.updateList(999, true);
      //    }
      // });
      // dp2.setDate(now);

      /// /var pl = this.pl = new BST.PlList($(".right-panel>#app-tree-contents>#row1>#pl1")
      /// /    , this.createPlData(null)
      /// /    , {
      /// /    callbackSelected: function (d) {
      /// /        //alert(d.label);
      /// /        //_self.updateList(pl.value(), false);
      /// /        _self.updateList(d.value, false);

      /// /    }
      /// /});

      // var title = this.title = $(".right-panel>#contents>#row2>#title");
    }
    // BST.Util.inherits(Main, BST.BaseCtr);

    Main.prototype.updateDrive = function () {

    };

    // Main.prototype.createPlData = function (datas) {
    //    var res = [
    //        {
    //            label: "すべて"
    //            ,value:999
    //        }
    //    ];

    //    if (datas) {
    //        for (var i = 0; i < datas.length; i++)
    //            res.push({
    //                label: datas[i]
    //                , value: datas[i]
    //            });
    //    }

    //    return res;
    // }

    /// /Main.prototype.updateList = function (uppl, _kijun) {
    // Main.prototype.updateList = function (kijun, uppl) {
    //    if (!BST.Com.current.node || !BST.Com.current.pnode) return;

    //    var _self = this;
    //    //var pld = this.pl.value() || {};
    //    //var kijun = pld.value;
    //    //if (kijun == undefined || kijun == null) kijun = 999;
    //    //if (_kijun != undefined && _kijun != null) kijun = _kijun;
    //    var callback;
    //    if (uppl) callback = function (res) {
    //        if (res.suc) {
    //            _self.pl.update(_self.createPlData(res.data.kijun));
    //        }
    //    }
    //    this.listCtr.change(BST.Com.current.node.type, BST.Com.current.node.cd, BST.Com.session, this.dp1.value(), this.dp2.value(), kijun, BST.Com.current.pnode.cd, callback);
    // }

    return Main;
  })();
})(BST || (BST = {}));
