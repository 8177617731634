var BST;
(function (BST) {
  var LoginData = BST.LoginData = (function () {
    function LoginData () {
      this._data = null;
    }

    LoginData.prototype.data = function () {
      return this._data;
    };

    LoginData.prototype.login = function (ccd, id, pass, callback) {
      var url = BST.Const.csurl + 'auth';
      this._login(url, ccd, id, pass, callback);
    };

    LoginData.prototype._login = function (url, ccd, id, pass, callback) {
      var req = {
        ccd: ccd,
        login: id,
        pass: pass
      };
      var _self = this;

      BST.Com.post(url, req, function (res) {
        if (res.suc) {
          _self._data = res.data;
        } else {
          _self._data = null;
        }

        callback(res);
      });
    };
    // LoginData.prototype.login = function (id, pass, callback) {
    //    var url = BST.Const.csurl + "auth";
    //    var req = {
    //        login: id
    //        , pass: pass
    //    };
    //    var _self = this;

    //    $.ajax({
    //        type: "POST"
    //        , datatype: "json"
    //        , data: JSON.stringify(req)
    //        , url: url
    //        , contentType: "application/json; charset=utf-8"
    //    }).then(function (data) {
    //        _self._data = data;
    //        if (callback) {
    //            var res = _self.createResult(true, _self._data);
    //            callback(res);
    //        }

    //    }).fail(function (err) {
    //        //_self._data = null;
    //        if (callback) {
    //            var res = _self.createResult(false, err);
    //            callback(res);
    //        }
    //        console.log('login data error:' + err);
    //    }).always(function () {
    //    });

    // }

    LoginData.prototype.logout = function (sc, callback) {
      var url = BST.Const.csurl + 'logout';
      var req = {
        sc: sc
      };
      var _self = this;

      BST.Com.post(url, req, function (res) {
        callback(res);
      });
    };

    LoginData.prototype.checkSession = function (sc, callback) {
      var url = BST.Const.csurl + 'alive';
      var req = {
        sc: sc
      };
      var _self = this;

      BST.Com.post(url, req, function (res) {
        callback(res);
      });
    };

    LoginData.prototype.createResult = function (success, data) {
      var res = {
        suc: false,
        mess: 'ログインIDまたはパスワードが正しくありません',
        data: data
      };
      if (success) {
        if (data.ecd === 0) {
        // エラーなし
          res.suc = true;
          res.mess = '';
        } else {
          // サーブレット内でエラーあり
          // res.login = false;
          res.mess = data.emsg;
        }
      } else {
        // それ以外のエラー
        // res.login = false;
      }

      return res;
    };

    return LoginData;
  })();
})(BST || (BST = {}));
