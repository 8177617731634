var BST;
(function (BST) {
  var Tree = BST.Tree = (function () {
    function Tree (parent, options) {
      parent = parent || $('body');
      var opt = this.opt = options || {};
      var data = this.data = opt.data || null;
      this.callbackSelected = opt.callbackSelected;
      this.callbackReport = opt.callbackReport;

      var base = this.base = $('<div>').addClass('bst-tree').appendTo(parent);

      this._dataName = {
        label: 'label',
        nodes: 'nodes'
      };

      this.update(data);
    }

    Tree.prototype.setDataName = function (dn) {
      this._dataName = dn;
    };

    Tree.prototype.visible = function (b) {
      if (b) {
        this.base.show();
      } else {
        this.base.hide();
      }
    };

    Tree.prototype.clear = function () {
      this.base.empty();
    };

    Tree.prototype.update = function (data) {
      this.data = data;

      // hash clear
      var elhash = this.elhash = {};

      this.clear();

      var ul = this.parentUl = $('<ul>');

      if (data) {
        var nodes = data[this._dataName.nodes];
        var labelName = this._dataName.label;
        for (var n in nodes) {
          var node = nodes[n];
          var li = this.createItem(node[labelName], node);
          li.appendTo(ul);
          // 要素をハッシュ
          if (!elhash[node.type]) elhash[node.type] = {};
          elhash[node.type][node.cd] = li;
          this.addItem(node, li, elhash);
        }
      }

      ul.appendTo(this.base);
    };

    Tree.prototype.addItem = function (data, rootNode, elhash) {
      var ul = rootNode.children().last();
      var nodes = data[this._dataName.nodes];
      var labelName = this._dataName.label;
      for (var n in nodes) {
        var node = nodes[n];
        var li = this.createItem(node[labelName], node);
        li.appendTo(ul);
        // 要素をハッシュ
        if (!elhash[node.type]) elhash[node.type] = {};
        elhash[node.type][node.cd] = li;
        this.addItem(node, li, elhash);// 末端の子要素まで追加したいので再起処理
      }
    };

    Tree.prototype.createItem = function (text, data) {
      var _self = this;
      var li = $('<li>').addClass('reaf').data('data', data);
      var label = $('<span>').addClass('label').appendTo(li);
      var h = $('<span>').appendTo(label);
      var l1 = $('<span>').text(text).addClass('label1').appendTo(label);

      // ドライバーのみレポート作成
      if (data.type === 'driver') {
        l1.addClass('label2');

        var report = $('<span>').addClass('report')
          .on('click', function (e) {
            e.stopImmediatePropagation();
            if (_self.callbackReport) {
              var dt = li.data('data');
              var pdt = null;
              try {
                // pdt = $.data(li.parent().parent().parent().prevObject, "data");
                pdt = li.parent().parent().parent().prevObject.data('data');
              } catch (e) { }
              _self.callbackReport(dt, pdt);
            }
          }).appendTo(label);
      }

      var ul = $('<ul>').appendTo(li).on('click', function (e) {
        e.stopImmediatePropagation();
      });
      ul.hide();

      var nodes = data[_self._dataName.nodes];
      // li.addClass("reaf");
      // if (nodes && nodes.length != 0) li.addClass("collapse");
      h.addClass('reaf');
      if (nodes && nodes.length !== 0) h.addClass('collapse');

      label.on('click', function (e) {
        e.stopImmediatePropagation();

        // 同じなら
        if (_self.selectedItem === label) return;

        if (_self.selectedItem) {
          _self.selectedItem.removeClass('selected');
        }

        _self.selectedItem = label;
        _self.selectedItem.addClass('selected');

        if (_self.callbackSelected) {
          // var dt = $.data(e.target.parentElement, "data");
          var dt = li.data('data');
          var pdt = null;
          try {
            // pdt = $.data(li.parent().parent().parent().prevObject, "data");
            pdt = li.parent().parent().parent().prevObject.data('data');
          } catch (e) { }
          _self.callbackSelected(dt, pdt);
        }
      });

      h.on('click', function (e) {
        e.stopImmediatePropagation();
        var nodes = data[_self._dataName.nodes];
        // var _data = $.data(e.target, "data");
        if (!(nodes && nodes.length !== 0)) return;

        if (h.hasClass('expand')) {
          // close
          h.removeClass('expand');
          // alert("close");
          ul.hide();
        } else {
          // open
          h.addClass('expand');
          // alert("open");
          ul.show();
        }

        // if (li.hasClass("collapse")) {
        //    li.toggle("expand");
        // }
      });

      return li;
    };

    Tree.prototype.getItem = function (type, cd) {
      try {
        return this.elhash[type][cd];
      } catch (e) {

      }
      return null;
    };

    Tree.prototype.clickItem = function (type, cd) {
      try {
        this.elhash[type][cd].children('.label').trigger('click');
      } catch (e) {

      }
      return null;
    };

    Tree.prototype.clearSelect = function () {
      if (this.selectedItem) {
        this.selectedItem.removeClass('selected');
        this.selectedItem = null;
      }
    };

    return Tree;
  })();
})(BST || (BST = {}));
