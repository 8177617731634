var BST;
(function (BST) {
  var SettingEval = BST.SettingEval = (function () {
    function SettingEval (parent, options) {
      parent = parent || 'body';
      // var data = {
      //    saiten: 0.3
      //    , grav: 0.3
      //    , blank: 20
      //    , term: 999
      // };
      this.opt = options || {};

      this.callbackUpdate = this.opt.callbackUpdate;

      // ベース作成
      this.elementRoot = $('<div class="setting-eval"> ').appendTo($(parent));
      this.elementRoot.hide();

      this.createMain(); // メイン部分作成
      // this.setData(data);
    }

    // メイン部分作成
    SettingEval.prototype.createMain = function () {
      var _self = this;

      var title = $('<div id="title"> ').appendTo(this.elementRoot);
      title.text('評価設定');

      var settingField = $('<div class="settingField"> ').appendTo(this.elementRoot);

      var list = $('<ul class="list"> ').appendTo(settingField);

      var li1 = $('<li class="li1"> ').appendTo(list);

      var scoringG = $('<label id="scoringG" class="label"> ').appendTo(li1);
      scoringG.text('評価基準G :');

      var max1 = 0.5;
      var min1 = 0.1;
      var numspinar1 = this.num1 = $('<input>', { id: 'numspinar1', value: '0.3' }).appendTo(li1);
      $('.settingField #numspinar1').spinner({
        max: max1,
        min: min1,
        step: 0.01
      });
      numspinar1.on('blur', function (e) {
        var v = _self.convFloat($(this).val(), min1, max1);
        $(this).val(v);
      });

      var range = $('<label  class="unit"> ').appendTo(li1);
      range.text('(0.1～0.5)');

      var li2 = $('<li class="li2"> ').appendTo(list);

      var starG = $('<label id="starG" class="label"> ').appendTo(li2);
      starG.text('★基準G :');

      var max2 = 0.5;
      var min2 = 0.05;
      var numspinar2 = this.num2 = $('<input>', { id: 'numspinar2', value: '0.3' }).appendTo(li2);
      $('.settingField #numspinar2').spinner({
        max: max2,
        min: min2,
        step: 0.01
      });
      numspinar2.on('blur', function (e) {
        var v = _self.convFloat($(this).val(), min2, max2);
        $(this).val(v);
      });

      var range2 = $('<label  class="unit"> ').appendTo(li2);
      range2.text('(0.05～0.5)');

      var li3 = $('<li class="li3"> ').appendTo(list);

      var empTime = $('<label id="empTime" class="label"> ').appendTo(li3);
      empTime.text('運行の区切りとなる空白時間 :');

      var max3 = 9999;
      var min3 = 0;
      var numspinar3 = this.num3 = $('<input>', { id: 'numspinar3', value: '20' }).appendTo(li3);
      $('.settingField #numspinar3').spinner({
        max: max3,
        min: min3,
        step: 5
      });
      numspinar3.on('blur', function (e) {
        var v = _self.convInt($(this).val(), min3, max3);
        $(this).val(v);
      });

      var minute = $('<label class="unit"> ').appendTo(li3);
      minute.text('分');

      var li4 = $('<li class="li4">').appendTo(list);

      var mxTime = $('<label id="mxTime" class="label"> ').appendTo(li4);
      mxTime.text('1運行の最大時間 :');

      var max4 = 24;
      var min4 = 1;
      // var numspinar4 = this.num4 = $("<input>", { id: 'numspinar4', value: '999' }).appendTo(li4);
      var numspinar4 = this.num4 = $('<input>', { id: 'numspinar4', value: '24' }).appendTo(li4);
      $('.settingField #numspinar4').spinner({
        max: max4,
        min: min4,
        step: 1
      });
      numspinar4.on('blur', function (e) {
        var v = _self.convInt($(this).val(), min4, max4);
        $(this).val(v);
      });

      var hour = $('<label class="unit"> ').appendTo(li4);
      hour.text('時間');

      var li5 = $('<li> ').appendTo(list);

      var avgScore = $('<label id="avgScore" class="label"> ').appendTo(li5);
      avgScore.text('平均点の算出対象:　走行距離');

      var max5 = 1000;
      var min5 = 300;
      var numspinar5 = this.num5 = $('<input>', { id: 'numspinar5', value: '300' }).appendTo(li5);
      $('.settingField #numspinar5').spinner({
        // numspinar5.spinner({
        max: max5,
        min: min5,
        step: 100
      });
      numspinar5.on('blur', function (e) {
        var v = _self.convInt($(this).val(), min5, max5);
        v = parseInt(v / 100) * 100; // 310とかなし200,300みたいな感じにする
        $(this).val(v);
      });

      var mtrService = $('<label class="unit"> ').appendTo(li5);
      mtrService.text('m以上の運行');

      var msg = $('<label class="msg"> ').appendTo(this.elementRoot);
      msg.text('適用されました。');

      var errormsg = $('<label class="errormsg"> ').appendTo(this.elementRoot);
      errormsg.text('適用できませんでした。');

      var saveBtn = $('<label class="saveBtn"> ').appendTo(this.elementRoot);
      saveBtn.text('適用').on('click', function () {
        if (_self.callbackUpdate) {
          _self.callbackUpdate(_self.getData());
        }
      });
    };

    SettingEval.prototype.disp = function (b) {
      if (b) {
        this.elementRoot.show();
      } else {
        this.elementRoot.hide();
      }
    };

    SettingEval.prototype.viewUpdate = function (data) {
      this.setData(data);
    };

    SettingEval.prototype.setData = function (data) {
      this._data = data;
      if (data) {
        this.num1.val(this._data.saiten);
        this.num2.val(this._data.grav);
        this.num3.val(this._data.blank);
        this.num4.val(this._data.term);
        this.num5.val(this._data.dist);
      }
    };

    SettingEval.prototype.getData = function () {
      this._data.saiten = this.num1.val();
      this._data.grav = this.num2.val();
      this._data.blank = this.num3.val();
      this._data.term = this.num4.val();
      this._data.dist = this.num5.val();
      return this._data;
    };

    SettingEval.prototype.convFloat = function (val, min, max) {
      var v = parseFloat(val);
      if (isNaN(v)) {
        return min;
      }
      v = Math.round(v * 100) / 100;
      if (v < min) {
        return min;
      } else if (v > max) {
        return max;
      }
      return v;
    };
    SettingEval.prototype.convInt = function (val, min, max) {
      var v = parseInt(val);
      if (isNaN(v)) {
        return min;
      }
      v = Math.round(v * 100) / 100;
      if (v < min) {
        return min;
      } else if (v > max) {
        return max;
      }
      return v;
    };

    return SettingEval;
  })();
})(BST || (BST = {}));
