var BST;
(function (BST) {
  var VideoPlayer = BST.VideoPlayer = (function () {
    function VideoPlayer () {
    }

    VideoPlayer.prototype.open = function (data, src) {
      this.close();
      this.create(data, src);
    };

    VideoPlayer.prototype.close = function () {
      if (this.base) {
        this.base.remove();
        this.base = null;
      }
      if (this.bg) {
        this.bg.remove();
        this.bg = null;
      }
    };

    VideoPlayer.prototype.create = function (data, src) {
      var _self = this;

      this.bg = $('<div>').addClass('bst-video-player-bg').appendTo('body');
      var base = this.base = $('<div>').addClass('bst-video-player').appendTo('body');
      var detail = this.detail = $('<div>').addClass('detail').appendTo(base);
      this.text1 = $('<div>').addClass('text').text(data.nm).appendTo(detail);
      this.text2 = $('<div>').addClass('text').text(data.dnm).appendTo(detail);
      var tbase = $('<div>').addClass('time-base').appendTo(detail);
      $('<span>').addClass('g').text('★').appendTo(tbase);
      this.time = $('<span>').addClass('time').text(data.date).appendTo(tbase);
      this.g = $('<span>').addClass('g').text(BST.Com.adjustGrav(data.grav) + 'G').appendTo(tbase);

      this.video = $("<video controls autoplay controlslist='nodownload'>").attr('src', src).appendTo(base);
      // this.video.get(0).play();

      var btn1 = this.btn1 = $('<div>').addClass('btn-close').appendTo(base);

      btn1.on('click', function () {
        // try {
        //    if (_self._callbackClose)
        //        _self._callbackClose();
        // } catch (e) { }
        _self.close();
      });
    };

    return VideoPlayer;
  })();
})(BST || (BST = {}));
