var BST;
(function (BST) {
  var SettingUser = BST.SettingUser = (function () {
    function SettingUser (parent, options) {
      var _self = this;

      parent = parent || 'body';
      var opt = this.opt = options || {};
      this._callbackCreate = opt.callbackCreate;
      this._callbackUpdate = opt.callbackUpdate;
      this._callbackDelete = opt.callbackDelete;
      // this._callbackChangeFliter = opt.callbackChangeFliter;

      this._filters = opt.filters || [];
      // ロールを辞書昇順あとで比較を簡略するため
      // for (var i = 0; i < this._filters.length; i++) {
      //    this._filters[i].rl.sort();
      // }

      this.create(parent);
      this.createTable([], [], [], []);
    }

    SettingUser.prototype.setData = function (datas, orgs, drvs, orgAdms) {
      this._datas = datas || [];
      this._orgs = orgs || [];
      this._drvs = drvs || [];
      this._orgAdms = orgAdms || [];

      // ロールを辞書昇順あとで比較を簡略するため
      // for (var i = 0; i < this._datas.length; i++) {
      //    this._datas[i].rl.sort();
      // }
    };

    SettingUser.prototype.create = function (parent) {
      var _self = this;

      var base = this.base = $('<div>').addClass('setting-user').appendTo(parent);

      var header = $('<div>').addClass('header').appendTo(base);

      var row1 = $('<div>').addClass('row').appendTo(header);
      this.btnNew = $('<span>').addClass('btn-new').text('新規作成').appendTo(row1)
        .on('click', function () {
          if (_self._callbackCreate) _self._callbackCreate(_self._orgs, _self._drvs, _self._orgAdms);
        });
      $('<span>').addClass('label').html('ユーザー設定').appendTo(row1);
      // this.dcnum = $("<div>").addClass("count").html("登録済ドライバー数&nbsp" + "-" + "名（上限" + "-" + "名）").appendTo(row1);

      var row2 = $('<div class="row"> ').appendTo(header);
      $("<input type='password' name='dummypass' style='visibility: hidden; position: absolute;' />").addClass('search').appendTo(row2);
      this.search = $('<input>').addClass('search').attr('autocomplete', 'off').attr('placeholder', 'フリーワード検索').appendTo(row2);

      var row3 = $('<div class="row"> ').appendTo(header);
      $('<div>').addClass('label2').text('表示（ユーザー種類）').appendTo(row3);
      // $("<div>").addClass("btn").addClass("btn-on").text("閲覧者").appendTo(row3);
      // $("<div>").addClass("btn").addClass("btn-on").text("閲覧者（管理者）").appendTo(row3);
      // $("<div>").addClass("btn").addClass("btn-on").text("ドライバー").appendTo(row3);
      // $("<div>").addClass("btn").addClass("btn-on").text("データアップロード用").appendTo(row3);
      this.filtersFlag = [];
      for (var i = 0; i < this._filters.length; i++) {
        if (this._filters[i].useFilter) {
          this.createFilter(this._filters[i], i, row3);
        }
      }
      // filter用のinput text
      this.searchDummy = $('<input>').addClass('').attr('autocomplete', 'off').hide().appendTo(base);
    };

    SettingUser.prototype.createFilter = function (data, index, parent) {
      var _self = this;
      this.filtersFlag[index] = true;
      $('<div>').addClass('btn').addClass('btn-on').text(data.nm).data('data', data).appendTo(parent)
        .on('click', function () {
          _self.filtersFlag[index] = !_self.filtersFlag[index];
          if (_self.filtersFlag[index]) {
            $(this).removeClass('btn-off');
            $(this).addClass('btn-on');
          } else {
            $(this).removeClass('btn-on');
            $(this).addClass('btn-off');
          }
          _self.changeFilter();
        });
    };

    SettingUser.prototype.changeFilter = function () {
      var res = [];
      for (var i = 0; i < this.filtersFlag.length; i++) {
        if (this.filtersFlag[i]) {
          res.push(this._filters[i]);
        }
      }

      this.searchDummy.trigger('change');

      // this.resetNumber();
      // if (this._callbackChangeFliter)
      //    this._callbackChangeFliter(res);
    };

    SettingUser.prototype.getFilter = function (data) {
      // var rld = data.rl;

      // for (var i = 0; i < this._filters.length; i++) {
      //    var s1 = JSON.stringify(data.rl);
      //    var s2 = JSON.stringify(this._filters[i].rl);
      //    if (s1 == s2) return this._filters[i];
      // }

      // return null;

      // データは配列でなくなった。サーバーの変更で、ロールが追加されたりするとこちらも変更する必要があるので、
      // rlは文字列の固定の値になった。
      for (var i = 0; i < this._filters.length; i++) {
        var s1 = data.rl;
        var cd1 = data.cd;
        var s2 = this._filters[i].rl;
        var cd2 = this._filters[i].cd;
        if (s1 === s2) {
          // フィルター情報にcdがある場合はこちらもチェック
          if (cd2 != null) {
            if (cd1 === cd2) {
              return this._filters[i];
            }
          } else {
            return this._filters[i];
          }
        }
      }
      return null;
    };

    // テーブル作成
    SettingUser.prototype.createTable = function (users, orgs, drvs, orgAdms) {
      var _self = this;

      // テーブル
      if (!this.table) {
        this.table = $('<table>').addClass('table').appendTo(this.base);
      } else {
        this.table.empty();
      }

      var table = this.table;
      var header = $('<thead>').addClass('scrollHead').appendTo(table);
      var tr = $('<tr> ').appendTo(header);
      $('<td>').addClass('col0').addClass('label0').text('').appendTo(tr);
      $('<td>').addClass('col1').addClass('label').text('ログインID').appendTo(tr);
      $('<td>').addClass('col5').addClass('label').text('ユーザー種類').appendTo(tr);
      $('<td>').addClass('col2').addClass('label').text('ユーザー名').appendTo(tr);
      // $("<td>").addClass("col3").addClass("label").text("IDに紐付くドライバー").appendTo(tr);
      $('<td>').addClass('col4').addClass('label').text('').appendTo(tr);

      // ハッシュ
      // var orgMap = {};
      // for (var i = 0; i < orgs.length; i++) {
      //    orgMap[orgs[i].cd] = orgs[i];
      // }

      // var drvMap = {};
      // for (var i = 0; i < drvs.length; i++) {
      //    drvMap[drvs[i].cd] = drvs[i];
      // }

      var body = $('<tbody>').addClass('scrollBody').appendTo(table);
      // this._rows = [];
      // this._rowNumbers = [];
      // 1行分
      for (var i = 0; i < users.length; i++) {
        var d = users[i];

        // var drv = drvMap[d.dcd] || { nm: "" };

        tr = $('<tr class="tr"> ').data('data', d).appendTo(_self.table);
        var number = $('<td>').addClass('col0').addClass('item').addClass('item0').text(i + 1).appendTo(tr);
        $('<td>').addClass('col1').addClass('item').text(d.cd).appendTo(tr);
        var ut = this.getFilter(d) || {};
        $('<td>').addClass('col5').addClass('item').text(ut.nm).appendTo(tr);
        $('<td>').addClass('col2').addClass('item').text(d.nm).appendTo(tr);
        // $("<td>").addClass("col3").addClass("item").text(d.dnm).appendTo(tr);
        var btns = $('<td>').addClass('col4').addClass('item').appendTo(tr);
        $('<span>').addClass('btn-edit').text('編集').appendTo(btns)
          .on('click', function () {
            var _d = $(this).parent().parent().data('data');
            if (_self._callbackUpdate) {
              _self._callbackUpdate(_d, _self._orgs, _self._drvs, _self._orgAdms);
            }
          });
        var btnDel = $('<span>').addClass('btn-delete').text('削除').appendTo(btns);

        if (d.cd === 'admin') {
          btnDel.addClass('disable');
        } else {
          btnDel.on('click', function () {
            var _d = $(this).parent().parent().data('data');
            if (_self._callbackDelete) {
              _self._callbackDelete(_d);
            }
          });
        }

        // filter用の列（非表示）
        // rlとc
        // $("<td>").addClass("").text(ut.rl).hide().appendTo(tr);
        // $("<td>").addClass("").text(d.rl + "," + d.cd).hide().appendTo(tr);
        $('<td>').addClass('').text(ut.rl + ',' + ut.cd).hide().appendTo(tr);

        // this._rows.push(tr);
        // this._rowNumbers.push(number);
      }// end of for
      if (users.length > 0) {
        // this.table.exTableFilter(this.search);
        this.table.exTableFilter(
          {
            filters: {
              1: this.search,
              2: this.search,
              3: this.search,
              5: {
                element: this.searchDummy,
                onFiltering: function (api) {
                  // return api.getCurrentCellNum() >= api.getCurrentFilterNum();

                  // var val = api.getCurrentCellVal();
                  // for (var i = 0; i < _self._filters.length; i++) {
                  //    if (_self.filtersFlag[i]) {
                  //        if (val == _self._filters[i].rl) {
                  //            return true;
                  //        }
                  //    }
                  // }
                  // return false;

                  var val = api.getCurrentCellVal();
                  var arr = val.split(',');
                  var rl = arr[0];
                  var cd = arr[1];
                  for (var i = 0; i < _self._filters.length; i++) {
                    if (_self.filtersFlag[i]) {
                      if (rl === _self._filters[i].rl) {
                        return true;

                        // if ((_self._filters[i].cd != null
                        //    || _self._filters[i].cd != "null" )
                        //    && _self._filters[i].cd == cd)
                        //    return true;
                        // else
                        //    return true;
                      }
                    }
                  }
                  if (cd === 'admin') {
                    return true;
                  } else {
                    return false;
                  }
                }
              }
            },
            onFilteringEnd: function (api) {
              _self.resetNumber();
            }
          }
        );
      }
      this.search.trigger('change');
    };

    SettingUser.prototype.update = function (users, orgs, drvs, orgAdms) {
      this.setData(users, orgs, drvs, orgAdms);
      this.createTable(users, orgs, drvs, orgAdms);
    };

    SettingUser.prototype.resetNumber = function () {
      // var no = 1;

      // for (var i = 0; i < this._rows.length; i++) {
      //    console.log(this._rows[i].css('display'));
      //    if (this._rows[i].css('display') != 'none') {
      //        this._rowNumbers[i].text(no);
      //        no++;
      //    }
      // }

      var no = 1;

      this.table.find('.scrollBody').find('.tr').each(function (i, elem) {
        var $tr = $(elem);

        // console.debug($tr.css('display'));
        if ($tr.css('display') !== 'none') {
          var $no = $tr.find('.col0');
          $no.text(no);
          no++;
        }
      });
    };

    SettingUser.prototype.disp = function (b) {
      if (b) {
        this.base.show();
      } else {
        this.base.hide();
      }
    };

    return SettingUser;
  })();
})(BST || (BST = {}));
