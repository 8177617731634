var BST;
(function (BST) {
  var DlData = BST.DlData = (function () {
    function DlData () {
      // this._data = null;
    }

    // DlData.manual_url = "./img/cl-btn-h.png";
    DlData.manual_url = BST.Const.stgurl + 'manuals/DCW%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB-latest.pdf';
    DlData.manual_dl_name = '';
    DlData.viewer_url = BST.Const.stgurl + 'viewers/dcw-viewer-setup-latest.zip';
    DlData.viewer_dl_name = '';

    return DlData;
  })();
})(BST || (BST = {}));
